import { Component, Input, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router'
import { ItemMenu } from "../../../models/list-menu";

@Component({
    selector: 'app-menu-item',
    templateUrl: './menu-item.component.html',
    styleUrls: ['./menu-item.component.scss'],
})
export class MenuItemComponent implements OnInit {
    @Input() title: string = ''
    @Input() link: string = ''
    @Input() icon?: string = ''
    @Input() subItems?: Array<ItemMenu> = []

    activeSubItem: boolean = false

    constructor(private router: Router) {
    }

    ngOnInit(): void {
        this.watchSubItemActive()
    }

    toggleShowSubItems(): void {
        this.activeSubItem = !this.activeSubItem
    }

    watchSubItemActive(): void {
        this.routeIsActive()

        this.router.events.subscribe((event: Event) => {

            if (event instanceof NavigationEnd) {
                this.routeIsActive()
            }
        })
    }

    routeIsActive(): void {
        let currentUrl = this.router.url
        let isActive = false

        this.subItems?.forEach((item) => {
            if (currentUrl === item.link) {
                isActive = true
            }
            this.activeSubItem = isActive
        })
    }

}
