<app-loading-local class="main-loading" [loading]="loading"></app-loading-local>

<div class="project-profile" *ngIf="!loading">
    <div class="project-profile-title">
        <h2> {{ project?.name }} </h2>
        <h4> {{ project?.clients?.name }} </h4>
    </div>

    <div class="card-content mt-5">
        <div class="project-profile-users">
            <div class="project-profile-summary">
                <h3> Colaboradores </h3>

                <button class="project-profile-edit" (click)="showModal()">
                    <i class="bi bi-pencil"></i>
                </button>
            </div>

            <div class="project-profile-table-users">
                <app-table-list [listHeader]='usersHeader' [listBody]='listFilters'>
                    <ng-template let-item #body>
                        <td>
                            <a class="users-profile" [routerLink]="'/admin/collaborators/' + item.uuid">
                                {{ item.name }}
                            </a>
                        </td>
                        <td class="text-center">
                            {{ item.roles.join(', ') }}
                        </td>
                        <td class="text-center">
                            <strong class="hours">
                                {{ item.total_hours }}h
                            </strong>
                        </td>
                    </ng-template>
                </app-table-list>
            </div>
        </div>
    </div>

    <div class="card-content mt-5 chart-centered">
        <h3 class="mb-4">Orçamento de horas</h3>
        
        <app-chart
            *ngIf="project?.budget && (project?.budget.info_budget && project?.budget.info_budget.length || project?.budget.users.length); else notBudget"
            [data]="chart"
            [options]="plugins"
        ></app-chart>

        <ng-template #notBudget>
            <h3 class="text-display"> Nenhum orçamento adicionado </h3>
        </ng-template>
    </div>
    
    <div class="card-content mt-5 chart-centered">
        <h3 class="mb-4">Evolução</h3>
        <app-chart
            *ngIf="plots && plots.datasets.length; else notPlots"
            chartType="line"
            [options]="{ scales: { y: {min: 0} } }"
            [data]="plots"
        ></app-chart>

        <ng-template #notPlots>
            <h3 class="text-display"> Este projeto não possui evoluções </h3>
        </ng-template>
    </div>
</div>

<!-- Modal -->
<ng-template #modalCustom>
    <div class="mb-3 content-modal">
        <app-input 
            [form]="form" 
            name="name" 
            labelName="Nome do projeto*" 
            placeholder="Adicione o nome do projeto"
            [inputModel]="form.value.name"
        ></app-input>

        <app-select 
            [form]="form" 
            name="clients" 
            labelName="Cliente*" 
            placeholder="Adicione o cliente do projeto"
            [inputModel]="form.value.clients"
            [options]="clients"
        ></app-select>

        <app-select
            [form]="form" 
            name="is_activate"
            labelName="Status*" 
            placeholder="Adicione um status ao projeto"
            [inputModel]="form.value.is_activate"
            [options]="optionsStatus"
        ></app-select>

        <div class="mb-4">
            <app-budget-hours
                [form]="form"
            ></app-budget-hours>
        </div>
    </div>

    <div class="wrapper-buttons d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-cancel btn-secondary " (click)="closeModal()">
            Cancelar
        </button>

        <button 
            type="button" 
            class="btn-save ms-3 btn-default" 
            [class.btn-disabled]="form.invalid"
            (click)="onSubmit()"
        >
            Salvar
        </button>
    </div>
</ng-template>
