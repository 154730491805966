import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-boolean',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss']
})
export class BooleanComponent implements OnInit {
  @Input() id?: string
  @Input() inputModel: any
  @Output() output: EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit(): void { }

  onChangeValue() {
    this.output.emit(!this.inputModel)
  }
}
