import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'
import { ControlSelectOptions } from '../../../models/control-select-options'

@Component({
	selector: 'app-select',
	templateUrl: './select.component.html',
	styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
	@Input() form: FormGroup = new FormGroup(Input())
	@Input() id?: string
	@Input() name: string | number | null = ''
	@Input() inputModel: any
	@Input() labelName: string = ''
	@Input() placeholder: string = ''
	@Input() disabled: boolean = false
	@Input() options?: ControlSelectOptions[]
	@Output() output: EventEmitter<any> = new EventEmitter()

	constructor() { }

	ngOnInit(): void { }

	onChange(value: string): void {
		this.output.emit(value)
	}
}
