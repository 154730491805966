import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class WorksheetService {

  constructor(private http: HttpClient) { }

  addWorksheet(data: any, callback: Callbacks) {
    this.http
      .post<any>(`${environment.api}/worksheet/`, data)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  getWorksheetPerDay(day: string, callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/worksheet/?start=${day}&end=${day}`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          const worksheet = data.find((item: any) => item.date === day)
          callback.fnSuccess(worksheet)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  getWorksheetPerAdmin(userId: string, queryParams: any, callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/admin/worksheet/?user=${userId}&${queryParams}`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  getWorksheetsPerDate(date: any, callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/worksheet/?start=${date.start}&end=${date.end}`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  updateWorksheet(worksheetUuid: string, data: any, callback: Callbacks) {
    this.http
      .put<any>(`${environment.api}/worksheet/${worksheetUuid}/`, data)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  deleteWorksheet(worksheetUuid: string, callback: Callbacks) {
    this.http
      .delete<any>(`${environment.api}/admin/worksheet/${worksheetUuid}/`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  downloadWorksheet(params: string, callback: Callbacks) {
    const headers: any = { responseType: 'blob' }

    this.http
      .get<any>(`${environment.api}/worksheet/export/${params}`, headers)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  downloadWorksheetsClt(params: string, callback: Callbacks) {
    const headers: any = { responseType: 'blob' }

    this.http
      .get<any>(`${environment.api}/admin/accounts/export/${params}`, headers)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }
}
