import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks'
import { environment } from 'src/environments/environment'
import { finalize } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class ClientsService {
	constructor(private http: HttpClient) {}

	getClient(clientId: string, callback: Callbacks) {
		this.http
			.get<any>(`${environment.api}/admin/clients/${clientId}/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	getClients(callback: Callbacks) {
		this.http
			.get<any>(`${environment.api}/clients/?all`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	createClient(data: any, callback: Callbacks) {
		const formData = new FormData()
		formData.append('name', data.name)
		formData.append('cnpj', data.cnpj)
		formData.append('razao_social', data.razao_social)

		if (data.avatar && data.avatar.length) {
			formData.append(
				'avatar',
				this.DataURIToBlob(data.avatar),
				'image.jpg'
			)
		} else {
			formData.append('avatar', '')
		}

		this.http
			.post<any>(`${environment.api}/admin/clients/`, formData)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	updateClient(data: any, clientId: string, callback: Callbacks) {
		const formData = new FormData()
		formData.append('name', data.name)
		formData.append('cnpj', data.cnpj)
		formData.append('razao_social', data.razao_social)

		if (
			data.avatar &&
			data.avatar.length &&
			!data.avatar.includes('http')
		) {
			formData.append(
				'avatar',
				this.DataURIToBlob(data.avatar),
				'image.jpg'
			)
		}

		this.http
			.patch<any>(
				`${environment.api}/admin/clients/${clientId}/`,
				formData
			)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	getClientsForAdmin(callback: Callbacks, params: string = '') {
		this.http
			.get<any>(`${environment.api}/admin/clients/${params}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	deleteClient(uuid: string, callback: Callbacks) {
		this.http
			.delete<any>(`${environment.api}/admin/clients/${uuid}/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	DataURIToBlob(dataURI: any) {
		const splitDataURI = dataURI.split(',')
		const byteString =
			splitDataURI[0].indexOf('base64') >= 0
				? atob(splitDataURI[1])
				: decodeURI(splitDataURI[1])
		const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

		const ia = new Uint8Array(byteString.length)
		for (let i = 0; i < byteString.length; i++)
			ia[i] = byteString.charCodeAt(i)

		return new Blob([ia], { type: mimeString })
	}
}
