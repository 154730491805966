import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-label-group-menu-item',
    templateUrl: './label-group-menu-item.component.html'
})
export class LabelGroupMenuItemComponent implements OnInit {

    @Input() title: string = ''
    @Input() display: boolean = true;

    constructor() {
    }

    ngOnInit(): void {
    }
}
