const clientDefault = {
    name: '',
    cnpj: '',
    avatar: '',
    razaoSocial: ''
}

const projectDefault = {
    name: '',
    clients: '',
    is_activate: true
}

const collaboratorDefault = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    is_admin: false,
    is_active: true
}

export {
    clientDefault,
    projectDefault,
    collaboratorDefault
}