import { ControlSelectOptions } from "../models/control-select-options"

const typeContracts: ControlSelectOptions[] = [
    {
        label: "CLT",
        value: "CLT"
    },
    {
        label: "PJ",
        value: "PJ"
    },
]

export default typeContracts