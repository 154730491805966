<div class="card-statistics border-20">
    <div class="row p-0 d-flex align-items-center">
        <div class="col-lg-8">
            <span class="mb-3"> {{ description }} </span>
            <h4> {{ total || "--" }} </h4>
        </div>

        <div class="col-lg-4">
            <div class="h-100 card-statistics-wrapper-icon border-20 ms-auto">
                <img [src]="icon" alt="">
            </div>
        </div>
    </div>
</div>
