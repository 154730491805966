import { ActionUserTypes } from './user.action'
import { ActionModel } from '../action'
import { User } from '../../models/user'

const initialState: User = {
    id: "",
    firstName: "",
    lastName: "",
    email: "",
    picture: "",
    role: ["public"],
    typeContract: "PJ"
}

export function userReducer(state: User = initialState, action: ActionModel) {
    switch (action.type) {
        case ActionUserTypes.Add: {
            state = { ...action.payload }
            return state
        }

        case ActionUserTypes.Clear: {
            state = initialState
            return state
        }

        default:
            return state
    }
}
