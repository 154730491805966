<div
	class="header-fixed header-tablet-and-mobile-fixed toolbar-enabled toolbar-fixed aside-enabled aside-fixed"
	style="--kt-toolbar-height: 0px; --kt-toolbar-height-tablet-and-mobile: 0px"
	[attr.data-kt-aside-minimize]="minimizeAside ? 'on' : 'off'"
	[attr.data-kt-drawer]="showMenu ? 'on' : 'off'"
	(window:resize)="initWindow($event)"
	(window:DOMContentLoaded)="initWindow($event)"
>
	<div class="d-flex flex-column flex-root">
		<div class="page d-flex flex-row flex-column-fluid">
			<div
				[class]="
					'aside aside-hoverable ' +
					(animating ? 'animating ' : '') +
					(minimizeAside ? 'drawer drawer-start drawer-on ' : '') +
					(showMenu ? 'drawer-on ' : '') +
					(isMobile ? 'drawer drawer-start' : '')
				"
				data-kt-drawer="true"
				data-kt-drawer-name="aside"
				data-kt-drawer-activate="{default: true, lg: false}"
				data-kt-drawer-overlay="true"
				data-kt-drawer-width="{default:'200px', '300px': '250px'}"
				data-kt-drawer-direction="start"
				data-kt-drawer-toggle="#kt_aside_mobile_toggle"
			>
				<div class="aside-logo flex-column-auto">
					<a routerLink="/admin/dashboard">
						<img
							alt="Logo"
							src="assets/images/temp/catskillet.svg"
							class="logo h-70px"
							[class.aside-logo-compacted]="minimizeAside"
						/>
					</a>
				</div>
				<div class="aside-menu flex-column-fluid">
					<div class="my-5 my-lg-5">
						<div
							class="menu menu-column menu-title-gray-800"
							data-kt-menu="true"
						>
							<app-menu-item
								class="bright"
								*ngFor="let item of menuSystem"
								[title]="item.title"
								[link]="item.link"
								[icon]="item.icon"
								[subItems]="item.subItems"
							></app-menu-item>
						</div>
					</div>
				</div>
				<div
					class="d-flex justify-content-center mb-5 w-75 align-self-center"
				>
					<a class="w-100 link-timesheet" [href]="'/timesheet'">
						Ir para timesheet
					</a>
				</div>
				<div
					class="logout-button d-flex gap-2 justify-content-center mb-5 py-4 w-75 align-self-center rounded bg-secondary cursor-pointer"
					(click)="logout()"
				>
					<h3 class="m-0" [class.d-none]="minimizeAside">Sair</h3>
					<i class="bi bi-box-arrow-right m-0"></i>
				</div>
			</div>
			<div class="wrapper d-flex flex-column flex-row-fluid">
				<div class="header px-5 align-items-stretch px-lg-10">
					<div
						class="w-100 d-flex align-items-stretch justify-content-between"
					>
						<div
							class="d-flex align-items-center d-lg-none ms-n3 me-1"
							title="Mostrar menu lateral"
						>
							<div
								class="btn btn-icon w-auto px-0 aside-toggle"
								(click)="toggleMenu()"
							>
								<span class="svg-icon svg-icon-1">
									<svg
										width="20"
										height="20"
										viewBox="0 0 20 20"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
									>
										<path
											fill-rule="evenodd"
											clip-rule="evenodd"
											d="M2 5C2 4.44771 2.44771 4 3 4H17C17.5523 4 18 4.44771 18 5C18 5.55228 17.5523 6 17 6H3C2.44771 6 2 5.55228 2 5ZM2 15C2 14.4477 2.44772 14 3 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H3C2.44771 16 2 15.5523 2 15ZM3 9C2.44771 9 2 9.44772 2 10C2 10.5523 2.44771 11 3 11H17C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9H3Z"
											fill="#3F434A"
										/>
									</svg>
								</span>
							</div>
						</div>
						<div
							class="d-flex align-items-stretch justify-content-between w-100"
						>
							<div class="d-flex align-items-stretch">
								<div
									class="header-menu align-items-stretch"
									data-kt-drawer="true"
									data-kt-drawer-name="header-menu"
									data-kt-drawer-activate="{default: true, lg: false}"
									data-kt-drawer-overlay="true"
									data-kt-drawer-width="{default:'200px', '300px': '250px'}"
									data-kt-drawer-direction="end"
									data-kt-drawer-toggle="#kt_header_menu_mobile_toggle"
									data-kt-swapper="true"
									data-kt-swapper-mode="prepend"
									data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
								>
									<div
										class="d-flex justify-content-between align-items-center new-nav"
										[class]="
											minimizeAside
												? 'new-nav-off '
												: 'new-nav-on'
										"
									>
										<div
											[class]="
												'btn btn-icon w-auto px-0 aside-toggle ' +
												(minimizeAside ? 'active' : '')
											"
											(click)="toggleMinimizeAside()"
										>
											<span class="svg-icon svg-icon-1">
												<svg
													width="20"
													height="20"
													viewBox="0 0 20 20"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														fill-rule="evenodd"
														clip-rule="evenodd"
														d="M2 5C2 4.44771 2.44771 4 3 4H17C17.5523 4 18 4.44771 18 5C18 5.55228 17.5523 6 17 6H3C2.44771 6 2 5.55228 2 5ZM2 15C2 14.4477 2.44772 14 3 14H12C12.5523 14 13 14.4477 13 15C13 15.5523 12.5523 16 12 16H3C2.44771 16 2 15.5523 2 15ZM3 9C2.44771 9 2 9.44772 2 10C2 10.5523 2.44771 11 3 11H17C17.5523 11 18 10.5523 18 10C18 9.44772 17.5523 9 17 9H3Z"
														fill="#3F434A"
													/>
												</svg>
											</span>
										</div>

										<div
											class="d-flex gap-5 align-items-center"
										>
											<!-- <div class="d-flex gap-5">
												<i class="bi bi-search" style="font-size: 20px;"></i>
												<i class="bi bi-bell" style="font-size: 20px;"></i>
											</div>
											<div class="vr"></div> -->
											<div
												class="user-profile d-flex flex-row align-items-center gap-2"
											>
												<app-image
													[path]="user?.picture"
													[title]="user?.firstName"
													[icon]="true"
													class="wrapper-image-profile"
												></app-image>

												<p class="fw-normal user-name">
													Olá, {{ user?.firstName }}
												</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="content d-flex flex-column flex-column-fluid container-sidebar"
				>
					<div class="container-fluid">
						<ng-content></ng-content>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div
	*ngIf="showMenu"
	(click)="toggleMenu()"
	style="z-index: 109"
	class="drawer-overlay"
></div>
