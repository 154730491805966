<div class="collaborator-projects">
    <div class="mb-5 d-flex gap-5 align-items-center">
        <h4> Filtro de horas: </h4>

        <div class="d-flex gap-4 align-items-center">
            <input 
                type="date"
                class="input-date form-group"
                [max]="currentDate" 
                [(ngModel)]="startDate"
                (ngModelChange)="changeDate($event, 'start')"
            >
            <span> Até </span>
            <input 
                type="date" 
                class="input-date form-group"
                [max]="currentDate" 
                [min]="startDate" 
                [(ngModel)]="endDate"
                (ngModelChange)="changeDate($event, 'end')"
            />
        </div>
    </div>

    <div class="main-projects-body">
        <div class="wrapper-table-search p-0">
            <div class="my-5">
                <div class="wrapper-search">
                    <i class="bi bi-search search"></i>
                    <input type="text" class="w-100 input-search" (keyup)="searchProjects($event)"
                        placeholder="Pesquisar projeto">
                </div>
            </div>
            
            <h4 class="total-hours">
                Total de horas: {{ totalHours || 0 }}h
            </h4>
            
            <div class="wrapper-table-user-projects">
                <app-table-list [listHeader]='collaboratorProjectsHeader' [listBody]='listFilters' [loading]="loading">
                    <ng-template let-item #body>
                        <td>
                            <a [routerLink]="'/admin/projects/' + item.uuid">
                                {{ item.name }}
                            </a>
                        </td>
                        <td class="text-center">
                            {{ item.hours }}h
                        </td>
                        <td class="text-center">
                            {{ item.overtime }}h
                        </td>
                        <td class="text-center">
                            <strong>{{ item.total }}h</strong>
                        </td>
                    </ng-template>
                </app-table-list>
            </div>
        </div>
    </div>
</div>
