import { Component, OnInit } from '@angular/core'
import { ListMenu } from '../../models/list-menu'
import { Event, NavigationEnd, Route, Router } from '@angular/router'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/app-state'
import { Observable } from 'rxjs'
import { User } from 'src/app/models/user'
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
	selector: 'app-template-default',
	templateUrl: './template-default.component.html',
	styleUrls: ['./template-default.component.scss'],
})
export class TemplateDefaultComponent implements OnInit {
	private user$?: Observable<User>
	user?: User

	titlePage: string = ''
	minimizeAside: boolean = false
	showMenu: boolean = false
	animating: boolean = false
	isMobile: boolean = true

	menuSystem: Array<ListMenu> = []
	menuProfile: Array<ListMenu> = []

	constructor(
		private router: Router,
		private store: Store<AppState>,
		private authService: AuthService,
	) {
		this.user$ = this.store.select('user')
		this.user$.subscribe((state) => {
			this.user = state
		})

		this.menuSystem = [
			{
				title: 'Visão Geral',
				link: '/admin/dashboard',
				icon: '/assets/images/temp/overview.svg',
			},
			{
				title: 'Clientes',
				link: '/admin/clients',
				icon: '/assets/images/temp/clients.svg',
			},
			{
				title: 'Projetos',
				link: '/admin/projects',
				icon: '/assets/images/temp/projects.svg',
			},
			{
				title: 'Colaboradores',
				link: '/admin/collaborators',
				icon: '/assets/images/temp/peoples.svg',
			},
			{
				title: 'Relatórios',
				link: '/admin/reports',
				icon: '/assets/images/temp/colaborator.svg',
			},
            {
                title: 'Anotações',
                link: '/admin/notes',
                icon: '/assets/images/temp/icon-calendar.svg',
            },
		]
		this.menuProfile = []
	}

	ngOnInit(): void {
		this.initWindow()
		this.getTitlePage()
		this.watchTitlePage()
	}

	initWindow(evt?: any): void {
		this.isMobile = evt?.currentTarget?.innerWidth <= 992
		if (this.isMobile) {
			this.minimizeAside = false
		}
	}

	toggleMinimizeAside(): void {
		this.minimizeAside = !this.minimizeAside

		if (this.minimizeAside) {
			this.animating = true

			setTimeout(() => {
				this.animating = false
			}, 250)
		}
	}

	toggleMenu(): void {
		this.showMenu = !this.showMenu
	}

	watchTitlePage(): void {
		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				this.getTitlePage()
			}
		})
	}

	private getTitlePage() {
		const { config } = this.router
		let currentRoute: Route | undefined

		config.forEach((item) => {
			if (item.children?.length) {
				item.children.filter((subItem) => {
					if (
						`${item.path}/${subItem.path}` ===
						this.router.url.replace('/', '')
					) {
						currentRoute = subItem
					}
				})
			}
			if (item.path === this.router.url.replace('/', '')) {
				currentRoute = item
			}
		})
		if (currentRoute?.data) {
			this.titlePage = currentRoute?.data.title
		}
	}

	logout() {
		this.authService.logout().subscribe(() => {
		  //@ts-ignore
		  window.location.href = '/'
		})
	  }
}
