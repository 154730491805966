import {
	Component,
	ContentChild, EventEmitter,
	Input,
	OnInit, Output,
	TemplateRef
} from '@angular/core'
import { ListHeader } from '../../models/list-header'

@Component({
	selector: 'app-table-list',
	templateUrl: './table-list.component.html',
	styleUrls: ['./table-list.component.scss'],
})
export class TableListComponent implements OnInit {
	@Input() listHeader: ListHeader[] | undefined = []
	@Input() listBody?: any = []
	@Input() pageSize = 20
	@Input() page = 1
	@Input() loading = false
	@Input() total?: number = 0
	@Output() emmitChangePagination: EventEmitter<any> = new EventEmitter()

	@ContentChild('body', { static: false }) bodyTemplateRef?: TemplateRef<any>

	constructor() { }

	ngOnInit(): void { }

	changePagination(pageChanged: number) {
		this.emmitChangePagination.emit(pageChanged)
	}
}
