<div class="input-check control-checkbox">
	<div class="my-5">
		<div
			class="form-check form-check-custom form-check-solid form-check-lg"
			[formGroup]="form"
		>
			<input
				class="form-check-input"
				#element
				type="radio"
				[id]="'c-' + id || name"
				[attr.name]="name"
				[formControlName]="name"
				[value]="inputModel"
				(change)="onChange(inputModel)"
			/>
			<label class="form-check-label" [for]="'c-' + id || name"
				><ng-content></ng-content
			></label>
		</div>
	</div>
</div>
