import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalConfirmComponent } from 'src/app/components/modals/modal-confirm/modal-confirm.component'
import { ListHeader } from 'src/app/models/list-header'
import { ClientsService } from 'src/app/services/clients/clients.service'
import { clientHeader } from 'src/app/mocks/client-header'
import { HelpersService } from 'src/app/services/helpers/helpers.service'
import { Client } from 'src/app/models/client'
import { ToastrService } from 'ngx-toastr'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { clientDefault } from 'src/app/mocks/default-form'
import { ModalCustomService } from 'src/app/services/modal/modal-service.service'

@Component({
	selector: 'app-clients',
	templateUrl: './clients.component.html',
	styleUrls: ['./clients.component.scss'],
})
export class ClientsComponent implements OnInit {
	@ViewChild('modalCustom', { static: false }) modalCustom?: TemplateRef<any>

	clientHeader: ListHeader[] = clientHeader
	clients: Client[] = []
	listFilters: Client[] = []
	form: FormGroup | any
	clientSelected?: any
	total: number = 0
	searchTime: any
	params: any = {}
	page: number = 1
	loadingTable: boolean = false

	constructor(
		private clientsService: ClientsService,
		private modalService: NgbModal,
		public helpersService: HelpersService,
		private toast: ToastrService,
		private formBuilder: FormBuilder,
		private modalCustomService: ModalCustomService
	) {}

	ngOnInit(): void {
		this.form = this.formBuilder.group({
			name: ['', Validators.required],
			cnpj: ['', []],
			avatar: [''],
			razaoSocial: [''],
		})

		this.getClients()
	}

	searchClient(event: any) {
		clearTimeout(this.searchTime)
		this.searchTime = setTimeout(() => {
			const searchValue = event.target.value.trim()
			const param: string = this.setParam('search', searchValue)
			this.getClients(param)
		}, 350)
	}

	getClients(params: string = '') {
		const self = this
		this.loadingTable = true

		this.clientsService.getClientsForAdmin(
			{
				fnSuccess({ data, count }) {
					self.total = count
					self.clients = data
					self.listFilters = data
					self.loadingTable = false
				},
				fnError(err) {
					console.warn(err)
					self.loadingTable = false
				},
			},
			params
		)
	}

	changePagination(page: any) {
		this.page = page
		const param: string = this.setParam('page', page)
		this.getClients(param)
	}

	fnParam(name?: string, value?: any): string {
		if (name) {
			if (name !== 'page') {
				this.params['page'] = 1
				this.page = 1
			}

			this.params[name] = value
		}

		const params = Object.entries(this.params)
			.map((param, index) => {
				const [query, value] = param
				const cancat = index === 0 ? '?' : '&'
				return `${cancat}${query}=${value}`
			})
			.join('')

		return params
	}

	getParams() {
		return this.fnParam()
	}

	setParam(name: string, value: any) {
		return this.fnParam(name, value)
	}

	showModal(title: string) {
		const data = {
			content: this.modalCustom,
			title,
		}

		this.modalCustomService.showModal(data)
		this.modalCustomService.cancelModal(() => {
			this.form?.reset(clientDefault)
		})
	}

	updateClient(clientId: string) {
		const self = this
		const data = this.helpersService.converterCamelCaseToSnake(
			this.form.value
		)

		this.clientsService.updateClient(data, clientId, {
			fnSuccess(data) {
				const params = self.getParams()
				self.getClients(params)
				self.toast.success('Cliente atualizado com sucesso!')
				self.closeModal()
			},
			fnError(err) {
				console.warn(err)
				self.showErrorMessage(err.error, () => {
					self.toast.error('Erro na atualização do cliente!')
				})
				self.closeModal()
			},
		})
	}

	createClient() {
		const self = this
		const data = this.helpersService.converterCamelCaseToSnake(
			this.form.value
		)

		this.clientsService.createClient(data, {
			fnSuccess(data) {
				const params = self.getParams()
				self.getClients(params)
				self.toast.success('Cliente criado com sucesso!')
				self.closeModal()
			},
			fnError(err) {
				console.warn(err)
				self.closeModal()
				self.showErrorMessage(err.error, () => {
					self.toast.error('Erro na criação do cliente!')
				})
			},
		})
	}

	deleteClient(client: Client) {
		const self = this

		const modalRef = this.modalService.open(ModalConfirmComponent, {
			windowClass: 'modal-confirm',
		})

		modalRef.componentInstance.title =
			'Tem certeza que deseja excluir esse(s) cliente(s)?'
		modalRef.componentInstance.btnTitle = 'Excluir'

		modalRef.componentInstance.callbackConfirmation = () => {
			this.clientsService.deleteClient(client.uuid, {
				fnSuccess(data) {
					const params = self.getParams()
					self.getClients(params)
					self.toast.success('Cliente deletado com sucesso!')
					modalRef.close()
				},
				fnError(err) {
					console.warn(err)
					self.toast.error('Erro ao deletar cliente!')
				},
			})
		}
	}

	setAvatar(avatar: string) {
		this.form?.controls['avatar'].setValue(avatar)
	}

	closeModal() {
		this.modalCustomService.closeModal()
	}

	save(client?: Client) {
		this.clientSelected = client
		let titleModal = 'Adicionar novo cliente'

		if (client) {
			this.form?.setValue({
				name: client.name,
				cnpj: client.cnpj,
				avatar: client.avatar,
				razaoSocial: client.razao_social,
			})

			titleModal = `Editar ${client.name}`
		}

		this.showModal(titleModal)
	}

	onSubmit() {
		if (!this.clientSelected) this.createClient()
		else this.updateClient(this.clientSelected.uuid)
	}

	showErrorMessage(error: any, callbackError?: Function) {
		if (error.cnpj) {
			if (error.cnpj[0].includes('cnpj already')) {
				this.toast.error('Cliente com cnpj já cadastrado!')
			} else if (error.cnpj[0].includes('not valid')) {
				this.toast.error('Cnpj inválido!')
			} else {
				if (callbackError) callbackError()
			}
		} else {
			if (callbackError) callbackError()
		}
	}
}
