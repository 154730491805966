import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Project } from 'src/app/models/project';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { Chart, ChartData } from 'chart.js';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { optionsStatus } from 'src/app/mocks/options-status';
import { ControlSelectOptions } from 'src/app/models/control-select-options';
import { ModalCustomService } from 'src/app/services/modal/modal-service.service';
import { ClientsService } from 'src/app/services/clients/clients.service';
import tooltipChart from 'src/app/mocks/tooltip-chart';
import { Budget } from 'src/app/models/budget';
import { ToastrService } from 'ngx-toastr';
import usersHeader from 'src/app/mocks/users.header';


@Component({
  selector: 'app-project-profile',
  templateUrl: './project-profile.component.html',
  styleUrls: ['./project-profile.component.scss']
})
export class ProjectProfileComponent implements OnInit {
  @ViewChild('modalCustom', { static: false }) modalCustom?: TemplateRef<any>

  project: Project | any
  chart: ChartData<'bar'> | any = {}
  form: FormGroup | any
  loading: boolean = true
  clients: ControlSelectOptions[] = []
  optionsStatus: ControlSelectOptions[] = optionsStatus
  plugins = tooltipChart
  usersHeader = usersHeader
  listFilters: any[] = []
  plots: ChartData<'line'> | any

  constructor(
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    public helpers: HelpersService,
    private modalCustomService: ModalCustomService,
    private formBuilder: FormBuilder,
    private clientsService: ClientsService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const projectId = params["id"]
      this.getProject(projectId)
      this.getClients()
      this.getPlots(projectId)
    });
  }

  getProject(projectId: string) {
    const self = this
    this.loading = true

    this.projectsService.getProject(projectId, {
      fnSuccess(data) {
        self.project = data
        self.setForm()
        self.setChart()
        self.loading = false

        const collaborators: any[] = []

        self.project.budget.users.forEach((user: any) => {
          user.hours.forEach((hour: any) => {
            const fullName = `${user.first_name} ${user.last_name}`
            const functionDefault = hour.function || "Não informado"

            const collaboratorExists = collaborators.find((collaborator: any) => {
              return collaborator.name === fullName
            })

            if (collaboratorExists) {
              if (!collaboratorExists.roles.includes(functionDefault)) {
                collaboratorExists.roles.push(functionDefault)
              }
              collaboratorExists.total_hours += hour.expediente + hour.extra
            } else {
              collaborators.push({
                name: fullName,
                total_hours: hour.expediente + hour.extra,
                uuid: user.uuid,
                roles: [functionDefault]
              })
            }
          })
        })

        self.listFilters = collaborators
      }, fnError(err) {
        self.loading = false
        console.warn("err", err)
      }
    })
  }

  setForm() {
    this.form = this.formBuilder.group({
      name: [this.project.name, Validators.required],
      clients: [this.project.clients.uuid, Validators.required],
      is_activate: [this.project.is_activate],
      budget: this.formBuilder.array([])
    })

    if (this.project.budget) {
      this.setBudget(this.project.budget)
    }
  }

  getClients() {
    const self = this

    this.clientsService.getClientsForAdmin({
      fnSuccess(data) {
        self.clients = data.map((option: any) => ({
          value: option.uuid,
          label: option.name
        }))
      },
      fnError(err) {
        console.warn(err)
      }
    }, '?all')
  }

  getPlots(projectId: string) {
    const self = this

    this.projectsService.getPlots(projectId, {
      fnSuccess(body) {
        const labels: any = []
        const datasets: any = []

        if (body && body.length) {
          body.forEach(({ data, label }: any) => {
            labels.push(label)
            data.evolution.forEach(({ skill, hours }: any) => {
              const skillExists = datasets.find((itemSkill: any) => itemSkill.label === skill)

              if (!skillExists) {
                datasets.push({
                  label: skill,
                  data: [0, hours],
                  fill: false,
                  backgroundColor: self.getColorsForList(data.evolution),
                  borderColor: self.getColorsForList(data.evolution),
                  tension: 0.1,
                  pointRadius: 0,
                })
              } else {
                skillExists.data.push(hours)
              }
            })
          })

          if (labels.length) {
            const numberOfLastLabel = labels.at(-1).split(" ")[1]
            const nextLabel = `week ${Number(numberOfLastLabel) + 1}`
            labels.push(nextLabel)
          }

          self.plots = {
            labels, datasets
          }
        }
      },
      fnError(err) {
        console.warn(err)
      }
    })
  }

  getColorsForList(list: any[]): string[] {
    const baseColor = [0x86, 0xC9, 0xFD]; // RGB values of #86C9FD
    const colorMap: any = {};
    return list.map((_: any, index: number) => {
      let r, g, b;
      do {
        r = (baseColor[0] + index * 17) % 256;
        g = (baseColor[1] + index * 23) % 256;
        b = (baseColor[2] + index * 29) % 256;

        const colorKey = `${r},${g},${b}`;
        if (colorMap[colorKey]) {
          index++;
          continue;
        }
        colorMap[colorKey] = true;
        break;
      } while (true);

      return `rgba(${r}, ${g}, ${b})`;
    });
  }

  showModal() {
    const data = {
      content: this.modalCustom,
      title: `Editar ${this.project.name}`
    }

    this.modalCustomService.showModal(data)
  }

  closeModal() {
    this.modalCustomService.closeModal()
  }

  budgets(): FormArray {
    return this.form.get('budget') as FormArray;
  }

  setBudget(budget: any) {
    this.budgets().clear()

    if (budget?.info_budget && budget?.info_budget.length) {
      budget?.info_budget.forEach((budget: Budget) => {
        const newBudget = this.formBuilder.group({
          skill: [budget.skill, Validators.required],
          total_hours: [budget.total_hours, Validators.required]
        });

        this.budgets().push(newBudget);
      })
    }
  }

  setChart() {
    if (this.project) {
      const { budget } = this.project

      let usedDataset: any = []
      let contractedDataset: any = []

      if (budget?.users) {
        budget?.users.forEach((item: any) => {
          item.hours.forEach((hour: any) => {
            const functionDefault = hour.function || "Não informado"
            const skillExist = usedDataset.find(({ id }: any) => id === functionDefault)
            const totalHours = hour.expediente + hour.extra
            const dataItem = {
              context: `${item.first_name} ${item.last_name}`,
              hoursNormal: hour.expediente,
              overtime: hour.extra
            }

            if (skillExist) {
              skillExist.nested.team.push(dataItem)
              skillExist.nested.total = skillExist.nested.total + totalHours
            } else {
              usedDataset.push({
                id: functionDefault,
                nested: {
                  team: [dataItem],
                  total: totalHours
                }
              })
            }
          })
        })
      }

      if (budget?.info_budget && budget?.info_budget.length) {
        budget?.info_budget.forEach((budgetItem: any) => {
          contractedDataset.push({
            id: budgetItem.skill,
            nested: { total: budgetItem.total_hours }
          })
        })
      } else if (usedDataset.length) {
        usedDataset.forEach((item: any) => {
          const skillExist = contractedDataset.find((contractedItem: any) => contractedItem.id === item.id)

          if (skillExist) {
            skillExist.nested.total = skillExist.nested.total + item.nested.total
          } else {
            contractedDataset.push({
              id: item.id,
              nested: { total: item.nested.total }
            })
          }
        })
      }

      this.chart = {
        datasets: [
          {
            label: "Hora contratada",
            backgroundColor: '#8DA1C1',
            data: contractedDataset
          },
          {
            label: "Hora utilizada",
            backgroundColor: '#4285F4',
            data: usedDataset
          },
        ]
      }
    }
  }

  getInitials(user: any) {
    return this.helpers.getInitials(`${user.first_name} ${user.last_name}`)
  }

  setColorRandom(index: number) {
    const colors = ['#4285F4', '#ECB22E', '#FC473D', '#34A853', '#8DA1C1']
    return colors[index % colors.length]
  }

  onSubmit() {
    const self = this
    const data = { ...this.form.value }
    const projectId: string = this.project.uuid

    this.projectsService.updateProject(data, projectId, {
      fnSuccess(data) {
        self.getProject(projectId)
        self.toast.success("Projeto atualizado com sucesso!")
        self.closeModal()
      },
      fnError(err) {
        console.warn(err)
        self.toast.error("Error na atualização do projeto!")
        self.loading = false
        self.closeModal()
      }
    })
  }
}
