import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks'
import { environment } from 'src/environments/environment'
import { finalize } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class ProjectsService {
	constructor(private http: HttpClient) {}

	getProject(projectId: string, callback: Callbacks) {
		this.http
			.get<any>(`${environment.api}/admin/projects/${projectId}/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	getProjects(callback: Callbacks) {
		this.http
			.get<any>(`${environment.api}/projects/?all`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: ({ data }) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	getProjectByClient(callback: Callbacks) {
		this.http
			.get<any>(`${environment.api}/projects/?limit=1000`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	getProjectsForAdmin(callback: Callbacks, params: string = '') {
		this.http
			.get<any>(`${environment.api}/admin/projects/${params}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	getProjectByClientForAdmin(clientId: string, callback: Callbacks) {
		this.http
			.get<any>(`${environment.api}/admin/projects/?client=${clientId}`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	addProject(data: any, callback: Callbacks) {
		this.http
			.post<any>(`${environment.api}/admin/projects/`, data)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	updateProject(data: any, projectId: string, callback: Callbacks) {
		this.http
			.patch<any>(`${environment.api}/admin/projects/${projectId}/`, data)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	deleteProject(uuid: string, callback: Callbacks) {
		this.http
			.delete<any>(`${environment.api}/admin/projects/${uuid}/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	getPlots(id: string, callback: Callbacks) {
		this.http
			.get<any>(`${environment.api}/admin/projects/${id}/plots/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}
}
