import { FormPages } from '../models/form-pages'

export const formPages: FormPages[] = [
    {
        id: 'e3260d38-5e8e-48e2-9f84-283fc24ba623',
        title: 'Test page one',
        description: 'Description page one',
        questions: [
            {
                id: 'de3c7f95-8ae4-4a07-b6c7-1b603e510bd6',
                label: 'Full name',
                name: 'full_name',
                value: '',
                type: 'text',
                validation: {
                    required: true,
                },
            },
            // {
            //     id: 'de3c7f95-8ae4-4a07-b6c7-1b603e510bd7',
            //     label: 'Last name',
            //     name: 'last_name',
            //     value: '',
            //     type: 'text',
            //     validation: {
            //         required: true,
            //     },
            // },
            {
                id: 'de3c7f95-8ae4-4a07-b6c7-1b603e510123',
                label: 'CPF',
                name: 'cpf',
                value: '',
                type: 'tel',
                mask: '000.000.000-00',
                placeholder: 'Ex.: 000.000.000-00',
                validation: {
                    required: true,
                    isValidCpf: true
                },
            },
            {
                id: '988fe9cf-6e38-4120-a278-20345e48e968',
                label: 'Which is your gender?',
                name: 'which_is_your_gender',
                value: '',
                type: 'select',
                placeholder: 'Select value',
                validation: {
                    required: true,
                },
                options: [
                    { label: 'Male', value: 'Male' },
                    { label: 'Female', value: 'Female' },
                    { label: 'Other', value: 'Other' },
                ],
                conditional: {
                    value: 'Other',
                    questions: [
                        {
                            id: '3d9c024f-ff42-4aa4-b91d-7594f66d6f10',
                            label: 'Describe',
                            name: 'describe',
                            value: '',
                            type: 'text',
                            validation: {
                                required: true,
                            },
                        },
                    ],
                },
            },
            {
                id: '16391210-c81f-48ef-b81d-2f8a6959d9e4',
                label: 'Birthdate?',
                name: 'birthdate',
                value: '',
                placeholder: 'Ex.: 10/10/2000',
                type: 'tel',
                mask: '00/00/0000',
                validation: {
                    required: true,
                },
            },
            {
                id: '988fe9cf-6e38-4120-a278-20345e48e967',
                label: 'Do you own a car?',
                name: 'do_you_own_a_car',
                value: '',
                type: 'radio',
                validation: {
                    required: true,
                },
                options: [
                    { label: 'Yes', value: 'Yes' },
                    { label: 'No', value: 'No' },
                ],
                conditional: {
                    value: 'Yes',
                    questions: [
                        {
                            id: '3d9c024f-ff42-4aa4-b91d-7594f66d6f99',
                            label: 'Which model?',
                            name: 'which_model',
                            value: '',
                            type: 'text',
                            validation: {
                                required: true,
                            },
                        },
                    ],
                },
            },
        ],
    },
    {
        id: '1cfa7ac1-ea5f-4d70-bb8d-a49c3a965420',
        title: 'Test page two',
        description: 'Description page two',
        questions: [
            {
                id: 'b916afa2-ed50-471e-a97d-e3ea7875872e',
                label: 'Which activities do you perform?',
                name: 'which_activities_do_you_perform',
                value: '',
                type: 'checkbox',
                validation: {
                    required: true,
                },
                options: [
                    { label: 'Football', value: 'Football' },
                    { label: 'Basketball', value: 'Basketball' },
                    { label: 'Baseball', value: 'Baseball' },
                    { label: 'Neither', value: 'Neither' },
                ],
            },
        ],
    },
]
