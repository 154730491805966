import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class CollaboratorsService {

  constructor(
    private http: HttpClient,
  ) { }

  getOneCollaborator(uuid: string, callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/admin/accounts/${uuid}/`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  getCollaborators(callback: Callbacks, params: string = "") {
    this.http
      .get<any>(`${environment.api}/admin/accounts/${params}`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  getCollaboratorProfile(uuid: string, callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/admin/accounts/${uuid}/`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  addCollaborators(data: any, callback: Callbacks) {
    this.http
      .post<any>(`${environment.api}/admin/accounts/`, data)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  updateCollaborator(data: any, collaboratorId: string, callback: Callbacks) {
    this.http
      .patch<any>(`${environment.api}/admin/accounts/${collaboratorId}/`, data)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }

  deleteCollaborator(uuid: string, callback: Callbacks) {
    this.http
      .delete<any>(`${environment.api}/admin/accounts/${uuid}/`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
          console.warn(err)
        }
      })
  }
}
