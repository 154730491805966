<div class="main-form-collaborator">
    <div class="wrapper-form-group">
        <app-input 
            [form]="form" 
            name="first_name" 
            id="first_name"
            labelName="Nome*" 
            placeholder="Nome do Colaborador"
            [inputModel]="form.value.first_name"
        ></app-input>

        <app-input 
            [form]="form" 
            name="last_name" 
            id="last_name"
            labelName="Sobrenome*" 
            placeholder="Sobrenome do Colaborador"
            [inputModel]="form.value.last_name"
        ></app-input>
        
        <app-input 
            [form]="form" 
            name="email" 
            id="email"
            labelName="E-mail*" 
            placeholder="E-mail do Colaborador"
            [inputModel]="form.value.email"
            [readonly]="true"
        ></app-input>

        <app-checkbox
            [form]="form" 
            id="is_admin"
            name="is_admin"
            labelName="Administrador"
            [inputModel]="form.value.is_admin"
        ></app-checkbox>

        <form *ngIf="form" class="mb-4" [formGroup]="form">
            <label for="skills" class="form-label">Habilidades</label>
            <ng-select2 
                [data]="skills" 
                placeholder="Selecione as habilidades"
                id="skills"
                name="skills"
                [options]="options"
                formControlName="skills"
            ></ng-select2>
        </form>

        <!-- <app-select 
            [form]="form" 
            name="type_contract"
            labelName="Tipo de contrato*" 
            [placeholder]="'Adicione um tipo de contrato'"
            id="type_contract"
            [inputModel]="form.value.type_contract || ''"
            [options]="typeContracts"
        ></app-select> -->

        <app-select 
            [form]="form" 
            name="role"
            labelName="Cargo*" 
            [placeholder]="'Adicione um cargo'"
            id="role"
            [options]="roles"
            [inputModel]="form.value.role || ''"
        ></app-select>
    </div>

    <div class="wrapper-buttons col-12 d-flex align-items-center justify-content-center">
        <button 
            type="button" 
            class="btn-save ms-3 btn-default" 
            [class.btn-disabled]="form.invalid"
            (click)="onSubmit()"
        >
            Salvar
        </button>
    </div>
</div>