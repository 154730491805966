<section class="wrapper-login">
    <div class="login-container container">
        <figure class="wrapper-logo">
            <img src="/assets/images/temp/catskillet.svg" alt="catskillet" title="Catskillet Logo">
        </figure>

        <h2>FAÇA SEU LOGIN</h2>

        <div id="googleBtn"></div>

        <div class="wrapper-text">
            <figure>
                <img src="/assets/images/temp/wave.svg" alt="wave" title="Wave">
            </figure>
            
            <h3 class="d-flex gap-3">e marque seu horário</h3>

            <figure class="wrapper-icon-circle">
                <img src="/assets/images/temp/circle.svg" alt="circle" title="Circle">
            </figure>
        </div>

        <p class="text-block">
            <span>Caso não tenha acesso ao seu e-mail entre </span>
            <span>em contato com o administrador</span>
        </p>
    </div>
    <app-footer></app-footer>
</section>
