import { Component, OnInit } from '@angular/core'
import { FormPages } from '../../models/form-pages'
import {
    NgWizardConfig,
    STEP_STATE,
    StepChangedArgs,
    NgWizardService,
    THEME
} from 'ng-wizard'
import { ActivatedRoute, Router } from '@angular/router'
import { ToastrService } from 'ngx-toastr'
import { FormBuilder } from '@angular/forms'
import { HelpersService } from '../../services/helpers/helpers.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { formPages } from '../../mocks/form-pages'

interface BuildData {
    [key: string]: string
}

@Component({
    selector: 'app-quiz',
    templateUrl: './quiz.component.html',
    styleUrls: ['./quiz.component.scss']
})
export class QuizComponent implements OnInit {
    formPagesResponse: FormPages[] = formPages
    formPagesData: FormPages[] = formPages

    hasQuizId: boolean = false

    loading: boolean = false

    _reload: boolean = true

    currentStep: number = 0

    stepStates = {
        normal: STEP_STATE.normal,
        disabled: STEP_STATE.disabled,
        error: STEP_STATE.error,
        hidden: STEP_STATE.hidden
    }

    config: NgWizardConfig = {
        selected: 0,
        theme: THEME.arrows,
        toolbarSettings: {
            showPreviousButton: false,
            showNextButton: false
        }
    }

    constructor(
        private ngWizardService: NgWizardService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: FormBuilder,
        private helpers: HelpersService,
        private toast: ToastrService,
        private modalQuiz: NgbModal
    ) {
    }

    ngOnInit(): void {
        this.hasQuizId = !!this.getQuizIdLocale()

        if (!this.hasQuizId) {
            this.setQuizIdLocale('form')
        }
    }

    private reload() {
        setTimeout(() => (this._reload = false))
        setTimeout(() => (this._reload = true))
    }

    openModalSuccess() {
        alert('Success!')
    }

    stepChanged(args: StepChangedArgs) {
        const { index } = args.step
        this.currentStep = index
    }

    previous() {
        this.ngWizardService.previous()
    }

    next() {
        this.ngWizardService.next()
    }

    setQuizIdLocale(id: string) {
        localStorage.setItem('quiz_id', id)
    }

    getQuizIdLocale(): string {
        return localStorage.getItem('quiz_id') || ''
    }

    removeQuizIdLocale() {
        localStorage.removeItem('quiz_id')
    }

    resetQuiz() {
        this.removeQuizIdLocale()
        // @ts-ignore
        window.location = window.location.href
    }

    buildDataFromQuestion(question: any, values: BuildData): any {
        if (question.conditional) {
            question.conditional.questions.forEach((subQuestion: any) => {
                subQuestion = this.buildDataFromQuestion(subQuestion, values)
            })
        }

        const currentQuestionValue = values[question.id]

        if (currentQuestionValue) question.value = currentQuestionValue

        return question
    }

    buildData(data: BuildData) {
        this.formPagesResponse.forEach((pillar) => {
            pillar.questions.forEach((question: any) => {
                question = this.buildDataFromQuestion(question, data)
            })
        })
    }

    onSubmit(data: any) {
        const id = this.getQuizIdLocale()
        if (id) {
            this.loading = true
            this.buildData(data)
            const finished =
                this.currentStep === this.formPagesResponse.length - 1

            if (finished) {
                this.openModalSuccess()
                this.removeQuizIdLocale()
            } else {
                this.next()
            }

            setTimeout(() => {
                this.loading = false
            }, 500)
        } else {
            this.toast.error('Error.', 'OPS!!!')
        }
    }
}
