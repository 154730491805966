<div class="main-calendar">
    <div class="row w-100 calendar-desktop m-0">
        <div [class]="{ 'col-9': !collaboratorAdmin }">

            <div class="wrapper-calendar">
                <full-calendar [options]="calendarOptions" class="full-calendar"  >
                    <ng-template #eventContent let-arg>
                        <h4>{{ arg.event.title }}</h4>
                    </ng-template>
                </full-calendar>
            </div>
        </div>

        <div class="col-3 d-flex align-items-center justify-content-center flex-column gap-10" *ngIf="!collaboratorAdmin">
            <div class="wrapper-summary">
                <div class="overview">
                    <h2 class="text-center">Resumo mensal</h2>

                    <div class="item-hours">
                        <strong>Horas</strong>: <span>{{ hours.normal }}h</span>
                    </div>
                    <div class="item-hours overtime">
                        <strong>Horas extras</strong>: <span>{{ hours.overtime }}h</span>
                    </div>
                </div>

                <div class="download-timesheet">
                    <button (click)="downloadTimesheet()">
                        baixar histórico mensal
                        <i class="bi bi-download"></i>
                    </button>
                </div>
            </div>

            <button class="btn-default btn-worksheet" (click)="downloadWorksheet()" *ngIf="user ? user.typeContract !== 'PJ' : !collaboratorAdmin">
                Baixar folha de ponto mensal  <i class="bi bi-download"></i>
            </button>
        </div>
    </div>

    <app-calendar-mobile
        [timesheetsByDate]="timesheetsByDate"
        (showModal)="showModalContent($event)"
        [hours]="hours"
        (handlerMonth)="handlerMonthCalendar($event)"
        (download)="downloadTimesheet()"
        (downloadWorksheetFn)="downloadWorksheet()"
        [usertypeContract]="user?.typeContract"
        [worksheets]="worksheets"
        (showDetailsClock)="showDetailsClock()"
    ></app-calendar-mobile>
</div>
