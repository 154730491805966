<div class="main-clients container">
    <app-table-header
        title="Anotações"
        (callbackAdd)="save()"
    ></app-table-header>

    <div class="main-clients-body">
        <div class="wrapper-table-search">
<!--            <div class="mb-5">-->
<!--                <div class="wrapper-search">-->
<!--                    <i class="bi bi-search search"></i>-->
<!--                    <input-->
<!--                        type="text"-->
<!--                        class="w-100 input-search"-->
<!--                        (keyup)="searchClient($event)"-->
<!--                        placeholder="Pesquisar dia"-->
<!--                    />-->
<!--                </div>-->
<!--            </div>-->
            <app-table-list
                [listHeader]='clientHeader'
                [listBody]='listFilters'
                (emmitChangePagination)="changePagination($event)"
                [total]="total"
                [loading]="loadingTable"
                [page]="page"
            >
                <ng-template let-item #body>
                    <td>
                        <span (click)="save(item)" class="cursor-pointer d-inline">
                            {{ helpersService.newDate(item.date) }}
                        </span>
                    </td>
                    <td *ngIf="item.reason == 'with_employees'">Acordado com funcionarios</td>
                    <td *ngIf="item.reason == 'holiday'">Feriado</td>
                    <td *ngIf="item.reason == 'others'">Outros</td>
                    <td>
                        <div class="wrapper-icons">
                            <i class="bi bi-pencil" (click)="save(item)"></i>
                            <i class="bi bi-trash" (click)="deleteNote(item)"></i>
                        </div>
                    </td>
                </ng-template>
            </app-table-list>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #modalCustom>
    <div class="row">
        <div class="col-lg-6">
            <app-input
                [form]="form"
                name="date"
                id="name"
                typeInput="date"
                labelName="Dia"
                [inputModel]="form.value.date"
            ></app-input>
            <app-select
                [form]="form"
                name="reason"
                id="reason"
                labelName="Motivo*"
                placeholder="Qual o motivo?"
                [inputModel]="form.value.reason"
                [options]="optionsTypes"
            ></app-select>
            <app-checkbox
                [form]="form"
                id="half_day"
                name="half_day"
                labelName="Neste dia será meio expediente?"
                [inputModel]="form.value.half_day"
            ></app-checkbox>
        </div>
    </div>

    <div class="wrapper-buttons col-12 d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-cancel btn-secondary " (click)="closeModal()">
            Cancelar
        </button>

        <button
            type="button"
            class="btn-save ms-3 btn-default"
            [class.btn-disabled]="form.invalid"
            (click)="onSubmit()"
        >
            Salvar
        </button>
    </div>
</ng-template>
