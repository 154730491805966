import { Component, Input, OnInit } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'app-checkbox-form-builder',
    templateUrl: './checkbox-form-builder.component.html',
    styleUrls: ['./checkbox-form-builder.component.scss']
})
export class CheckboxFormBuilderComponent implements OnInit {
    @Input() form: FormGroup = new FormGroup(Input())
    @Input() id?: string
    @Input() name: string | number | null = ''
    @Input() typeCheck: string = 'primary'
    @Input() value?: boolean | string
    @Input() disabled?: boolean
    @Input() labelName: string = ''
    @Input() inputModel: any
    @Input() changeModel?: Function

    constructor() {
    }

    ngOnInit(): void {
    }

    fnChangeModel(event: any): void {
        if (this.changeModel) this.changeModel(event)
    }
}
