<div class="modal-timesheet" id="modal-timesheet">
	<button class="btn-close" (click)="close()"></button>

	<div class="d-flex align-items-center flex-column mb-5">
		<img
			class="icon-wave"
			src="/assets/images/temp/wave.svg"
			alt="wave"
			title="Wave"
		/>
		<h2 class="timesheet-title">Marque o seu horário</h2>
	</div>

	<div class="wrapper-timesheet">
		<div class="timesheet-header">
			<h3 class="date-selected">
				Data: {{ date.split('-').reverse().join('/') }}
			</h3>
		</div>

		<div>
			<app-timesheet-control
				*ngIf="_reload"
				[timesheets]="timesheets"
				[date]="date"
				[weekday]="weekday"
				(emmitEventUpdateList)="callbackAddAndRemove()"
			></app-timesheet-control>
		</div>
	</div>
</div>
