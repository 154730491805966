import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { SkillsService } from 'src/app/services/skills/skils.service';
import { ControlSelectOptions } from 'src/app/models/control-select-options';
import { RolesService } from 'src/app/services/roles/roles.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { CollaboratorsService } from 'src/app/services/collaborators/collaborators.service';
import { ToastrService } from 'ngx-toastr';
import typeContracts from 'src/app/mocks/type-contracts';

@Component({
  selector: 'app-form-collaborator',
  templateUrl: './form-collaborator.component.html',
  styleUrls: ['./form-collaborator.component.scss']
})
export class FormCollaboratorComponent implements OnInit {
  @Input() collaborator?: any
  @Output() updateData: EventEmitter<any> = new EventEmitter()

  collaboratorCloned: any
  form: FormGroup | any
  skills: Array<Select2OptionData> = []
  roles: ControlSelectOptions[] = []
  typeContracts = typeContracts

  public options: Options;

  constructor(
    private fb: FormBuilder,
    private skillsService: SkillsService,
    private rolesService: RolesService,
    public helpers: HelpersService,
    private collaboratorService: CollaboratorsService,
    private toast: ToastrService
  ) {
    this.options = {
      multiple: true,
      width: '300'
    };
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      is_admin: [false],
      skills: [''],
      role: ['', Validators.required],
      type_contract: ['PJ', Validators.required],
    })

    this.getSkills()
    this.getRoles()
    this.setValueInForm()
    this.cloneCollaborator()
  }

  getSkills() {
    const self = this

    this.skillsService.getSkills({
      fnSuccess(skills) {
        self.skills = skills.data.map((skill: any) => ({
          id: skill.uuid,
          text: skill.name
        }))
      },
      fnError(err) {
        console.warn(err)
      }
    })
  }

  getRoles() {
    const self = this

    this.rolesService.getRoles({
      fnSuccess(roles) {
        self.roles = roles.data.map((role: any) => ({
          label: role.name,
          value: role.id
        }))
      },
      fnError(err) {
        console.warn(err)
      }
    })
  }

  cloneCollaborator() {
    let { role, skills, ...outherInfos } = this.collaborator

    if (role.length) {
      role = role[0].role_id
    }
    if (skills.length) {
      skills = skills.map((skill: any) => skill.skill_id_id)
    }

    this.collaboratorCloned = { ...outherInfos, role, skills }
  }

  setValueInForm() {
    const { user, skills, role } = this.collaborator

    const bodyUser = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      is_admin: user.is_admin,
      skills: skills.length ? skills.map((skill: any) => skill.skill_id_id) : [],
      role: role.length ? role[0].role_id : null,
      type_contract: user.type_contract
    }

    this.form?.setValue(bodyUser)
  }

  onSubmit() {
    const self = this
    const { role, skills, ...user } = this.form.value
    let data: any = {}

    if (this.collaboratorCloned.role !== role) {
      data.role = role
    }

    if (!this.helpers.arrayEquals(this.collaboratorCloned.skills, skills)) {
      data.skills = skills
    }

    data = { user, ...data }
    const uuid = this.collaborator?.user.uuid

    this.collaboratorService.updateCollaborator(data, uuid, {
      fnSuccess(data) {
        self.updateData.emit()
        self.toast.success("Colaborador atualizado com sucesso!")
      },
      fnError(err) {
        console.warn(err)
        self.toast.error("Error na atualização do Colaborador!")
      }
    })
  }
}
