import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from 'src/app/components/modals/modal-confirm/modal-confirm.component';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { ListHeader } from 'src/app/models/list-header';
import projectHeader from 'src/app/mocks/project-header';
import { ToastrService } from 'ngx-toastr';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { Project } from 'src/app/models/project';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { ModalCustomService } from 'src/app/services/modal/modal-service.service';
import { ControlSelectOptions } from 'src/app/models/control-select-options';
import { optionsStatus } from 'src/app/mocks/options-status';
import { projectDefault } from 'src/app/mocks/default-form';
import { Budget } from 'src/app/models/budget';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {
  @ViewChild('modalCustom', { static: false }) modalCustom?: TemplateRef<any>

  listFilters: Project[] = []
  projectsHeader: ListHeader[] = projectHeader
  projectSelected?: Project
  optionsStatus: ControlSelectOptions[] = optionsStatus
  projects: Project[] = []
  total: number = 0
  clients: ControlSelectOptions[] = []
  form: FormGroup | any
  searchTime: any
  params: any = {}
  page: number = 1
  loadingTable: boolean = false

  constructor(
    private modalService: NgbModal,
    private projectsService: ProjectsService,
    public helpersService: HelpersService,
    private toast: ToastrService,
    private clientsService: ClientsService,
    private modalCustomService: ModalCustomService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      clients: ['', Validators.required],
      is_activate: [true],
      budget: this.formBuilder.array([])
    })

    this.getProjects()
    this.getClients()
  }

  getClients() {
    const self = this
    const param = "?all"

    this.clientsService.getClientsForAdmin({
      fnSuccess(data) {
        self.clients = data.map((option: any) => ({
          value: option.uuid,
          label: option.name
        }))
      },
      fnError(err) {
        console.warn(err)
      }
    }, param)
  }

  getProjects(params: string = "") {
    const self = this
    this.loadingTable = true

    this.projectsService.getProjectsForAdmin({
      fnSuccess({ data, count }) {
        self.total = count
        self.projects = data
        self.listFilters = data
        self.loadingTable = false
      },
      fnError(err) {
        console.warn(err)
        self.loadingTable = false
      }
    }, params)
  }

  searchProjects(event: any) {
    clearTimeout(this.searchTime)
    this.searchTime = setTimeout(() => {
      const searchValue = event.target.value.trim()
      const param: string = this.setParam("search", searchValue)
      this.getProjects(param)
    }, 350)
  }

  changePagination(page: any) {
    this.page = page
    const param: string = this.setParam("page", page)
    this.getProjects(param)
  }

  fnParam(name?: string, value?: any): string {
    if (name) {
      if (name !== "page") {
        this.params["page"] = 1
        this.page = 1
      }

      this.params[name] = value
    }

    const params = Object.entries(this.params).map((param, index) => {
      const [query, value] = param
      const cancat = index === 0 ? "?" : "&"
      return `${cancat}${query}=${value}`
    }).join("")

    return params
  }

  getParams() {
    return this.fnParam()
  }

  setParam(name: string, value: any) {
    return this.fnParam(name, value)
  }

  updateProject(projectId: string) {
    const self = this
    const data = { ...this.form.value }

    this.projectsService.updateProject(data, projectId, {
      fnSuccess(data) {
        const params = self.getParams()
        self.getProjects(params)
        self.toast.success("Projeto atualizado com sucesso!")
        self.closeModal()
      },
      fnError(err) {
        console.warn(err)
        self.toast.error("Error na atualização do projeto!")
        self.closeModal()
      }
    })
  }

  createProject() {
    const self = this
    const data = { ...this.form.value }

    this.projectsService.addProject(data, {
      fnSuccess() {
        const params = self.getParams()
        self.getProjects(params)
        self.toast.success("Projeto criado com sucesso!")
        self.closeModal()
      },
      fnError(err) {
        console.warn(err)
        self.toast.error("Error na criação do projeto!")
        self.closeModal()
      }
    })
  }

  deleteProject(project: Project) {
    const self = this
    const modalRef = this.modalService.open(
      ModalConfirmComponent,
      { windowClass: "modal-confirm" }
    )

    modalRef.componentInstance.title = "Tem certeza que deseja excluir esse projeto?"
    modalRef.componentInstance.description = "Essa a ação não poderá ser desfeita."
    modalRef.componentInstance.btnTitle = "Excluir"
    modalRef.componentInstance.callbackConfirmation = () => {
      this.projectsService.deleteProject(project.uuid, {
        fnSuccess(data) {
          const params = self.getParams()
          self.getProjects(params)
          self.toast.success("Projeto deletado com sucesso!")
        },
        fnError(err) {
          console.warn(err)
          self.toast.error("Erro ao deletar projeto")
        }
      })
      modalRef.close()
    }
  }

  showModal(title: string) {
    const data = {
      content: this.modalCustom,
      title
    }

    this.modalCustomService.showModal(data)
    this.modalCustomService.cancelModal(() => {
      this.form?.reset(projectDefault)
      this.budgets().clear()
    })
  }

  closeModal() {
    this.modalCustomService.closeModal()
  }

  save(project?: Project) {
    this.projectSelected = project
    let titleModal = "Adicionar novo projeto"

    if (project) {
      this.form?.setValue({
        name: project.name,
        clients: project.clients.uuid,
        is_activate: project.is_activate,
        budget: []
      })

      if (project.budget) {
        this.setBudget(project.budget)
      }

      titleModal = `Editar ${project.name}`
    }

    this.showModal(titleModal)
  }

  budgets(): FormArray {
    return this.form.get('budget') as FormArray;
  }

  setBudget(budgets: Budget[]) {
    budgets.forEach((budget: Budget) => {
      const newBudget = this.formBuilder.group({
        skill: [budget.skill, Validators.required],
        total_hours: [budget.total_hours, Validators.required]
      });

      this.budgets().push(newBudget);
    })
  }

  onSubmit() {
    if (!this.projectSelected) this.createProject()
    else this.updateProject(this.projectSelected.uuid)
  }
}
