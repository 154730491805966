import { Component, NgZone } from '@angular/core';
import { Store } from '@ngrx/store'
import { accounts } from 'google-one-tap';
import { AppState } from 'src/app/state/app-state';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../services/auth/auth.service'
import { ToastrService } from 'ngx-toastr'
import { HiddenLoading, ShowLoading } from 'src/app/state/loading/loading.action';

declare let google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  constructor(
    private store: Store<AppState>,
    private authService: AuthService,
    private toast: ToastrService,
		private _ngZone: NgZone
  ) { }

  ngOnInit(): void {
    // @ts-ignore
    window.onGoogleLibraryLoad = () => {
      const gAccounts: accounts = google.accounts;

      this.initializeGoogleSignin(gAccounts)
      this.customGoogleBtn(gAccounts)
    };
  }

  initializeGoogleSignin(gAccounts: accounts) {
    // @ts-ignore
    gAccounts.id.initialize({
      client_id: environment.googleClientId,
      ux_mode: 'popup',
      auto_select: true,
      cancel_on_tap_outside: false,
      callback: ({ credential }) => {
        this.onSubmit(credential)
      },
    })
  }

  customGoogleBtn(gAccounts: accounts) {
    // @ts-ignore
    gAccounts.id.renderButton(document.getElementById('googleBtn') as HTMLElement, {
      size: 'large',
      width: 320
    });
  }

  onSubmit(jwtToken: string): void {
    const self = this

		this._ngZone.run(() => {
      self.store.dispatch(ShowLoading())

      if (jwtToken.length) {
        const payload = { token: jwtToken }

        this.authService.login({
          payload,
          fnSuccess() {
            self.authService.redirectAuth()
          },
          fnError(responseError: any) {
            if (
              responseError.status >= 400 &&
              responseError.status < 500
            ) {
              self.toast.error('Conta invalida para login', 'Erro!')
            } else {
              self.toast.error(
                `Status: ${responseError.status}`,
                'Server Error!'
              )
            }
            console.warn(responseError)
          },
          fnFinalized() {
            self.store.dispatch(HiddenLoading())
          },
        })
      }
    })
  }
}
