import {
	Component,
	OnInit,
	Input,
	ViewChild,
	Output,
	EventEmitter,
} from '@angular/core'
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap'
import tabsTimesheet from 'src/app/mocks/tabs-timesheet'
import { User } from 'src/app/models/user'

import { TimesheetControlComponent } from '../../timesheet-tabs/timesheet-control/timesheet-control.component'
import { WorksheetComponent } from '../../timesheet-tabs/worksheet/worksheet.component'

@Component({
	selector: 'app-modal-timesheet',
	templateUrl: './modal-timesheet.component.html',
	styleUrls: ['./modal-timesheet.component.scss'],
})
export class ModalTimesheetComponent implements OnInit {
	@ViewChild(TimesheetControlComponent) timesheetComponent: any
	@ViewChild(WorksheetComponent) worksheetComponent: any
	@Input() callbackConfirmation?: Function
	@Output() emmitEvent: EventEmitter<any> = new EventEmitter()

	@Input() user?: User
	@Input() timesheets: any
	@Input() date: string = ''
	@Input() weekday: string = ''
	@Input() typeReasonDay: string = ''

	tabs = tabsTimesheet
	currentTab: string = 'Planilha de horas'

	_reload = true

	constructor(public activeModal: NgbActiveModal) {}

	ngOnInit(): void {
		if (this.user?.typeContract === 'PJ') {
			this.currentTab = 'Planilha de horas'
		}
	}

	ngAfterContentInit() {
		this.closeModalByJs()
	}

	setTimeSheat(data: any) {
		this.timesheets = data
		this._reload = false
		setTimeout(() => {
			this._reload = true
		})
		this.emmitEvent.emit()
	}

	closeModalByJs() {
		const modalFullScreen = document.querySelector('.modal-timesheet')
		const modalContent = document.querySelector('.modal-content')

		modalFullScreen?.addEventListener('click', () => {
			const timer = setTimeout(() => {
				this.close()
				clearTimeout(timer)
			}, 400)
		})

		modalContent?.addEventListener('click', (e) => {
			e.stopPropagation()
		})
	}

	setTab(tab: string) {
		const setNewTab = () => {
			this.currentTab = tab
		}
		if (this.callbackConfirmation) this.callbackConfirmation()
		if (this.currentTab === 'Planilha de horas') {
			this.timesheetComponent.close(setNewTab)
		} else if (this.currentTab === 'Folha de ponto') {
			this.worksheetComponent.close(setNewTab)
		}
	}

	callbackAddAndRemove() {
		if (this.callbackConfirmation) this.callbackConfirmation()
	}

	close() {
		const closeModal = () => this.activeModal.close()

		if (this.currentTab === 'Planilha de horas') {
			this.timesheetComponent.close(closeModal)
		} else if (this.currentTab === 'Folha de ponto') {
			this.worksheetComponent.close(closeModal)
		}
	}
}
