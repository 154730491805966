import { ListHeader } from "../models/list-header"

const collaboratorsHeader: ListHeader[] = [
  {
    value: 'name',
    label: 'NOME DO COLABORADOR'
  },
  {
    value: '',
    label: 'E-MAIL DE CONTATO'
  },
  {
    value: '',
    label: 'status',
    align: '',
    className: 'text-center'
  },
  {
    value: 'hire_date',
    label: 'Tipo de contratação',
    align: '',
    className: 'text-center'
  },
  {
    value: '',
    label: 'Total de horas',
    align: '',
    className: 'text-center'
  },
]

const collaboratorsHeaderInternal: ListHeader[] = [
  {
    value: 'status',
    label: 'status',
  },
  {
    value: 'name',
    label: 'NOME DO PROJETO',
  },
  {
    value: '',
    label: '',
  }
]

export {
  collaboratorsHeader,
  collaboratorsHeaderInternal
}
