import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormBuilder } from '../../models/form-builder'
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { ToastrService } from 'ngx-toastr'

@Component({
    selector: 'app-form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements OnInit {

    forms: FormBuilder[] = []
    @Input() formJson: any
    @Output() output: EventEmitter<FormGroup> = new EventEmitter()
    @Output() onSubmitForm: EventEmitter<FormGroup> = new EventEmitter()
    fg: FormGroup = new FormGroup({})

    constructor(private toast: ToastrService) {
    }

    ngOnInit(): void {
        this.createForm()
    }

    setConditionProp(value: string, prop: any, fg: any) {
        prop.currentValue = value
        let conditionalIsValid = false
        if (prop.conditional) {
            const listValues = prop.conditional.value.split(',')
            conditionalIsValid = listValues.includes(prop.currentValue)
        }

        if (conditionalIsValid && prop.conditional.questions.length) {
            prop.conditional.questions.forEach((subProp: any) => {
                fg.addControl(subProp.id, new FormControl(subProp.value || '', this.mapValidators(subProp.validation)))
            })
        } else {
            if (prop.conditional && prop.conditional.questions.length) {
                prop.conditional.questions.forEach((subProp: any) => {
                    fg.removeControl(subProp.id)
                })
            }
        }

        prop.conditionalIsValid = conditionalIsValid

    }

    createForm() {
        if (this.formJson == null) return
        let objectProps = this.formJson
        const formGroup: any = {}
        for (let prop of objectProps) {
            if (prop.type === 'checkbox') {
                formGroup[prop.id] = new FormArray([], this.mapValidators(prop.validation))
            } else {
                formGroup[prop.id] = new FormControl(prop.value || '', this.mapValidators(prop.validation))
            }
        }

        this.fg = new FormGroup(formGroup)

        for (let prop of objectProps) {
            if (prop.conditionalIsValid) {
                prop.conditional.questions.forEach((subProp: any) => {
                    this.fg.addControl(subProp.id, new FormControl(subProp.value || '', this.mapValidators(subProp.validation)))
                })
            }
        }

        const form: FormBuilder = {
            id: new Date().getUTCMilliseconds().toString(),
            formGroup: this.fg,
            metaData: objectProps,
            transactionalData: []
        }
        this.fg.valueChanges.subscribe(values => {
            this.output.emit(this.fg)
        })
        this.forms.push(form)
        return form
    }

    private mapValidators(validators: any) {
        const formValidators = []

        if (validators) {
            for (const validation of Object.keys(validators)) {
                if (validation === 'required') {
                    formValidators.push(Validators.required)
                } else if (validation === 'minLength') {
                    formValidators.push(Validators.minLength(validators[validation]))
                } else if (validation === 'maxLength') {
                    formValidators.push(Validators.maxLength(validators[validation]))
                } else if (validation === 'isValidCpf') {
                    formValidators.push(GenericValidatorService.isValidCpf)
                }
            }
        }

        return formValidators
    }

    onSubmit(form: FormGroup) {
        if (!form.invalid) {
            this.onSubmitForm.emit(form.value)
        } else {
            this.toast.error('Formulário inválido. Verifique os valores e tente novamente.', 'OPS!!!')
        }
    }

}
