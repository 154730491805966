<nav class="header shadow-sm">
    <div class="container">
        <div class="header-left">
            <img 
                src="/assets/images/temp/catskillet.svg" 
                alt="catskillet" 
                title="catskillet" 
                width="100px"
            >
            <p class="fw-bold m-0 current-date">
                {{ currentDateTime }}
            </p>
        </div>
    
        <div class="header-right d-flex align-items-center">
            <a routerLink="/admin" class="me-4" *ngIf="isAdmin">
                <img src="/assets/images/temp/overview.svg" alt="icon-admin" title="ir para o admin">
            </a>

            <button (click)="logout()" class="btn-logout">
                <img src="/assets/images/temp/icon-logout.svg" alt="sair" title="sair">
            </button>
        </div>
    </div>
</nav>
