<div class="control">
	<div class="form-group form-wrapper">
		<div
			class="position-relative"
			[class.default]="!labelName"
			[formGroup]="form"
		>
			<label class="form-label" [for]="'i-' + id">{{ labelName }}</label>
			<input
				#element
				[id]="'i-' + id"
				[type]="!showPass ? typeInput : 'text'"
				[attr.name]="name"
				[formControlName]="name"
				[placeholder]="placeholder || ''"
				[setValue]="inputModel"
				[mask]="inputMask"
				[class.disabled]="disabled"
				(change)="onChange($event, element.value)"
				maxlength="50"
				type="text"
				class="form-control"
				placeholder="name@example.com"
				[readonly]="readonly"
			/>
			<button
				*ngIf="typeInput == 'password'"
				(click)="toggleInputPass()"
				type="button"
				class="show-pass"
				[class.active]="showPass"
				data-input="name"
			>
				<figure>
					<img
						src="/assets/images/eye.svg"
						alt="Arrow Down"
						title="Arrow Down"
					/>
				</figure>
			</button>
		</div>

		<div *ngIf="callout" class="form-text">{{ callout }}</div>
		<span class="control-error-group">
			<app-control-error
				[form]="form"
				[name]="name"
				[labelName]="labelName"
			></app-control-error>
		</span>
	</div>
</div>
