import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { WorksheetService } from 'src/app/services/worksheet/worksheet.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalConfirmComponent } from '../../modals/modal-confirm/modal-confirm.component';

@Component({
  selector: 'app-worksheet',
  templateUrl: './worksheet.component.html',
  styleUrls: ['./worksheet.component.scss']
})
export class WorksheetComponent implements OnInit {
  @Input() date: string = ""
  form: FormGroup | any
  worksheet: any

  constructor(
    private formBuilder: FormBuilder,
    private worksheetService: WorksheetService,
    private helpers: HelpersService,
    private modalService: NgbModal,
    private toast: ToastrService,
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      startDay: ['', Validators.required],
      outLunch: ['', [this.customValidator("startDay")]],
      returnLunch: ['', [this.customValidator("outLunch")]],
      endDay: ['', [this.customValidator("returnLunch")]],
      sign: [false, Validators.required]
    })

    this.getWorksheets()
  }

  customValidator(prop: any) {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;

      if (value && this.worksheet) {
        const item = this.worksheet[prop]

        let [hours, minutes]: any = value.split(":")
        let [hoursBased, minutesBased]: any = item.split(":")

        hours = Number(hours)
        minutes = Number(minutes)

        hoursBased = Number(hoursBased)
        minutesBased = Number(minutesBased)

        if (hours < hoursBased) {
          return {
            min: "Este valor deve ser maior ou igual ao valor anterior.",
          };
        } else if (hours === hoursBased && minutes < minutesBased) {
          return {
            min: "Este valor deve ser maior ou igual ao valor anterior.",
          };
        }
      }

      return null;
    }
  }

  changeWorksheet(name: string, value: any) {
    if (this.worksheet) {
      this.worksheet[name] = value
    } else {
      this.worksheet = {}
      this.worksheet[name] = value
    }
  }

  getWorksheets() {
    const self = this
    this.worksheetService.getWorksheetPerDay(this.date, {
      fnSuccess(data) {
        if (data) {
          self.form.setValue({
            startDay: data.start_day,
            outLunch: data.out_lunch,
            returnLunch: data.return_lunch,
            endDay: data.end_day,
            sign: data.sign
          })

          self.worksheet = self.helpers.converterSnakeToCamelCase(data)
        }
      }, fnError(err) {
        console.warn(err)
      }
    })
  }

  onSubmit() {
    const self = this

    if (this.form.value.sign && !this.form.invalid) {
      if (this.worksheet && this.worksheet.uuid) {
        const obj: any = {}

        Object.entries(this.form.value).forEach((item: any) => {
          const [key, value] = [item[0], item[1]]
          if (value) obj[key] = value
        })

        const body = {
          ...this.helpers.converterCamelCaseToSnake(obj),
          date: this.date
        }

        this.worksheetService.updateWorksheet(this.worksheet.uuid, body, {
          fnSuccess(data) {
            self.toast.success("Folha de ponto atualizada com sucesso!")
            self.getWorksheets()
          }, fnError(err) {
            console.warn("err ", err)
          }
        })
      } else {
        const body = {
          ...this.helpers.converterCamelCaseToSnake(this.form.value),
          date: this.date
        }

        this.worksheetService.addWorksheet(body, {
          fnSuccess(data) {
            self.toast.success("Folha de ponto atualizada com sucesso!")
            self.getWorksheets()
          }, fnError(err) {
            console.warn("err", err)
          }
        })
      }
    }
  }

  close(fnCloseModal: Function) {
    if (this.worksheet && this.worksheet.uuid) {
      const status = this.worksheet.startDay && this.worksheet.returnLunch && this.worksheet.outLunch && this.worksheet.endDay

      if (status) {
        fnCloseModal()
      } else {
        const modalRef = this.modalService.open(
          ModalConfirmComponent,
          { container: "#modal-timesheet", windowClass: "modal-confirm" }
        )

        modalRef.componentInstance.title = "Antes de sair, por favor, revise sua folha de ponto e preencha todas as informações necessárias para garantir que esteja corretamente registrada"
        modalRef.componentInstance.callbackConfirmation = () => {
          fnCloseModal()
          modalRef.close()
        }
      }
    } else {
      fnCloseModal()
    }
  }
}
