import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  @Input() callbackConfirmation?: Function
  @Input() title: string = ""
  @Input() description?: string = ""
  @Input() btnTitle?: string = "Confirmar"

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void { }

  close() {
    this.activeModal.close()
  }

  confirmation() {
    if (this.callbackConfirmation) this.callbackConfirmation()
  }
}
