<div class='control select'>
    <div class='form-group form-wrapper'>
        <div [class.default]='!labelName' [formGroup]='form'>
            <label [for]='id || name' *ngIf='labelName' class='form-label'>
                {{ labelName }}
            </label>
            <select
                #element
                [id]='id || name'
                [attr.name]='name'
                [formControlName]='name'
                [setValue]='inputModel'
                (change)='onChange(element.value)'
                [class.disabled]="disabled"
                class='form-select'
                aria-label='Select example'
            >
                <option value="" selected hidden>{{ placeholder }}</option>
                <option *ngFor='let opt of options' [value]='opt.value'>
                    {{ opt.label }}
                </option>
            </select>
        </div>

        <span class="control-error-group">
            <app-control-error
                [form]='form'
                [name]='name'
                [labelName]='labelName'
            ></app-control-error>
        </span>
    </div>
</div>
