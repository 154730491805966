import { Component, OnInit, Input } from '@angular/core';
import { SkillsService } from 'src/app/services/skills/skils.service';
import { GroupSkills } from 'src/app/models/skills';
import { Select2OptionData } from 'ng-select2';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { Options } from 'select2';

@Component({
  selector: 'app-budget-hours',
  templateUrl: './budget-hours.component.html',
  styleUrls: ['./budget-hours.component.scss']
})
export class BudgetHoursComponent implements OnInit {
  @Input() form: FormGroup = new FormGroup(Input())
  groupSkills: Array<Select2OptionData> = []
  loading: any = {
    user: false,
    skills: false,
    select: false
  }

  public options: Options;

  constructor(
    private skillsService: SkillsService,
    private formBuilder: FormBuilder
  ) {
    this.options = {
      multiple: true,
      width: '300'
    };
  }

  ngOnInit(): void {
    this.getGroupsSkills()
  }

  getGroupsSkills() {
    const self = this
    self.loading.skills = false
    self.loading.select = true

    this.skillsService.getGroupSkills({
      fnSuccess({ data }) {
        if (data.length) {
          const listGroup: Array<Select2OptionData> = []

          data.forEach((item: GroupSkills) => {
            listGroup.push({
              text: item.group,
              id: item.group,
              disabled: false
            })
          })

          self.groupSkills = listGroup
          self.loading.skills = false
          self.loading.select = false
          self.disableGroupsExists()
        }
      }, fnError(err) {
        self.loading.select = false
        console.warn("err", err)
      }
    })
  }

  budgets(): FormArray {
    return this.form.get('budget') as FormArray;
  }

  disableGroupsExists() {
    const budgetSelecteds = this.budgets().value.map(({ skill }: any) => skill)

    this.showLoadingSelect(() => {
      this.groupSkills = this.groupSkills.map((item) => {
        let disabled = false

        if (budgetSelecteds.includes(item.text)) {
          disabled = true
        }

        return { ...item, disabled }
      })
    })
  }

  showLoadingSelect(callback?: Function) {
    this.loading.select = true
    if (callback) callback()
    setTimeout(() => {
      this.loading.select = false
    }, 0)
  }

  addBudget() {
    const budget = this.formBuilder.group({
      skill: ['', Validators.required],
      total_hours: ['', Validators.required]
    });

    this.budgets().push(budget);
  }

  removeBudget(index: number) {
    this.budgets().removeAt(index);
    this.disableGroupsExists()
  }
}
