<div class="main-team-workers">
    <h4 class="title"> Clientes </h4>

    <div class="row">
        <div class="col-4">
            <ng-select2 
                *ngIf="clients.length"
                placeholder="Clientes"
                id="clients"
                [data]="clients" 
                [(ngModel)]="client"
                (ngModelChange)="getDashboardByClient($event)"
            ></ng-select2>
        </div>
    </div>

    <div class="main-content">
        <div class="wrapper-chart" *ngIf="!loading">
            <div *ngIf="client && dahsboardClient && dahsboardClient.labels.length">
                <div class="summary">
                    <h4>
                        Horas contratadas: <strong>{{ totalHours.total || 0 }}h </strong>
                    </h4>
                    <h4>
                        Horas utilizadas: <strong>{{ totalHours.expediente || 0 }}h </strong>
                    </h4>
                    <h4>
                        Horas extras: <strong>{{ totalHours.overtime || 0 }}h </strong>
                    </h4>
                </div>
                <app-chart [data]="dahsboardClient"></app-chart>
            </div>

            <h3 *ngIf="!client" class="text-center"> Selecione um cliente para a busca de informações dos projetos! </h3>
            <h3 *ngIf="client && notProjects" class="text-center">
                Cliente sem projetos cadastrados!
            </h3>
        </div>

        <div class="loading">
            <app-loading-local [loading]="loading"></app-loading-local>
        </div>
    </div>
</div>
