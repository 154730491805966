<ng-container [ngSwitch]='prop.type'>
    <ng-container *ngSwitchCase="'text'">
        <app-input
            [id]='name'
            [typeInput]='prop.type'
            [name]='name'
            [labelName]='prop.label'
            [placeholder]='prop.placeholder'
            [form]='form'
            [inputMask]='prop.mask || null'
            [inputModel]='prop.value'
        ></app-input>
    </ng-container>
    <ng-container *ngSwitchCase="'tel'">
        <app-input
            [id]='name'
            [typeInput]='prop.type'
            [name]='name'
            [labelName]='prop.label'
            [placeholder]='prop.placeholder'
            [form]='form'
            [inputMask]='prop.mask || null'
            [inputModel]='prop.value'
        ></app-input>
    </ng-container>
    <ng-container *ngSwitchCase="'email'">
        <app-input
            [id]='name'
            [typeInput]='prop.type'
            [name]='name'
            [labelName]='prop.label'
            [placeholder]='prop.placeholder'
            [form]='form'
            [inputMask]='prop.mask || null'
            [inputModel]='prop.value'
        ></app-input>
    </ng-container>
    <ng-container *ngSwitchCase="'number'">
        <app-input
            [id]='name'
            [typeInput]='prop.type'
            [name]='name'
            [labelName]='prop.label'
            [placeholder]='prop.placeholder'
            [form]='form'
            [inputMask]='prop.mask || null'
            [inputModel]='prop.value'
        ></app-input>
    </ng-container>
    <ng-container *ngSwitchCase="'url'">
        <app-input
            [id]='name'
            [typeInput]='prop.type'
            [name]='name'
            [labelName]='prop.label'
            [placeholder]='prop.placeholder'
            [form]='form'
            [inputMask]='prop.mask || null'
            [inputModel]='prop.value'
        ></app-input>
    </ng-container>
    <ng-container *ngSwitchCase="'select'">
<!--        <label [attr.for]='prop'>{{prop.label}}</label>-->
        <app-select
            [id]='name'
            [name]='name'
            [options]='prop.options'
            [labelName]='prop.label'
            [placeholder]='prop.placeholder'
            [form]='form'
            [inputModel]='prop.value'
            (output)='onChange($event)'
        ></app-select>
    </ng-container>
    <ng-container *ngSwitchCase="'radio'">
        <label [attr.for]='prop'><h5>{{prop.label}}</h5></label>
        <div *ngFor='let option of prop.options; let i = index'>
            <label>
                <app-radios
                    [form]='form'
                    [id]='name + "-" + i'
                    [name]='name'
                    [inputModel]='option.value'
                    (output)='onChange($event)'
                >
                    {{option.value}}
                </app-radios>
                <app-control-error [form]='form' [name]='name' [labelName]='prop.label'></app-control-error>
            </label>
        </div>
    </ng-container>
    <ng-container *ngSwitchCase="'checkbox'">
        <label [attr.for]='prop'><h5>{{prop.label}}</h5></label>
        <div *ngFor='let option of prop.options; let i = index'>
            <label>
                <app-checkbox-form-builder
                    [form]='form'
                    [id]='name + "-" + i'
                    [name]='name'
                    [value]='option.value'
                    [changeModel]='onCheckChange'
                >
                    {{option.value}}
                </app-checkbox-form-builder>
                <app-control-error [form]='form' [name]='name' [labelName]='prop.label'></app-control-error>
            </label>
        </div>
    </ng-container>
</ng-container>
