<div class="main-modal-lg" id="main-modal-lg" *ngIf="!loading">
	<button class="btn-close" (click)="closeModal()"></button>

	<div class="header-modal">
		<div class="d-flex gap-5">
			<h3>{{ collaborator.first_name }} {{ collaborator.last_name }}</h3>

			<span
				[class]="[
					'badge',
					collaborator.type_contract.toLowerCase() === 'clt'
						? 'bg-success'
						: 'bg-primary'
				]"
			>
				{{ collaborator.type_contract }}
			</span>
		</div>
		<h2>
			Total de horas mensal: <span>{{ hours.normal }}</span
			>h
		</h2>
		<h3 class="overtime">
			Horas extras: <span>{{ hours.overtime }}</span
			>h
		</h3>
	</div>

	<div class="row mt-5">
		<div
			[class]="timesheetsSelecteds ? 'col-lg-8' : 'col-lg-12'"
			class="wrapper-calendar"
		>
			<app-calendar
				*ngIf="collaborator"
				(setContentDaySelected)="setContentDaySelected($event)"
				[collaboratorAdmin]="collaborator"
				[hours]="hours"
			></app-calendar>
		</div>

		<div class="col-lg-4" *ngIf="effectTimeControl">
			<div class="main-timesheets" *ngIf="timesheetsSelecteds">
				<h3>{{ dateSelected }}</h3>

				<div
					class="wrapper-tabs"
					*ngIf="collaborator.type_contract !== 'PJ'"
				>
					<div class="tabs" *ngFor="let tab of tabs">
						<button
							[class.current-tab]="tab.label === currentTab"
							(click)="setTab(tab.label)"
						>
							{{ tab.label }}
						</button>
					</div>
				</div>

				<div *ngIf="currentTab === 'Planilha de horas'">
					<div *ngIf="timesheetsSelecteds.length; else notTimesheets">
						<div
							*ngFor="
								let timesheet of timesheetsSelecteds;
								let index = index
							"
							class="timesheet"
							[class.light]="index % 2 === 0"
						>
							<div class="wrapper-content">
								<h3>
									{{ timesheet.extendedProps.project.name }}
								</h3>
								<div class="d-flex justify-content-between">
									<span>
										horas:
										{{ timesheet.extendedProps.hours }}h
									</span>
									<strong
										class="badge-status danger"
										*ngIf="timesheet.extendedProps.overtime"
									>
										Extra
									</strong>
								</div>
							</div>
							<div>
								<a
									*ngIf="timesheet.extendedProps.absence_file"
									class="btn-delete"
									[href]="
										timesheet.extendedProps.absence_file
									"
									target="_blank"
									><i class="bi bi-download"></i
								></a>
								<button
									class="btn-delete"
									(click)="
										onDelete(timesheet.extendedProps.uuid)
									"
								>
									<i class="bi bi-trash"></i>
								</button>
							</div>
						</div>
					</div>

					<ng-template #notTimesheets>
						<h4 class="text-center mt-3">
							Sem horas disponíveis neste dia!
						</h4>
					</ng-template>
				</div>

				<div *ngIf="currentTab === 'Folha de ponto'">
					<div
						*ngIf="worksheetSelected; else notWorksheets"
						class="main-worksheet-admin"
					>
						<div class="wrapper-worksheet-details">
							<div class="worksheet-details-item">
								<span> Início do expediente </span>:
								<strong>
									{{
										worksheetSelected.start_day || '--'
									}}</strong
								>
							</div>
							<div class="worksheet-details-item">
								<span> Início do almoço </span>:
								<strong>
									{{
										worksheetSelected.out_lunch || '--'
									}}</strong
								>
							</div>
							<div class="worksheet-details-item">
								<span> Retorno do almoço </span>:
								<strong>
									{{
										worksheetSelected.return_lunch || '--'
									}}</strong
								>
							</div>
							<div class="worksheet-details-item">
								<span> Fim do expediente </span>:
								<strong>
									{{
										worksheetSelected.end_day || '--'
									}}</strong
								>
							</div>
						</div>

						<div class="wrapper-worksheet-total">
							<div class="worksheet-total-item">
								<span>total</span>:
								<strong> {{ worksheetSelected.total }} </strong>
							</div>
							<div
								class="worksheet-total-item"
								*ngIf="worksheetSelected.overtime"
							>
								<span>Hora extra</span>:
								<strong
									>{{ worksheetSelected.overtime }}
								</strong>
							</div>
						</div>

						<button
							class="btn-delete"
							(click)="onDeleteWorksheet(worksheetSelected.uuid)"
						>
							<i class="bi bi-trash"></i>
						</button>
					</div>

					<ng-template #notWorksheets>
						<h4 class="text-center mt-3">
							Sem folhas de ponto disponíveis neste dia!
						</h4>
					</ng-template>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="content-loading" *ngIf="loading">
	<app-loading-local [loading]="loading"></app-loading-local>
</div>
