<div class="timesheet-control">
	<div class="wrapper-timesheet-content row m-0">
		<div class="col-lg-5 box-content p-0">
			<div>
				<div class="wrapper-show-hours">
					<p>
						Total de <br />
						horas diárias:
					</p>
					<h2>{{ totalHours }}h</h2>
				</div>

				<h4 class="resume-hours">Resumo de horas:</h4>

				<strong *ngIf="!listTimeSheets.length" class="list-empty">
					Adicione o projeto em que <br />
					está trabalhando aqui
				</strong>

				<ul class="wrapper-links" *ngIf="listTimeSheets.length">
					<li
						*ngFor="
							let timeSaved of listTimeSheets;
							let idx = index
						"
						class="link-timesheet"
						[class.actived]="timeSaved.uuid === action.id"
						[class.light]="idx % 2 === 0"
					>
						<span
							*ngIf="timeSaved.name"
							class="project-name"
							[class.overtime]="timeSaved.overtime"
						>
							<strong>{{ timeSaved.hours }}h</strong> -
							{{ timeSaved.name }}
						</span>
						<p
							class="view-details cursor-pointer"
							*ngIf="timeSaved.name"
							(click)="setCurrentTimesheet(timeSaved)"
						>
							<i class="bi bi-pencil"></i> Editar
						</p>
					</li>
				</ul>
			</div>
		</div>

		<div class="timesheet-list col-lg-7 box-content">
			<app-loading-local
				class="loading-box"
				[loading]="loading"
			></app-loading-local>

			<div *ngIf="!loading">
				<div class="timesheet-list-content">
					<div
						[hidden]="timesheet.attestation_document"
						class="group-wrapper-select row"
					>
						<div
							class="wrapper-select"
							[class]="
								isSkillFirstProject ? 'col-lg-8' : 'col-lg-4'
							"
						>
							<h3>Projeto</h3>
							<div
								class="timesheet-wrapper-project"
								[class.disabled]="isStudy || isAbsent"
							>
								<ng-select2
									[data]="listProjects"
									*ngIf="!timesheet.loading_label && _force"
									[ngModel]="form.value?.project_id"
									(valueChanged)="changeProject($event)"
									id="project"
									class="timesheet-select"
									placeholder="Projetos"
								></ng-select2>
								<app-loading-local
									class="loading-label"
									[loading]="timesheet.loading_label"
								></app-loading-local>
							</div>
						</div>

						<div
							class="wrapper-roles col-lg-4"
							[hidden]="isSkillFirstProject"
						>
							<h3>Habilidade</h3>
							<div
								class="timesheet-wrapper-project"
								[class.disabled]="
									!form.value?.project_id || isAbsent
								"
							>
								<ng-select2
									[data]="listFunctions"
									[ngModel]="form.value?.function"
									(valueChanged)="changeSkill($event)"
									id="function"
									class="timesheet-select"
									placeholder="Habilidades"
								></ng-select2>
								<app-loading-local
									class="loading-label"
									[loading]="loadingFunction"
								></app-loading-local>
							</div>
						</div>
						<div class="wrapper-roles col-lg-4">
							<h3>Horas</h3>
							<div class="wrapper-select">
								<select
									id="hours"
									class="form-control select-custom-nav"
									name="Score"
									[ngModel]="form.value?.hours"
									[disabled]="isDayOff"
									(change)="setValueTime($event)"
								>
									<option
										*ngFor="let score of optionsTimeSelect"
										[value]="score.value"
									>
										{{ score.label }}
									</option>
								</select>
								<span class="arrow" role="presentation"
									><b role="presentation"></b
								></span>
							</div>
						</div>
					</div>
				</div>
				<div class="wrapper-radius">
					<label
						for="normal"
						[class.inactive]="limitExceededNormalHour"
					>
						<span class="wrapper-radio">
							<input
								type="radio"
								name="absence_reason"
								id="normal"
								class="input-radio-black"
								value="normal"
								[disabled]="limitExceededNormalHour"
								[checked]="
									normalTimeControl &&
									!limitExceededNormalHour
								"
								(change)="setTypeDayWorkerNormal($event)"
							/>
						</span>
						<span class="text">Expediente normal </span>
					</label>

					<label for="overtime">
						<span class="wrapper-radio">
							<input
								type="radio"
								id="overtime"
								name="absence_reason"
								[checked]="
									form.value?.overtime ||
									limitExceededNormalHour
								"
								(change)="setTypeDayWorkerOvertime($event)"
							/>
						</span>
						<span class="text">
							Hora extra
							<!--                            - -->
							<!--                            {{ form.value?.overtime }} - -->
							<!--							{{ limitExceededNormalHour }}-->
						</span>
					</label>

					<label
						for="study"
						[class.inactive]="limitExceededNormalHour"
					>
						<span class="wrapper-radio">
							<input
								type="radio"
								name="absence_reason"
								value="study"
								id="study"
								class="input-radio-black"
								[checked]="isStudy"
								[disabled]="limitExceededNormalHour"
								(change)="setTypeDayWorkerStudy($event)"
							/>
						</span>
						<span class="text">Estudo</span>
					</label>

					<label
						for="absent"
						[class.inactive]="limitExceededNormalHour"
					>
						<span class="wrapper-radio">
							<input
								type="radio"
								name="absence_reason"
								value="ausente"
								id="absent"
								class="input-radio-black"
								[disabled]="limitExceededNormalHour"
								[checked]="isAbsent"
								(change)="setTypeDayWorkerAbsent($event)"
							/>
						</span>
						<span class="text">Ausente?</span>
					</label>
				</div>

				<div [hidden]="!isAbsent">
					<h3 class="pb-3">Motivo da ausência</h3>
					<div class="content-file">
						<div class="wrapper-select">
							<select
								id="absence"
								class="form-control select-custom-nav"
								name="absence"
								[ngModel]="form.value?.absence_reason"
								(change)="ChangeValueAbsenceReason($event)"
							>
								<option [ngValue]="null" [disabled]="true">
									Selecione
								</option>
								<option
									*ngFor="let score of absenceReason"
									[value]="score.value"
								>
									{{ score.label }}
								</option>
							</select>
							<span class="arrow" role="presentation"
								><b role="presentation"></b
							></span>
						</div>
						<div class="">
							<div
								class="wrapper-file"
								[class.active]="this.form.value?.absence_file"
							>
								<label for="document" class="form-label">
									<!--                                    Selecione um arquivo PDF-->
								</label>
								<input
									*ngIf="_force"
									id="document"
									(change)="fileChangeEvent($event)"
									name="absence_file"
									accept="application/pdf"
									class="form-control w-100 file"
									type="file"
								/>
								<label for="document" class="form-label-stamp"
									>Selecione o arquivo</label
								>
								<button
									class="btn-remove-file"
									*ngIf="fileNamePdf"
									(click)="removeFile()"
								>
									<i class="bi bi-trash"></i>
								</button>
								<a
									[href]="form.value?.absence_file"
									*ngIf="
										form.value?.absence_file &&
										!hasFile &&
										!edit
									"
									target="_blank"
								>
									<i class="bi bi-download"></i>
								</a>
							</div>
						</div>
					</div>
				</div>

				<div class="buttons-actions" [class.delete]="action.id">
					<button
						*ngIf="action.id"
						class="btn-default btn-cancel"
						(click)="resetForm()"
					>
						Cancelar
					</button>
					<div>
						<button
							*ngIf="action.id"
							class="btn-default btn-delete"
							(click)="saveAction(true)"
						>
							Excluir
						</button>
						<button
							type="button"
							[class.disabled]="disabledSubmit()"
							class="ms-3 btn-default"
							(click)="saveAction()"
						>
							Salvar
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
