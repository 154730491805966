import { Component, OnInit } from '@angular/core'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { Store } from '@ngrx/store'
import { Observable } from 'rxjs'
import { User } from 'src/app/models/user'
import { AppState } from 'src/app/state/app-state'
import { ModalOnboardingWorksheetComponent } from 'src/app/components/modals/modal-onboarding-worksheet/modal-onboarding-worksheet.component'

@Component({
	selector: 'app-timesheet',
	templateUrl: './timesheet.component.html',
	styleUrls: ['./timesheet.component.scss'],
})
export class TimesheetComponent implements OnInit {
	private user$?: Observable<User>
	user?: User

	constructor(
		private modalService: NgbModal,
		private store: Store<AppState>
	) {}

	ngOnInit(): void {
		this.user$ = this.store.select('user')
		this.user$.subscribe((state) => {
			this.user = state
		})

		if (
			this.user?.typeContract &&
			this.user?.typeContract.toUpperCase() === 'CLT'
		) {
			const alreadyLoggedIn = localStorage.getItem('alreadyLoggedIn')

			if (!alreadyLoggedIn) {
				this.modalService.open(ModalOnboardingWorksheetComponent, {
					keyboard: false,
					backdrop: 'static',
					windowClass: 'modal-onboarding-worksheet',
				})
			}
		}
	}
}
