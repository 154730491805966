import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks'
import { environment } from 'src/environments/environment'
import { finalize } from 'rxjs/operators'
import { Timesheet } from 'src/app/models/timesheet'

@Injectable({
	providedIn: 'root',
})
export class TimesheetService {
	constructor(private http: HttpClient) {}

	getOneTimesheet(userId: string, queryParams: any, callback: Callbacks) {
		this.http
			.get<any>(
				`${environment.api}/admin/timesheet/?user=${userId}&${queryParams}`
			)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	deleteTimesheetByAdmin(timesheetId: string, callback: Callbacks) {
		this.http
			.delete<any>(`${environment.api}/timesheet/${timesheetId}/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	getTimesheets(date: any, callback: Callbacks) {
		this.http
			.get<any>(
				`${environment.api}/timesheet/?start=${date.start}&end=${date.end}`
			)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	createTimesheet(timesheet: any, callback: Callbacks) {
		this.http
			.post<any>(`${environment.api}/timesheet/`, timesheet)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	deleteTimesheet(timesheetId: string, callback: Callbacks) {
		this.http
			.delete<any>(`${environment.api}/timesheet/${timesheetId}/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	updateTimesheet(timesheetId: string, timesheet: any, callback: Callbacks) {
		this.http
			.patch<any>(
				`${environment.api}/timesheet/${timesheetId}/`,
				timesheet
			)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	downloadTimesheet(timesheet: any, callback: Callbacks) {
		const headers: any = { responseType: 'blob' }

		this.http
			.post<any>(
				`${environment.api}/timesheet/export/`,
				timesheet,
				headers
			)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}
}
