import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { CollaboratorsService } from 'src/app/services/collaborators/collaborators.service'
import { HelpersService } from 'src/app/services/helpers/helpers.service'
import { Collaborator } from 'src/app/models/collaborator'
import { Options } from 'select2'
import { User } from 'src/app/models/user'
import { TimesheetService } from 'src/app/services/timesheet/timesheet.service'
import { ClientsService } from 'src/app/services/clients/clients.service'
import { ProjectsService } from 'src/app/services/projects/projects.service'
import Date from 'src/app/models/date'
import { TimesheetModel } from 'src/app/models/timesheet'
import { saveAs } from 'file-saver'
import { ToastrService } from 'ngx-toastr'
import reportsHeader from 'src/app/mocks/reports.header'
import status from 'src/app/mocks/status'

@Component({
	selector: 'app-reports',
	templateUrl: './reports.component.html',
	styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
	@ViewChild('tableClockwork', { static: false })
	tableClockwork?: TemplateRef<any>

	user: User[] = []
	collaborators: Collaborator[] = []
	loadingTable: boolean = false
	loadingHours: boolean = false
	loadingSelectClient: boolean = false
	loadingSelectProject: boolean = false
	status = status
	clients: any = []
	projects: any = []
	statusSelected: string = 'all'
	clientSelected: string = 'all'
	projectSelected: string = 'all'
	reportsHeader = reportsHeader
	selectDisable: boolean = true
	searchTime: any
	params: any = {}
	total: number = 0
	clearFilterShow: boolean = false
	page: number = 1
	collaboratorSearched: string = ''
	dataControl: any = {}

	dates: Date[] = []
	currentMonth: any
	date = {
		start: '',
		end: '',
	}

	public options: Options

	constructor(
		private collaboratorService: CollaboratorsService,
		private clientsService: ClientsService,
		private projectsService: ProjectsService,
		public helpers: HelpersService,
		private timesheetService: TimesheetService,
		private toast: ToastrService
	) {
		this.options = {
			multiple: true,
			width: '300',
		}
	}

	ngOnInit(): void {
		this.getCollaborators()
		this.getCurrentDate()
		this.getDates()
		this.getClients()
	}

	searchCollaborator(collaboratorName: string) {
		clearTimeout(this.searchTime)
		this.searchTime = setTimeout(() => {
			const searchValue = collaboratorName.trim()
			const param: string = this.setParam('search', searchValue)
			this.getCollaborators(param)
		}, 350)
	}

	getCollaborators(params: string = '') {
		const self = this
		this.loadingTable = true

		this.collaboratorService.getCollaborators(
			{
				fnSuccess({ data, count }) {
					self.total = count
					self.collaborators = data
					self.formatTimesheetByUser(data)
					self.loadingTable = false
				},
				fnError(err) {
					console.warn(err)
					self.loadingTable = false
				},
			},
			params
		)
	}

	showBtnClearFilter() {
		const dateChanged =
			this.currentMonth.start !== this.date.start &&
			this.currentMonth.start !== this.date.start

		if (
			dateChanged ||
			this.statusSelected !== 'all' ||
			this.clientSelected.length
		) {
			this.clearFilterShow = true
		} else {
			this.clearFilterShow = false
		}
	}

	fnParam(name?: string, value?: any): string {
		this.showBtnClearFilter()

		if (name) {
			if (name !== 'page') {
				this.params['page'] = 1
				this.page = 1
			}

			this.params[name] = value
		}

		const params = Object.entries(this.params)
			.map((param, index) => {
				const [query, value] = param
				const cancat = index === 0 ? '?' : '&'
				return `${cancat}${query}=${value}`
			})
			.join('')

		return params
	}

	getParams() {
		return this.fnParam()
	}

	setParam(name: string, value: any) {
		return this.fnParam(name, value)
	}

	getCurrentDate() {
		const day = new Date().getDate()
		const dayWithZero = day < 10 ? `0${day}` : day
		const month = new Date().getMonth() + 1
		const monthWithZero = month < 10 ? `0${month}` : month
		const year = new Date().getFullYear()

		const currentDate = `${year}-${monthWithZero}-${dayWithZero}`

		this.date = {
			start: `${year}-${monthWithZero}-01`,
			end: currentDate,
		}
		this.currentMonth = { ...this.date }
	}

	filterByStatus(status: any) {
		let param: string = ''

		if (status === 'all') {
			const { status, ...objCloned } = this.params // removing status property
			this.params = objCloned
			param = this.getParams()
		} else {
			param = this.setParam('status', status)
		}

		this.getCollaborators(param)
	}

	clearFilters() {
		this.date = { ...this.currentMonth }
		this.params['start'] = this.date.start
		this.params['end'] = this.date.end
		this.statusSelected = 'all'

		this.clientSelected = ''
		this.projectSelected = ''
		this.selectDisable = true
		this.collaboratorSearched = ''

		const { project_id, client_id, status, search, ...objCloned } =
			this.params // removing status property
		this.params = { ...objCloned, search: '' }

		const param = this.getParams()
		this.getCollaborators(param)
	}

	getDates() {
		const dates = []

		const currentDate = new Date(this.helpers.formateDate(this.date.start))
		const endDate = new Date(this.helpers.formateDate(this.date.end))

		while (currentDate <= endDate) {
			const day = currentDate.getDate()
			const dayWithZero = `${day < 10 ? '0' : ''}${day}`

			const month = currentDate.getMonth() + 1
			const monthWithZero = `${month < 10 ? '0' : ''}${month}`

			const dateArray = {
				day: dayWithZero,
				month: monthWithZero,
				year: currentDate.getFullYear(),
			}
			dates.push(dateArray)
			currentDate.setDate(currentDate.getDate() + 1)
		}

		this.dates = dates
	}

	changeDate(date: any, prop: 'start' | 'end') {
		this.date[prop] = date

		if (prop === 'start') {
			if (this.date['start'] > this.date['end']) {
				this.date['end'] = this.date['start']
			}
		}

		this.params['start'] = this.date.start
		const params = this.setParam('end', this.date.end)

		this.getDates()
		this.getCollaborators(params)
	}

	formatTimesheetByUser(collaborators: any) {
		collaborators.forEach((collaborator: any) => {
			const { timesheet } = collaborator

			let listTimesheets: any = []

			listTimesheets = this.dates.map((date: Date) => {
				const { year, month, day } = date

				let totalHours = 0
				const dateFormated = `${year}-${month}-${day}`

				const timesheetsByDate = timesheet.timesheets.filter(
					(itemTimesheet: TimesheetModel) => {
						return itemTimesheet.date === dateFormated
					}
				)

				if (timesheetsByDate.length) {
					totalHours = timesheetsByDate.reduce(
						(acc: any, value: any) => {
							acc += value.hours
							return acc
						},
						0
					)
				}

				const weekday = new Date(dateFormated).getDay()
				const monthName = this.helpers
					.searchingMonthByNumber(Number(month) - 1)
					.substring(0, 3)
				const abbreviation =
					this.helpers.abbreviatingWeekdayName(weekday)

				return { year, month: monthName, day, abbreviation, totalHours }
			})

			this.dataControl[collaborator.uuid] = listTimesheets
		})
	}

	downloadTimsheeetColaborator(colaboratorSelected: any) {
		const self = this
		const { timesheet } = colaboratorSelected

		if (!timesheet.timesheets.length) {
			this.toast.warning(
				'Para a realização do download do histórico, é necessário que pelo menos um timesheet seja adicionado.'
			)
			return
		}

		this.timesheetService.downloadTimesheet(timesheet, {
			fnSuccess(csv) {
				const blob = new Blob([csv], {
					type: 'application/octet-stream',
				})
				const [start, end] = [self.date.start, self.date.end]
				const name = `${colaboratorSelected.first_name}-${colaboratorSelected.last_name}`
				saveAs(
					blob,
					`controle-de-horas-${name}_(${start} - ${end}).csv`
				)
			},
			fnError(err) {
				console.warn('Erro no export', err)
			},
		})
	}

	isWeekend(abbr: string) {
		abbr = abbr.toLowerCase()
		return abbr === 'sáb.' || abbr === 'dom.'
	}

	changePagination(page: any) {
		this.page = page
		const params = this.setParam('page', page)
		this.getCollaborators(params)
	}

	getClients() {
		const self = this
		self.loadingSelectClient = true

		this.clientsService.getClientsForAdmin(
			{
				fnSuccess(data) {
					const currentClient: any = []

					data.forEach((item: any) => {
						currentClient.push({
							text: item.name,
							id: item.uuid,
						})
					})

					self.clients = currentClient
					self.loadingSelectClient = false
				},
				fnError(err) {
					console.warn(err)
					self.loadingSelectClient = false
				},
			},
			'?all'
		)
	}

	getProjects(clientId: any) {
		const self = this
		self.loadingSelectProject = true

		this.projectsService.getProjectByClient({
			fnSuccess({ data }) {
				const currentProjects: any = []

				data.forEach((item: any) => {
					currentProjects.push({
						text: item.name,
						id: item.uuid,
					})
				})

				self.projects = currentProjects
				self.loadingSelectProject = false
			},
			fnError(err) {
				console.warn(err)
				self.loadingSelectProject = false
			},
		})
	}

	changeSearchClientsParam(clientId: any) {
		this.setParam('project_id', '')
		let param = this.setParam('client_id', clientId)
		this.projectSelected = ''
		this.selectDisable = false

		this.getProjects(clientId)
		this.getCollaborators(param)
	}

	changeSearchProjectsParam(projectId: any) {
		let param = this.setParam('project_id', projectId)

		this.getCollaborators(param)
	}
}
