import { Component, OnInit } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import optionsDays from 'src/app/mocks/options-days';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  client: string = ""
  optionsDays: Array<Select2OptionData> = optionsDays
  currentDay: string = ""
  topContent: any = {}

  dashboardExport: {
    top?: any,
    middle?: {
      users: any,
      client_dash: any
    },
    bottom?: any
  } = {}

  constructor(
    private dashboardService: DashboardService,
  ) { }

  ngOnInit(): void {
    this.getDashboard()
  }

  downloadOverview() {
    this.dashboardService.exportDashboard(this.dashboardExport, {
      fnSuccess(csv) {
        const blob = new Blob([csv], { type: 'application/octet-stream' });
        saveAs(blob, 'dashboard-timesheet.csv');
      }, fnError(err) {
        console.warn("Erro no export", err)
      }
    })
  }

  getDashboard() {
    const self = this
    this.dashboardService.getDashboardTop({
      fnSuccess(data) {
        self.topContent = data
        self.dashboardExport.top = data
      }, fnError(err) {
        console.warn("Err", err)
      }
    })
  }

  changeClient(clientId: string) {
    this.client = clientId
  }
}
