import { ListHeader } from "../models/list-header"

const reportsHeader: ListHeader[] = [
  {
    value: '',
    label: 'NOME DO COLABORADOR',
    align: '',
    className: ''
  },
  {
    value: '',
    label: 'STATUS',
    align: '',
    className: 'text-center'
  },
  {
    value: '',
    label: ' HORAS',
    align: '',
    className: 'text-center'
  },
  {
    value: '',
    label: 'EXTRA',
    align: '',
    className: 'text-center'
  },
  {
    value: '',
    label: 'TOTAL DE HORAS',
    align: '',
    className: 'text-center'
  },
  {
    value: '',
    label: '',
    align: '',
    className: 'text-center'
  },
]

export default reportsHeader
