<div class="row pb-5">
    <div>
        <h1>Componentes</h1>
        <hr />
    </div>
	<section>
        <h2>Form with submit</h2>
        <div class='row w-100'>
            <div class='col-sm-6 d-flex justify-content-center'>
                <form
                    action='#'
                    autocomplete='off'
                    (ngSubmit)='onSubmit()'
                    [formGroup]='form'
                >
                    <app-input
                        id='cpf'
                        name='cpf'
                        typeInput='text'
                        labelName='CPF*'
                        placeholder='Type your document*'
                        [form]='form'
                        inputMask='000.000.000-00'
                    ></app-input>
                    <app-input
                        id='password'
                        name='password'
                        typeInput='password'
                        labelName='Password*'
                        placeholder='Type your password'
                        [form]='form'
                    ></app-input>
                    <div class='wrapper-forgot-password'>
                        <a [routerLink]='[]' (click)='openModalForgotPassword()'>Forgot password</a>
                    </div>
                    <button
                        type='submit'
                        class='btn w-100'
                        [disabled]='form.invalid'
                    >
                        Send
                    </button>
                </form>
            </div>
        </div>
    </section>
</div>
