import { Component, OnInit } from '@angular/core';
import { CollaboratorData } from 'src/app/models/collaborator';
import { CollaboratorsService } from 'src/app/services/collaborators/collaborators.service';
import { collaboratorsHeaderInternal } from 'src/app/mocks/collaborators-header';
import { ActivatedRoute } from '@angular/router';
import { ListHeader } from 'src/app/models/list-header';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ItemMenu } from 'src/app/models/list-menu';
import { RolesService } from 'src/app/services/roles/roles.service';

@Component({
  selector: 'app-collaborators-profile',
  templateUrl: './collaborators-profile.component.html',
  styleUrls: ['./collaborators-profile.component.scss']
})
export class CollaboratorsProfileComponent implements OnInit {
  collaboratorData?: CollaboratorData
  profile: any
  collaboratorsHeader: ListHeader[] = collaboratorsHeaderInternal
  itemSelected: string = 'Editar'
  listMenu: ItemMenu[] = []
  loading: boolean = false
  collaboratorId: string = ""
  date: any

  constructor(
    private route: ActivatedRoute,
    private collaboratorService: CollaboratorsService,
    public helpers: HelpersService,
    private rolesServices: RolesService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      this.collaboratorId = params["id"]
      const start = this.route.snapshot.queryParamMap.get('start');
      const end = this.route.snapshot.queryParamMap.get('end');
      this.date = { start, end }
      if (start && end) {
        this.itemSelected = 'Projetos'
      }
      this.getCollaboratorsProfile()
    });

    this.listMenu = [
      {
        title: 'Editar',
        icon: '',
        link: ''
      },
      // {
      //   title: 'Calendário',
      //   icon: '',
      //   link: ''
      // },
      {
        title: 'Projetos',
        icon: '',
        link: ''
      },
    ]
  }

  getCollaboratorsProfile() {
    const self = this

    this.collaboratorService.getCollaboratorProfile(this.collaboratorId, {
      fnSuccess(data) {
        const { user, role } = data
        self.collaboratorData = {
          ...data,
          user: { ...user, uuid: self.collaboratorId }
        }
        self.profile = {
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          projectsQntd: data.projects.length
        }

        if (role.length) {
          const roleId = role[0].role_id
          self.getRoleById(roleId)
        }
      }, fnError(err) {
        console.warn(err)
      }
    })
  }

  setIdActiveItem(item: ItemMenu): void {
    this.itemSelected = item.title
  }

  getRoleById(roleId: number) {
    const self = this

    this.rolesServices.getRoleById(roleId, {
      fnSuccess(data) {
        const { role } = data
        self.profile = { ...self.profile, role: role.name }
      }, fnError(err) {
        console.warn(err)
      }
    })
  }

  updatePage() {
    this.getCollaboratorsProfile()
  }
}
