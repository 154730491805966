import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';
import { Client } from 'src/app/models/client';
import tooltipChart from 'src/app/mocks/tooltip-chart';

@Component({
  selector: 'app-projects-chart',
  templateUrl: './projects-chart.component.html',
  styleUrls: ['./projects-chart.component.scss']
})
export class ProjectsChartComponent implements OnInit {
  @Input() dashboardExport: any
  @Input() client: string = ""
  project: string = ""
  projects: Array<Select2OptionData> = []
  loadingProject: boolean = false
  loading: boolean = false
  validationsProject: boolean = false
  plugins = tooltipChart
  totalHours = {
    total: 0,
    used: 0
  }

  dahsboardTeam: any = {}

  constructor(
    private dashboardService: DashboardService,
    private projectsService: ProjectsService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["client"].currentValue.length) {
      this.getProjectByClient(this.client)
    }
  }

  getProjectByClient(clientId: string) {
    const self = this

    if (clientId) {
      this.projectsService.getProjectByClientForAdmin(clientId, {
        fnSuccess({ data }) {
          self.loading = true

          if (data && data.length) {
            self.project = data[0].uuid

            setTimeout(() => {
              self.projects = data.map((item: Client) => ({
                text: item.name,
                id: item.uuid
              }))
              self.loading = false
              self.getDashboardByProject(self.project)
            })
          } else {
            self.project = ""
            self.loading = false

            setTimeout(() => {
              self.projects = []
              self.loading = false
            })
          }
        }, fnError(err) {
          console.warn(err)
          self.loading = false
          self.validationsProject = false
          self.dahsboardTeam = {}
        }
      })
    }
  }

  getDashboardByProject(projectId: string) {
    const self = this

    if (projectId) {
      this.loadingProject = true
      this.dashboardService.getDashboardByProject(projectId, {
        fnSuccess(data) {
          const { users } = data
          const budget = users
          const totalHoursObj = {
            total: 0,
            used: 0
          }

          let usedDataset: any = []
          let contractedDataset: any = []

          if (budget?.users) {
            budget?.users.forEach((item: any) => {
              item.hours.forEach((hour: any) => {
                const functionDefault = hour.function || "Não informado"
                const skillExist = usedDataset.find(({ id }: any) => id === functionDefault)
                const totalHours = hour.expediente + hour.extra
                const dataItem = {
                  context: `${item.first_name} ${item.last_name}`,
                  hoursNormal: hour.expediente,
                  overtime: hour.extra
                }

                totalHoursObj.used += totalHours || 0

                if (skillExist) {
                  skillExist.nested.team.push(dataItem)
                  skillExist.nested.total = skillExist.nested.total + totalHours
                } else {
                  usedDataset.push({
                    id: functionDefault,
                    nested: {
                      team: [dataItem],
                      total: totalHours
                    }
                  })
                }
              })
            })
          }

          if (budget?.info_budget && budget?.info_budget.length) {
            budget?.info_budget.forEach((budgetItem: any) => {
              contractedDataset.push({
                id: budgetItem.skill,
                nested: { total: budgetItem.total_hours }
              })

              totalHoursObj.total += budgetItem.total_hours
            })
          } else if (usedDataset.length) {
            usedDataset.forEach((item: any) => {
              const skillExist = contractedDataset.find((contractedItem: any) => contractedItem.id === item.id)

              if (skillExist) {
                const total = skillExist.nested.total + item.nested.total
                skillExist.nested.total = total
              } else {
                contractedDataset.push({
                  id: item.id,
                  nested: { total: item.nested.total }
                })
              }
            })

            totalHoursObj.total = totalHoursObj.used
          }

          self.validationsProject = !usedDataset.some((data: number) => data > 0)
          self.dahsboardTeam = {
            datasets: [
              {
                label: "Hora contratada",
                backgroundColor: '#8DA1C1',
                data: contractedDataset
              },
              {
                label: "Hora utilizada",
                backgroundColor: '#4285F4',
                data: usedDataset
              },
            ]
          }

          self.totalHours = totalHoursObj
          self.loadingProject = false
          self.dashboardExport.middle = { ...self.dashboardExport.middle, ...data }
        }, fnError(err) {
          console.warn(err)
          self.loadingProject = false
          self.validationsProject = false
          self.dahsboardTeam = {}
        }
      })
    }
  }
}
