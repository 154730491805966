import { ListHeader } from "../models/list-header"

const usersHeader: ListHeader[] = [
  {
    value: '',
    label: 'NOME DO COLABORADOR',
    align: '',
    className: ''
  },
  {
    value: '',
    label: 'CARGO',
    align: '',
    className: 'text-center'
  },
  {
    value: '',
    label: 'TOTAL DE HORAS',
    align: '',
    className: 'text-center'
  },
]

export default usersHeader