<div class="main-collaborators-projects">
    <h4 class="title"> Colaborador </h4>

    <div class="row">
        <div class="col-6">
            <div class="wrapper-collaborators">
                <ng-select2 
                    id="users"
                    [data]="users" 
                    [(ngModel)]="user"
                    (ngModelChange)="getDashboardByUser($event)"
                    placeholder="Selecione um colaborador"
                ></ng-select2>
            </div>
        </div>

        <div class="col-6 d-flex justify-content-end wrapper-month">
            <ng-select2 
                id="months"
                [data]="months" 
                [(ngModel)]="month"
                (ngModelChange)="setDateUser($event)"
                placeholder="Selecione um mês"
                class="filter-month"
                [class.disabled]="!user"
            ></ng-select2>
        </div>
    </div>

    <div class="main-content">
        <div class="wrapper-chart" *ngIf="!loadingUser">
            <div *ngIf="user && projectsByUser.labels.length && !validationsUser">    
                <h3 class="total-hours">
                    Total de horas: {{ totalHours }}h
                </h3>

                <app-chart
                    chartType="doughnut"
                    [data]="projectsByUser"
                    [options]="optionsChartJs"
                ></app-chart>
            </div>
            
            <h3 class="text-display" *ngIf="!user"> Selecione um colaborador para a busca de suas informações! </h3>
            <h3 class="text-display" *ngIf="user && validationsUser"> 
                Usuário sem informações neste periodo ou seus valores estão zerados!
            </h3>
        </div>

        <div class="loading">
            <app-loading-local [loading]="loadingUser"></app-loading-local>
        </div>
    </div>
</div>
