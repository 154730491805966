<div class="modal-confirm-content">
    <figure class="d-flex justify-content-center">
        <img class="icon-wave" src="/assets/images/temp/wave.svg" alt="wave" title="Wave">
    </figure>

    <h3> {{ title }} </h3>

    <p *ngIf="description" class="mt-4 mb-4">
        {{ description }}
    </p>

    <div class="d-flex justify-content-end align-items-center buttons-action">
        <button 
            class="btn-default btn-cancel"
            (click)="close()"
        > 
            Cancelar 
        </button>
        <button
            class="btn-default btn-confirm"
            (click)="confirmation()"
        > 
            {{ btnTitle }} 
        </button>
    </div>
</div>