import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { ToastrService } from 'ngx-toastr'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { HelpersService } from '../../services/helpers/helpers.service'
import { GenericValidatorService } from '../../services/validators/generic-validator.service'
import { ModalDefaultComponent } from '../../components/modals/modal-default/modal-default.component'

@Component({
    selector: 'app-components',
    templateUrl: './components.component.html',
    styleUrls: ['./components.component.scss']
})
export class ComponentsComponent implements OnInit {
    form: FormGroup

    constructor(private formBuilder: FormBuilder,
                private toast: ToastrService,
                private modalDefault: NgbModal,
                public helpers: HelpersService) {
        this.form = this.formBuilder.group({
            cpf: [null, [Validators.required, GenericValidatorService.isValidCpf]],
            password: [null, Validators.required]
        })
    }

    ngOnInit(): void {
    }

    openModalForgotPassword(): void {
        const modalRef = this.modalDefault.open(ModalDefaultComponent)
        modalRef.componentInstance.title = 'Title'
        modalRef.componentInstance.desc =
            'Description'
        modalRef.componentInstance.callbackConfirmation = () => {
            alert('Click')
        }
    }

    onSubmit(): void {
        if (this.form.valid) {
            this.toast.success('Success!')
        } else {
            this.toast.error('Form invalid!')
        }
    }
}
