import { ListHeader } from '../models/list-header'

const notesHeader: ListHeader[] = [
	{
		value: '',
		label: 'Dia',
		align: '',
		className: '',
	},
	{
		value: '',
		label: 'Tipo',
		align: '',
		className: '',
	},
	{
		value: '',
		label: '',
	},
]

export { notesHeader }
