const tooltipChart = {
    parsing: {
      xAxisKey: 'id',
      yAxisKey: 'nested.total'
    },
    plugins: {
      tooltip: {
        enabled: false,
        external: function(context: any) {
          // Tooltip Element
          let tooltipEl: any = document.getElementById('chartjs-tooltip');

          // Create element on first render
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<div class="tooltip-wrapper"></div>';
            document.body.appendChild(tooltipEl);
          }
  
          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }
  
          // Set caret Position
          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }
  
          function getBody(bodyItem: any) {
            const { team, total } = bodyItem.raw.nested

            if(!team) {
              return { text: `${total} horas contratadas no total` }
            }
            return [ ...team, { text: `${total} horas totais`, isTotal: true  } ];
          }
  
          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            const bodyLines = tooltipModel.dataPoints.map(getBody);
  
            let innerHtml = '<div class="tooltip-header">';
  
            titleLines.forEach(function(title: any) {
              innerHtml += '<h4 style="text-align: center; margin-bottom: 10px; display: block;">' + title + '</h4>';
            });
  
            innerHtml += '</div> <div class="tooltip-body">';
  
            bodyLines.forEach(function(body: any) {
              if(Array.isArray(body)) {
                body.forEach(function(item: any, index: number) {
                  let content = ''
    
                  if (!item.isTotal) {
                    const style = !(body.length - 2 === index) ? "padding-bottom: 5px; display: block; border-bottom: 1px solid #E0E0E0;" : "padding-top: 5px"
                    content = `
                      <span style="${style} color: #8A9099"> 
                        ${item.context} - 
                        <span style="color: #4285F4">${item.hoursNormal}h</span>/<span style="color: #FC473D">${item.overtime}h</span> 
                      </span>`;
                    innerHtml += `<div> ${content} </div>`;
                  } else {
                    innerHtml += `<div style="text-align: center; margin-top: 10px;"> <strong>${item.text}</strong> </div>`;
                  }
                })
              } else {
                innerHtml += '<h5>' + body.text + '</h5>';
              }
            });
  
            innerHtml += '</div>';
  
            let tableRoot = tooltipEl.querySelector('.tooltip-wrapper');
            tableRoot.innerHTML = innerHtml;
          }
  
          const position = context.chart.canvas.getBoundingClientRect();
  
          // Display, position, and set styles for font
          tooltipEl.style.opacity = 1;
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
          tooltipEl.style.padding = "5px 10px";
          tooltipEl.style.pointerEvents = 'none';
          tooltipEl.style.backgroundColor = 'white';
          tooltipEl.style.borderRadius = '12px';
          tooltipEl.style.minWidth = '150px';
          tooltipEl.style.maxWidth = '200px';
          tooltipEl.style.overflow = 'hidden';
          tooltipEl.style.boxShadow = '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)';
        }
      }
    }
}

export default tooltipChart