<app-loading-local class="loading" [loading]="loading"></app-loading-local>

<div class="client-profile" *ngIf="!loading">
    <h3 class="client-profile-title">
        {{ client ? client.name : '--' }}
    </h3>

    <div class="client-profile-content mb-5" *ngIf="client">
        <div class="client-profile-content-header">
            <div class="client-profile-content-wrapper-image">
                <app-image
                    [path]="client.avatar || ''"
                    [title]="'Cliente ' + client.name"
                ></app-image>
            </div>
        
            <div class="client-profile-content-descriptions">
                <div>
                    <strong>Razão social</strong>: <span> {{ client.razao_social || '--' }} </span>
                </div>
                <div>
                    <strong>CNPJ</strong>: 
                    <span> 
                        {{ client.cnpj ? helpers.addMaskCnpj(client.cnpj) : '00.000.000/0000-00' }} 
                    </span>
                </div>
            </div>

            <button class="client-profile-content-edit" (click)="showModalEdit()">
                <i class="bi bi-pencil"></i>
            </button>
        </div>
    </div>
    
    <div class="client-profile-content">
        <div class="client-profile-content-projects">
            <h3> Projetos: </h3>

            <div *ngIf="!loading" class="wrapper-table-search table-client">
                <app-table-list [listHeader]='clientHeader' [listBody]='clientProjects'>
                    <ng-template let-item #body>
                        <td>
                            <span
                                class="badge-status"
                                [class]="item.is_activate ? 'success' : 'danger'"
                            >
                                {{ item.is_activate ? "Ativo" : "inativo" }}
                            </span>
                        </td>
                        <td>
                            <a [routerLink]="'/admin/projects/' + item.uuid">
                                <h4 class="d-inline"> {{ item.name }} </h4>
                            </a>
                        </td>
                        <td>
                            <button (click)="showModalViewDetails(item)">
                                Ver detalhes
                            </button>
                        </td>
                    </ng-template>
                </app-table-list>
            </div>
        </div>
    </div>
</div>

<!-- Modal view details -->
<ng-template #modalViewDetails>
    <div class="d-flex justify-content-between align-items-center">
        <h3>Software gerenciado pela empresa</h3>
        <span class="title-modal"> 
            {{ projectSelected?.is_activate ? "Em andamento" : "Parado" }} 
        </span>
    </div>

    <div class="row view-details-wrapper-content">
        <div class="col-lg-6 view-details-wrapper-users">
            <h4 class="mb-4"> Pessoas no projeto </h4>
            <div class="view-details-users" *ngIf="projectSelected?.users?.length; else noUsers">
                <div
                    *ngFor="let user of projectSelected?.users; let index = index" 
                    [ngStyle]="{ 'background-color': setColorRandom(index) }"
                    class="view-details-user"
                >
                    <a [routerLink]="'/admin/collaborators/' + user.uuid" (click)="closeModal()">
                        {{ getInitials(user) }}
                    </a>

                    <span class="suspended-name"> {{ user.first_name }} {{ user.last_name }} </span>
                </div>
            </div>

            <ng-template #noUsers>
                Nenhum usuário interligado a este projeto
            </ng-template>
        </div>

        <div class="col-lg-6">
            <app-accordion title="Informações" [isOpen]="true" *ngIf="projectSelected">
                <div class="d-flex justify-content-between align-items-center">
                    <strong>Data de inicio:</strong> 
                    <span>{{ helpers.getDate(projectSelected.created_at) }}</span>
                </div>

                <div class="mt-3">
                    <strong> Orçamento de horas: </strong>
                    <div *ngIf="projectSelected.budget?.length; else noBudget">
                        <div 
                            *ngFor="let budget of projectSelected.budget"  
                            class="mt-2 view-details-budget"
                        >
                            <span>{{ budget.skill }}</span>: <strong>{{ budget.total_hours }}h</strong>
                        </div>
                    </div>

                    <ng-template #noBudget>
                        <h5 class="text-center mt-4"> Sem orçamentos informados </h5>
                    </ng-template>
                </div>
            </app-accordion>
        </div>
    </div>
</ng-template>

<!-- Modal Update -->
<ng-template #modalCustom>
    <div class="row">
        <div class="col-lg-6">
            <app-image-cutter
                (imageChange)="setAvatar($event)"
                [image]="form.value.avatar ? form.value.avatar : '/assets/images/image-default.jpg'"
            ></app-image-cutter>
        </div>
        <div class="col-lg-6">
            <app-input 
                [form]="form" 
                name="name" 
                labelName="Nome do cliente*" 
                placeholder="Adicione o nome do cliente"
                [inputModel]="form.value.name"
            ></app-input>

            <app-input 
                [form]="form" 
                name="cnpj" 
                labelName="CNPJ*" 
                placeholder="Adicione o cnpj do cliente"
                inputMask="00.000.000/0000-00"
                [inputModel]="form.value.cnpj"
            ></app-input>

            <app-input 
                [form]="form" 
                name="razaoSocial" 
                labelName="Razão social" 
                placeholder="Adicione a razão social do cliente"
                [inputModel]="form.value.razaoSocial"
            ></app-input>
        </div>
    </div>

    <div class="wrapper-buttons col-12 d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-cancel btn-secondary " (click)="closeModal()">
            Cancelar
        </button>

        <button 
            type="button" 
            class="btn-save ms-3 btn-default" 
            [class.btn-disabled]="form.invalid"
            (click)="onSubmit()"
        >
            Salvar
        </button>
    </div>
</ng-template>
