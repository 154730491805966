import { ListHeader } from "../models/list-header"

const clientHeader: ListHeader[] = [    
    {
      value: 'name',
      label: 'NOME DO PROJETO',
      align: '',
      className: ''
    },
    {
      value: '',
      label: 'ENTRADA',
      align: '',
      className: ''
    },
    {
      value: '',
      label: 'CLIENTE',
      align: '',
      className: ''
    },
    {
      value: '',
      label: '',
    },
]

const clientHeaderInternal: ListHeader[] = [
  {
    value: 'status',
    label: 'status',
  },
  {
    value: 'name',
    label: 'NOME DO PROJETO',
  },
  {
    value: '',
    label: '',
  }
]

export {
  clientHeader,
  clientHeaderInternal
}
