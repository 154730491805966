import { Component, OnInit, Input } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { Collaborator } from 'src/app/models/collaborator';
import { CollaboratorsService } from 'src/app/services/collaborators/collaborators.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import months from 'src/app/mocks/months';

@Component({
  selector: 'app-collaborator-chart',
  templateUrl: './collaborator-chart.component.html',
  styleUrls: ['./collaborator-chart.component.scss']
})
export class CollaboratorChartComponent implements OnInit {
  @Input() dashboardExport: any
  user: string = ""
  users: Array<Select2OptionData> = []
  dateProject: any
  loadingUser: boolean = false
  validationsUser: boolean = false
  totalHours: number = 0

  optionsChartJs = {
    tooltips: {
      enabled: true,
      mode: 'index',
      position: 'left',
      intersect: false
    },
  }

  currentMonth: number = new Date().getMonth() + 1
  month: number = this.currentMonth
  months: Array<Select2OptionData> = months

  projectsByUser: any

  constructor(
    private dashboardService: DashboardService,
    private collaboratorsService: CollaboratorsService
  ) { }

  ngOnInit(): void {
    this.getUsers()
    this.limitingMonthsQuantity()
  }

  limitingMonthsQuantity() {
    const currentMonth = new Date().getMonth()
    this.months = months.filter((_, idx) => idx <= currentMonth) // limiting to the current month
  }

  getUsers() {
    const self = this
    const params = "?all"

    this.collaboratorsService.getCollaborators({
      fnSuccess(data) {
        if (data.length) {
          self.users = data.map((item: Collaborator) => ({
            text: `${item.first_name} ${item.last_name}` || "--",
            id: item.uuid
          }))
        }
      }, fnError(err) {
        console.warn(err)
      }
    }, params)
  }

  setDateUser(month: number) {
    if (month) {
      const self = this
      this.month = month
      this.loadingUser = true

      this.dashboardService.getDashboard(this.user, {
        fnSuccess(data) {
          self.setDataUser(data)
          self.loadingUser = false
        }, fnError(err) {
          console.warn(err)
          self.loadingUser = false
        }
      }, month)
    }
  }

  formatDate(date: string) {
    const dateSplited = date.split("/")
    return `${dateSplited[2]}-${dateSplited[1]}-${dateSplited[0]}`
  }

  getDashboardByUser(user: string) {
    const self = this
    this.loadingUser = true

    if (user) {
      this.user = user
      this.setDateUser(this.month)
    } else {
      self.loadingUser = false
    }
  }

  setDataUser(data: any) {
    let labels: any = []
    let datasets: any = []

    data.projects.forEach((project: any) => {
      labels.push(project.name)
      datasets.push(project.hours)
    })

    this.totalHours = parseInt(data.total_hours)

    this.projectsByUser = {
      labels,
      datasets: [
        {
          data: datasets,
          label: 'Total de horas',
          backgroundColor: this.getColorsForList(datasets)
        }
      ]
    };

    this.validationsUser = !datasets.some((data: number) => data > 0)
    this.dashboardExport.bottom = data
  }

  getColorsForList(list: any[]): string[] {
    const baseColor = [0x86, 0xC9, 0xFD]; // RGB values of #86C9FD
    const colorMap: any = {};
    return list.map((_: any, index: number) => {
      let r, g, b;
      do {
        r = (baseColor[0] + index * 17) % 256;
        g = (baseColor[1] + index * 23) % 256;
        b = (baseColor[2] + index * 29) % 256;

        const colorKey = `${r},${g},${b}`;
        if (colorMap[colorKey]) {
          index++;
          continue;
        }
        colorMap[colorKey] = true;
        break;
      } while (true);

      return `rgba(${r}, ${g}, ${b})`;
    });
  }
}
