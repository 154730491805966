import { Injectable } from '@angular/core'
import * as moment from 'moment'
import jwtDecode from 'jwt-decode'
import { AbstractControl, ValidatorFn } from '@angular/forms'

@Injectable({
	providedIn: 'root',
})
export class HelpersService {
	private controlMessageError(typeError: string, control?: string): string {
		switch (typeError) {
			case 'required':
				return `O campo ${control} é obrigatorio!`
			case 'email':
				return `E-mail inválido!`
			case 'cpfInvalid':
				return `CPF inválido!`
			case 'notSamePassword':
				return `A senha de confirmação não corresponde!`
			default:
				return ''
		}
	}

	public getControlMessageError(objError: any, control?: string): string {
		const typeError = Object.keys(objError).length
			? Object.keys(objError)[0]
			: ''
		return this.controlMessageError(typeError, control)
	}

	public converterSnakeToCamelCase(obj: any): any {
		let newObj: any = {}

		for (let d in obj) {
			if (obj.hasOwnProperty(d)) {
				newObj[
					d.replace(/(\_\w)/g, (k) => {
						return k[1].toUpperCase()
					})
				] = obj[d]
			}
		}
		return newObj
	}

	public converterCamelCaseToSnake(obj: any): any {
		let newObj: any = {}

		for (let d in obj) {
			if (obj.hasOwnProperty(d)) {
				newObj[this.camelToUnderscore(d)] = obj[d]
			}
		}
		return newObj
	}

	public camelToUnderscore(key: string): string {
		const result = key.replace(/([A-Z])/g, ' $1')
		return result.split(' ').join('_').toLowerCase()
	}

	decodePayloadJWT(token: string): any {
		try {
			return jwtDecode(token)
		} catch (Error) {
			return null
		}
	}

	public getDate(date: Date | string, withHours: boolean = false): string {
		if (!date) return '--'
		let format = withHours ? 'DD/MM/YYYY -- HH:mm' : 'DD/MM/YYYY'
		let dateString = moment.utc(date).local().format(format)
		return dateString.replace('--', 'às')
	}

	newDate(date: any) {
		let data = date.split('-')
		return `${data[2]}/${data[1]}/${data[0]}`
	}

	public searchingMonthByNumber(monthNumber: number): string {
		const months: string[] = [
			'Janeiro',
			'Fevereiro',
			'Março',
			'Abril',
			'Maio',
			'Junho',
			'Julho',
			'Agosto',
			'Setembro',
			'Outubro',
			'Novembro',
			'Dezembro',
		]
		return months[monthNumber] || '--'
	}

	public abbreviatingWeekdayName(weekday: number): string {
		const nameWeekday: string[] = [
			'Seg.',
			'Ter.',
			'Qua.',
			'Qui.',
			'Sex.',
			'Sáb.',
			'Dom.',
		]
		return nameWeekday[weekday] || '--'
	}

	public removeBlankAttributesFromObject(obj: any) {
		Object.keys(obj).forEach(
			(k: string | number) => obj[k] == null && delete obj[k]
		)
	}

	public getWeekday(weekday: number) {
		const weeks: any = {
			0: 'Domingo',
			1: 'Segunda-feira',
			2: 'Terça-feira',
			3: 'Quarta-feira',
			4: 'Quinta-feira',
			5: 'Sexta-feira',
			6: 'Sábado',
		}

		return weeks[weekday]
	}

	public addMaskCnpj(v: string) {
		return v.replace(
			/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
			'$1.$2.$3/$4-$5'
		)
	}

	cnpjValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: any } | null => {
			const cnpj = control.value
			const regex = /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/

			if (!cnpj) {
				return null
			}

			if (!regex.test(cnpj)) {
				return { cnpjInvalido: true }
			} else if (/^(\d)\1+$/.test(cnpj)) {
				return { cnpjInvalido: true }
			}

			return null
		}
	}

	public getInitials(nameComplete: string): string {
		let initials = ''
		const names = nameComplete.split(' ')

		for (let i = 0; i < names.length && i < 3; i++) {
			const initial = names[i][0].toUpperCase()
			initials += initial
		}

		return initials
	}

	public formateDate(date: string) {
		const splitedDate = date.split('-')
		const day = splitedDate[2]
		const month = splitedDate[1]
		const year = splitedDate[0]

		return `${month}/${day}/${year}`
	}

	public arrayEquals(array1: any[], array2: any[]): boolean {
		if (array1.length !== array2.length) {
			return false
		}

		for (let i = 0; i < array1.length; i++) {
			if (array1[i] !== array2[i]) {
				return false
			}
		}

		return true
	}
}
