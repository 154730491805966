const doughnutOptions = {
  rotation: -90,
  cutout: "70%",
  responsive: true,
  zoomOutPercentage: 55,
  plugins: {
    legend: {
      align: "center",
      position: "right",
      labels: {
        usePointStyle: true,
        pointStyle: "circle",
        pointRadius: 10,
        boxWidth: 10,
        padding: 15,
      },
    },
    tooltip: {
      callbacks: {
        label: function (context: any) {
          let label = context.label || '';

          if (label) {
            label += ': ';
          }

          if (context.parsed !== null) {
            label += `${context.parsed} horas`
          }
          return label;
        }
      }
    }
  },
  elements: {
    center: {
      text: 'Red is 2/3 the total numbers',
      color: '#FF6384', // Default is #000000
      fontStyle: 'Arial', // Default is Arial
      sidePadding: 20, // Default is 20 (as a percentage)
      minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
      lineHeight: 25 // Default is 25 (in px), used for when text wraps
    }
  },
  title: {
    display: true
  },
  animation: {
    animateRotate: false,
    animateScale: true,
  },
};

const barOptions = {
  responsive: true,
  barThickness: 20,
  cornerRadius: 20
};

const chartOtions: any = {
  "bar": barOptions,
  "doughnut": doughnutOptions
}

export default chartOtions