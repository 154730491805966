import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-statistics',
  templateUrl: './card-statistics.component.html',
  styleUrls: ['./card-statistics.component.scss']
})
export class CardStatisticsComponent implements OnInit {
  @Input() description: string = "Entradas"
  @Input() total: number = 0
  @Input() icon: string = "/assets/images/temp/project.svg"

  constructor() { }

  ngOnInit(): void {
  }

}
