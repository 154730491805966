import { Component, Input, OnInit } from '@angular/core'

@Component({
	selector: 'app-image',
	templateUrl: './image.component.html',
	styleUrls: ['./image.component.scss'],
})
export class ImageComponent implements OnInit {
	@Input() path?: string = ''
	@Input() title?: string = ''
	@Input() load: boolean = false
	@Input() icon: boolean = false

	errorImage: string = "/assets/images/image-default.jpg"

	default?: {
		defaultImage: string
		errorImage: string
	}

	ngOnInit(): void {
		this.errorImage = this.icon ? "assets/images/media/avatars/blank.png" : this.errorImage
	}
}
