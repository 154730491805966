import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {
  @Input() title: string = ""
  @Input() additional: boolean = true
  @Output() callbackExport: EventEmitter<any> = new EventEmitter()
  @Output() callbackAdd: EventEmitter<any> = new EventEmitter()

  constructor() {
  }

  ngOnInit(): void {
  }

  fnExport() {
    this.callbackExport.emit()
  }

  fnAdd() {
    this.callbackAdd.emit()
  }
}
