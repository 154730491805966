import { ListHeader } from "../models/list-header";

const collaboratorProjectsHeader: ListHeader[] = [    
    {
      value: 'name',
      label: 'NOME DO PROJETO',
      align: '',
      className: ''
    },
    {
      value: '',
      label: 'hora normal',
      align: '',
      className: 'text-center'
    },
    {
      value: '',
      label: 'hora extra',
      align: '',
      className: 'text-center'
    },
    {
      value: '',
      label: 'total',
      align: '',
      className: 'text-center'
    },
]

export default collaboratorProjectsHeader