<div class="worksheet">
    <div class="worksheet-form row">
        <div class="worksheet-line"></div>
        <app-input 
            [typeInput]="'time'"
            [form]="form" 
            name="startDay" 
            id="startDay"
            labelName="Início do expediente" 
            placeholder="Adicione um horário"
            class="col-sm-3"
            [inputModel]="form.value.name"
            (emmitEvent)="changeWorksheet('startDay', $event)"
            [class]="form.value.startDay ? 'selected success' : ''"
        ></app-input>

        <div class="col-sm-3 position-relative">
            <app-input 
                [typeInput]="'time'"
                [form]="form" 
                name="outLunch" 
                id="outLunch"
                labelName="Início do almoço" 
                placeholder="Adicione um horário"
                [inputModel]="form.value.name"
                (emmitEvent)="changeWorksheet('outLunch', $event)"
                [disabled]="!form.value.startDay"
                [class.selected]="form.value.outLunch"
                [class]="form.get('outLunch').errors?.min ? 'error' : 'success'"
            ></app-input>
            <p class="error-msg"> {{ form.get('outLunch').errors?.min }} </p>
        </div>

        <div class="col-sm-3 position-relative">
            <app-input 
                [typeInput]="'time'"
                [form]="form" 
                name="returnLunch" 
                id="returnLunch"
                labelName="Retorno do almoço" 
                placeholder="Adicione um horário"
                [inputModel]="form.value.name"
                (emmitEvent)="changeWorksheet('returnLunch', $event)"
                [disabled]="!form.value.outLunch"
                [class.selected]="form.value.returnLunch"
                [class]="form.get('returnLunch').errors?.min ? 'error' : 'success'"
            ></app-input>
            <p class="error-msg"> {{ form.get('returnLunch').errors?.min }} </p>
        </div>
        
        <div class="col-sm-3 position-relative">
            <app-input 
                [typeInput]="'time'"
                [form]="form" 
                name="endDay" 
                id="endDay"
                labelName="Fim do expediente"
                placeholder="Adicione um horário"
                [inputModel]="form.value.name"
                (emmitEvent)="changeWorksheet('endDay', $event)"
                [disabled]="!form.value.returnLunch"
                [class.selected]="form.value.endDay"
                [class]="form.get('endDay').errors?.min ? 'error' : 'success'"
            ></app-input>
            <p class="error-msg"> {{ form.get('endDay').errors?.min }} </p>
        </div>
    </div>

    <app-checkbox
        [form]="form" 
        id="sign"
        name="sign"
        labelName="Eu confirmo que as informações descritas acima são verdadeira e de minha total responsabilidade"
        class="d-flex align-items-center"
        [inputModel]="form.value.sign"
    ></app-checkbox>

    <button 
        [class.disabled]="!form.value.sign || form.invalid" 
        type="submit" 
        class="btn-default btn-submit"
        (click)="onSubmit()"
    >
        Salvar
    </button>
</div>