<div class="modal-header">
    <h5 *ngIf='title'>{{title}}</h5>
    <p *ngIf='desc'>{{desc}}</p>
</div>
<div class="modal-body">
    <ng-content></ng-content>
</div>
<button type="button" class="btn btn-white btn-active-light-primary me-2" (click)="close()">
    Cancel
</button>
<button type="button" class="btn btn-xs btn-primary" (click)="confirmation()">
    Confirm
</button>