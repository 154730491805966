import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ListHeader } from 'src/app/models/list-header';
import { CollaboratorsService } from 'src/app/services/collaborators/collaborators.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ToastrService } from 'ngx-toastr';
import { collaboratorsHeader } from 'src/app/mocks/collaborators-header';
import { Collaborator } from 'src/app/models/collaborator';
import { ModalCustomService } from 'src/app/services/modal/modal-service.service';
import { collaboratorDefault } from 'src/app/mocks/default-form';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalConfirmComponent } from 'src/app/components/modals/modal-confirm/modal-confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { User } from 'src/app/models/user';
import { ControlSelectOptions } from 'src/app/models/control-select-options';
import { SkillsService } from 'src/app/services/skills/skils.service';
import { WorksheetService } from 'src/app/services/worksheet/worksheet.service';
import { RolesService } from 'src/app/services/roles/roles.service';
import { ModalLgComponent } from 'src/app/components/modals/modal-lg/modal-lg.component';
import { optionsStatus } from 'src/app/mocks/options-status';
import months from 'src/app/mocks/months';
import typeContracts from 'src/app/mocks/type-contracts';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-collaborators',
  templateUrl: './collaborators.component.html',
  styleUrls: ['./collaborators.component.scss']
})
export class CollaboratorsComponent implements OnInit {
  @ViewChild('modalCustom', { static: false }) modalCustom?: TemplateRef<any>

  form: FormGroup | any
  user: User[] = []
  collaborator: Collaborator[] = []
  listFilters: Collaborator[] = []
  collaboratorsHeader: ListHeader[] = collaboratorsHeader
  collaboratorSelected?: Collaborator
  skills: Array<Select2OptionData> = []
  roles: ControlSelectOptions[] = [];
  collaboratorCloned: any
  optionsStatus = optionsStatus
  typeContracts = typeContracts
  total: number = 0
  searchTime: any
  params: any = {}

  loading: boolean = false
  loadingHours: boolean = false
  loadingTable: boolean = false

  currentMonth: number = new Date().getMonth() + 1
  month: number = this.currentMonth
  months: Array<Select2OptionData> = months
  currentDate: string = ""
  date = {
    start: "",
    end: ""
  }

  public options: Options;

  constructor(
    private modalService: NgbModal,
    private collaboratorService: CollaboratorsService,
    private skillsService: SkillsService,
    private rolesService: RolesService,
    private modalCustomService: ModalCustomService,
    private toast: ToastrService,
    public helpers: HelpersService,
    private formBuilder: FormBuilder,
    private worksheetService: WorksheetService
  ) {
    this.options = {
      multiple: true,
      width: '300'
    };
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', [Validators.email, Validators.required]],
      is_admin: [false],
      skills: [''],
      is_active: [true],
      type_contract: ['PJ', Validators.required],
      role: ['', Validators.required]
    })

    this.getCollaborators();
    this.getSkills();
    this.getRoles();
    this.getCurrentDate()
  }

  exportWorksheets() {
    const self = this
    const params = `?start=${this.date.start}&end=${this.date.end}`

    this.worksheetService.downloadWorksheetsClt(params, {
      fnSuccess(data) {
        const blob = new Blob([data], { type: 'application/octet-stream' });
        const formatDate = (date: string) => date.split("-").reverse().join("-")
        saveAs(blob, `controle-de-horas.csv_(${formatDate(self.date.start)}_${formatDate(self.date.end)}).xlsx`);
      }, fnError(err) {
        self.toast.error("Erro no download das folhas de ponto")
        console.warn(err)
      }
    })
  }

  getCurrentDate() {
    const day = new Date().getDate()
    const dayWithZero = day < 10 ? `0${day}` : day
    const month = new Date().getMonth() + 1
    const monthWithZero = month < 10 ? `0${month}` : month
    const year = new Date().getFullYear()

    this.currentDate = `${year}-${monthWithZero}-${dayWithZero}`

    this.date = {
      start: `${year}-${monthWithZero}-01`,
      end: this.currentDate
    }
  }

  searchCollaborator(event: any) {
    clearTimeout(this.searchTime)
    this.searchTime = setTimeout(() => {
      const searchValue = event.target.value.trim()
      const param: string = this.setParam("search", searchValue)
      this.getCollaborators(param)
    }, 350)
  }

  changePagination(page: any) {
    const params = this.setParam("page", page)
    this.getCollaborators(params)
  }

  fnParam(name?: string, value?: any): string {
    if (name) {
      this.params[name] = value
    }

    const params = Object.entries(this.params).map((param, index) => {
      const [query, value] = param
      const cancat = index === 0 ? "?" : "&"
      return `${cancat}${query}=${value}`
    }).join("")

    return params
  }

  getParams() {
    return this.fnParam()
  }

  setParam(name: string, value: any) {
    return this.fnParam(name, value)
  }

  getOneCollaborator(collaboratorId: string) {
    const self = this
    this.loading = true

    this.collaboratorService.getOneCollaborator(collaboratorId, {
      fnSuccess(data) {
        const { user, skills, role } = data

        const bodyUser = {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          is_admin: user.is_admin,
          is_active: String(user.is_active),
          skills: skills.length ? skills.map((skill: any) => skill.skill_id_id) : [],
          role: role.length ? role[0].role_id : null,
          type_contract: user.type_contract
        }

        self.form?.setValue(bodyUser)
        self.collaboratorCloned = { ...bodyUser }
        self.loading = false
      }, fnError(err) {
        self.toast.error("Erro ao buscar colaborador!")
        self.loading = false
        console.warn(err)
      }
    })
  }

  getCollaborators(params: string = "") {
    const self = this
    this.loadingTable = true

    this.collaboratorService.getCollaborators({
      fnSuccess({ data, count }) {
        self.total = count
        self.collaborator = data
        self.listFilters = data
        self.loadingTable = false
      },
      fnError(err) {
        self.loadingTable = false
        console.warn(err)
      }
    }, params)
  }

  showModal(title: string) {
    const data = {
      content: this.modalCustom,
      title
    }

    this.modalCustomService.showModal(data)
    this.modalCustomService.cancelModal(() => {
      this.form?.reset(collaboratorDefault)
    })
  }

  showModalCalendar(collaborator: Collaborator) {
    const modalRef = this.modalService.open(
      ModalLgComponent,
      { windowClass: "modal-lg-calendar", size: 'lg', keyboard: false }
    )
    modalRef.componentInstance.collaborator = collaborator
    modalRef.componentInstance.closeModal = () => modalRef.close()
  }

  createCollaborator() {
    const self = this
    const { skills, role, ...user } = this.form.value
    const { is_admin } = user
    const dataForm = {
      user: {
        ...user,
        is_admin: !is_admin ? false : is_admin
      },
      skills, role
    }

    this.collaboratorService.addCollaborators(dataForm, {
      fnSuccess(data) {
        const params = self.getParams()
        self.getCollaborators(params)
        self.toast.success("Colaborador adicionado com sucesso!")
        self.closeModal()
      },
      fnError(err) {
        console.warn(err)
        self.showErrorMessage(err.error, () => {
          self.toast.error("Erro na adição de colaborador")
        })
        self.closeModal()
        self.closeModal()
      }
    })
  }

  updateCollaborator(collaboratorId: string) {
    const self = this
    const { role, skills, ...user } = this.form.value
    let data: any = {}

    if (this.collaboratorCloned.role !== role) {
      data.role = role
    }

    if (!this.helpers.arrayEquals(this.collaboratorCloned.skills, skills)) {
      data.skills = skills
    }

    data = { user, ...data }

    this.collaboratorService.updateCollaborator(data, collaboratorId, {
      fnSuccess(data) {
        const params = self.getParams()
        self.getCollaborators(params)
        self.toast.success("Cliente atualizado com sucesso!")
        self.closeModal()
      },
      fnError(err) {
        console.warn(err)
        self.toast.error("Error na atualização do cliente!")
        self.closeModal()
      }
    })
  }

  deleteCollaborator(collaborator: Collaborator) {
    const self = this
    const modalRef = this.modalService.open(
      ModalConfirmComponent,
      { windowClass: "modal-confirm" }
    )

    modalRef.componentInstance.title = "Tem certeza que deseja excluir esse(s) cliente(s)?"
    modalRef.componentInstance.description = "Essa a ação não poderá ser desfeita."
    modalRef.componentInstance.btnTitle = "Excluir"
    modalRef.componentInstance.callbackConfirmation = () => {
      this.collaboratorService.deleteCollaborator(collaborator.uuid, {
        fnSuccess() {
          const params = self.getParams()
          self.getCollaborators(params)
          self.toast.success("Colaborador deletado com sucesso!")
        },
        fnError(err) {
          console.warn(err)
          self.toast.error("Erro ao deletar projeto")
        }
      })
      modalRef.close()
    }
  }

  save(collaborator?: Collaborator) {
    this.collaboratorSelected = collaborator
    let titleModal = "Adicionar novo Colaborador"

    if (collaborator) {
      this.getOneCollaborator(collaborator.uuid)
      titleModal = `Editar ${collaborator.first_name}`
    }

    this.showModal(titleModal)
  }

  changeDate(date: any, prop: "start" | "end") {
    this.date[prop] = date

    if (prop === "start") {
      if (this.date["start"] > this.date["end"]) {
        this.date["end"] = this.date["start"]
      }
    }

    this.params["start"] = this.date.start
    const params = this.setParam("end", this.date.end)

    this.getCollaborators(params)
  }

  getSkills() {
    const self = this

    this.skillsService.getSkills({
      fnSuccess(skills) {
        self.skills = skills.data.map((skill: any) => ({
          id: skill.uuid,
          text: skill.name
        }))
      },
      fnError(err) {
        console.warn(err)
      }
    })
  }

  getRoles() {
    const self = this

    this.rolesService.getRoles({
      fnSuccess(roles) {
        self.roles = roles.data.map((role: any) => ({
          label: role.name,
          value: role.id
        }))
      },
      fnError(err) {
        console.warn(err)
      }
    })
  }

  onSubmit() {
    if (!this.collaboratorSelected) this.createCollaborator()
    else this.updateCollaborator(this.collaboratorSelected.uuid)
  }

  closeModal() {
    this.modalCustomService.closeModal()
  }

  showErrorMessage(error: any, callbackError?: Function) {
    if (error.email) {
      if (error.email[0].includes("already exists")) {
        this.toast.error("Colaborador com email já cadastrado!")
      } else if (error.email[0].includes("not valid")) {
        this.toast.error("Cnpj inválido!")
      } else if (error.email[0].includes("Enter a valid")) {
        this.toast.error("Entre com um email válido!")
      } else {
        if (callbackError) callbackError()
      }
    } else {
      if (callbackError) callbackError()
    }
  }
}
