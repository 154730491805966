import { Component, OnInit, Input, Output, EventEmitter  } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-image-cutter',
  templateUrl: './image-cutter.component.html',
  styleUrls: ['./image-cutter.component.scss']
})
export class ImageCutterComponent implements OnInit {
	@Output() imageChange = new EventEmitter()
	@Input() image: string = '/assets/images/image-default.jpg'
	reload: boolean = true

	imageChangedEvent: any = ''
	croppedImage: any = ''
	openModalFile: boolean = false
	transform: ImageTransform = {}
	scale = 1

	constructor(private toast: ToastrService) {}

	ngOnInit(): void {}

	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event

		if (event.target.value) {
			this.openModalFile = true
		}

		this.getCroppedImageSize(event)
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64
	}

	getCroppedImageSize(event: any) {
		const sizeInBytes = event.target.files[0].size
		const sizeInKB = sizeInBytes / 1024;
		const maxSizeInMB = 2500 // 2.5mb
		
		if (sizeInKB > maxSizeInMB) {
			this.toast.error("Adicione uma imagem menor que 2.5MB", "Imagem mal otimizada")
			this.onCloseModalFile()
		}
	}

	confirmImage() {
		this.image = this.croppedImage
		this.imageChange.emit(this.image)
		this.openModalFile = false
	}

	loadImageFailed() {
		this.openModalFile = false
		this.onCloseModalFile()
		this.toast.error(
			'Os formatos de imagems aceitos são png | jpeg | bmp | webp | ico',
			'Formato inválido de imagem'
		)
	}

	onCloseModalFile() {
		this.openModalFile = false
		this.imageChangedEvent = ''
		this.croppedImage = ''
	}

	zoomOut() {
		if (this.scale > 1) {
			this.scale -= 0.1
			this.transform = {
				...this.transform,
				scale: this.scale,
			}
		}
	}

	zoomIn() {
		this.scale += 0.1
		this.transform = {
			...this.transform,
			scale: this.scale,
		}
	}
}
