import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms'

@Component({
    selector: 'app-controls-form-builder',
    templateUrl: './controls-form-builder.component.html',
    styleUrls: ['./controls-form-builder.component.scss']
})
export class ControlsFormBuilderComponent implements OnInit {

    @Input() prop: any
    @Input() name: string = ''
    @Input() form: FormGroup = new FormGroup({})
    @Output() emmitEvent: EventEmitter<any> = new EventEmitter()

    constructor() {
    }

    ngOnInit(): void {
    }

    onChange(value: string): void {
        this.emmitEvent.emit(value)
    }

    onCheckChange(event: any) {
        const formArray: FormArray = this.form.get(this.name) as FormArray

        if (event.target.checked) {
            formArray.push(new FormControl(event.target.value))
        } else {
            let i: number = 0

            formArray.controls.forEach((ctrl: AbstractControl) => {
                if (ctrl.value == event.target.value) {
                    formArray.removeAt(i)
                    return
                }
                i++
            })
        }
    }

}
