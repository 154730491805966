import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCustomComponent } from 'src/app/components/modals/modal-custom/modal-custom.component';

@Injectable({
  providedIn: 'root'
})
export class ModalCustomService {
  modalRef: any

  constructor(private modalService: NgbModal) { }

  showModal(dataModal: any): void {
    this.modalRef = this.modalService.open(ModalCustomComponent, { windowClass: dataModal.wrapperClass || "modal-service" });
    this.modalRef.componentInstance.content = dataModal.content;
    
    if(dataModal.title) this.modalRef.componentInstance.title = dataModal.title;
    if(dataModal.className) this.modalRef.componentInstance.className = dataModal.className;
    if(dataModal.closeBtn) this.modalRef.componentInstance.closeBtn = dataModal.closeBtn;
  }

  closeModal() {
    this.modalRef.close()
  }

  cancelModal(callback: Function) {
    this.modalRef.result.then(
      () => callback(),
      () => callback()
    )
  }
}
