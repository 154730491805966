import { Component, Input } from '@angular/core'

@Component({
	selector: 'app-loading-local',
	templateUrl: './loading-local.component.html',
	styleUrls: ['./loading-local.component.scss'],
})
export class LoadingLocalComponent {
	@Input() loading: any = false
}
