const api = 'https://cms-timesheet-back.valide.vc'

export const environment = {
    api: `${api}/api`,
    redirects: {
        roles: {
            admin: '/admin',
            public: '/timesheet',
        },
    },
    googleClientId: '305914845201-5l4via07fmlhapl11acr1g43bs63f83i.apps.googleusercontent.com',
    env: 'hml',
    production: false,
}
