import { Component, OnInit, Input } from '@angular/core';
import { Collaborator } from 'src/app/models/collaborator';
import { TimesheetModel } from 'src/app/models/timesheet';
import { TimesheetService } from 'src/app/services/timesheet/timesheet.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ToastrService } from 'ngx-toastr';
import { ModalConfirmComponent } from '../modals/modal-confirm/modal-confirm.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-calendar-collaborator',
  templateUrl: './calendar-collaborator.component.html',
  styleUrls: ['./calendar-collaborator.component.scss']
})
export class CalendarCollaboratorComponent implements OnInit {
  @Input() collaborator: Collaborator | any
  collaboratorTimesheet?: TimesheetModel[]
  timesheetsSelecteds: any
  loading: boolean = true
  effectTimeControl: boolean = false
  dateSelected: string = ""
  hours = {
    overtime: 0,
    normal: 0
  }
  dateCalendar = {
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear()
  }

  constructor(
    private timesheetService: TimesheetService,
    private modalService: NgbModal,
    public helpers: HelpersService,
    private toast: ToastrService
    ) { }

  ngOnInit(): void {
    this.loading = true
    this.getOneTimesheet(() => {
      this.loading = false
    })
  }

  getOneTimesheet(callback?: Function) {
    const self = this
    const month = this.dateCalendar.month >= 10 ? this.dateCalendar.month : `0${this.dateCalendar.month}`
    const dateParsed = `${this.dateCalendar.year}-${month}`
    const params = `start=${dateParsed}-01&end=${this.getEndDate()}`

    this.timesheetService.getOneTimesheet(this.collaborator.user.uuid, params, {
      fnSuccess(data) {
        const { timesheets, overtime, hours } = data
        self.hours.normal = hours
        self.hours.overtime = overtime
        self.collaboratorTimesheet = timesheets
      	if (callback) callback()
      }, fnError(err) {
      	if (callback) callback()
        console.warn("err", err)
      }
    })
  }

  getEndDate() {
    const month = this.dateCalendar.month >= 10 ? this.dateCalendar.month : `0${this.dateCalendar.month}`
    return `${this.dateCalendar.year}-${month}-${this.getLastDayOfTheMonth()}`
  }

  getLastDayOfTheMonth() {
    const day = new Date(this.dateCalendar.year, this.dateCalendar.month, 0).getDate()
    return day >= 10 ? day : `0${day}`;
  }

  handlerMonth(date: any) {
    const month = date.getMonth()
    const year = date.getFullYear()

    if(month !== this.dateCalendar.month || year !== this.dateCalendar.year) {
      this.effectTimeControl = false
      this.timesheetsSelecteds = undefined
      this.dateCalendar = { year, month }
      this.getOneTimesheet()
    }
  }

  onDelete(timesheetUuid: string) {
    const self = this
    const messageModal = "Tem certeza que deseja deletar esse apontamento?"

    const modalRef = this.modalService.open(
      ModalConfirmComponent,
      { windowClass: "modal-confirm" }
    )

    modalRef.componentInstance.title = messageModal      
    modalRef.componentInstance.callbackConfirmation = () => {
      this.timesheetService.deleteTimesheetByAdmin(timesheetUuid, {
        fnSuccess() {
          self.getOneTimesheet()
          self.timesheetsSelecteds = self.timesheetsSelecteds?.filter((timesheet: any) => {
            return timesheet.extendedProps.uuid !== timesheetUuid
          })
          self.toast.success("Horários removido com sucesso")
          modalRef.close()
        }, fnError(err) {
          modalRef.close()
          console.warn("err", err)
          self.toast.error("Erro ao remover horário")
        }
      })      
    }
  }

  setTimesheetSelected(timesheet: any) {
    const time = timesheet.date.split("-")
    const day = time[2]
    const month = this.helpers.searchingMonthByNumber(Number(time[1]) - 1)
    this.dateSelected = `${day} de ${month}:`
    this.timesheetsSelecteds = timesheet.timesheets
    this.showTimeControl()
  }

  showTimeControl() {
    if(!this.effectTimeControl) {
      this.effectTimeControl = false
      setTimeout(() => {
        this.effectTimeControl = true
      }, 400)
    }
  }
}
