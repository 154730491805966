import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { TimesheetComponent } from './pages/timesheet/timesheet.component'
import { LoginComponent } from './pages/login/login.component'
import { NoAuthGuard } from './guard/no-auth.guard'
import { AuthGuard } from './guard/auth.guard'
import { ClientsComponent } from './pages/admin/clients/clients.component'
import { AdminComponent } from './pages/admin/admin.component'
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component'
import { ClientProfileComponent } from './pages/admin/client-profile/client-profile.component'
import { ProjectsComponent } from './pages/admin/projects/projects.component'
import { CollaboratorsComponent } from './pages/admin/collaborators/collaborators.component'
import { ProjectProfileComponent } from './pages/admin/project-profile/project-profile.component'
import { CollaboratorsProfileComponent } from './pages/admin/collaborators-profile/collaborators-profile.component'
import { ReportsComponent } from './pages/admin/reports/reports.component'
import { NotesComponent } from './pages/admin/notes/notes.component'

const rolesPublic = ['public']
const rolesAdmin = ['admin']

const routes: Routes = [
	{
		path: '',
		redirectTo: '/login',
		pathMatch: 'full',
	},
	{
		path: 'login',
		component: LoginComponent,
		canActivate: [NoAuthGuard],
	},
	{
		path: 'timesheet',
		component: TimesheetComponent,
		canActivate: [AuthGuard],
		data: {
			title: 'Timesheet',
			roles: rolesPublic,
		},
	},
	{
		path: 'admin',
		redirectTo: '/admin/dashboard',
		pathMatch: 'full',
	},
	{
		path: 'admin',
		component: AdminComponent,
		data: {
			title: 'Dashboard',
		},
		children: [
			{
				path: 'dashboard',
				component: DashboardComponent,
				data: {
					title: 'Visão geral',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
			{
				path: 'clients',
				component: ClientsComponent,
				data: {
					title: 'Clientes',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
			{
				path: 'clients/:id',
				component: ClientProfileComponent,
				data: {
					title: 'Clientes',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
			{
				path: 'projects',
				component: ProjectsComponent,
				data: {
					title: 'Projetos',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
			{
				path: 'projects/:id',
				component: ProjectProfileComponent,
				data: {
					title: 'Projetos',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
			{
				path: 'collaborators',
				component: CollaboratorsComponent,
				data: {
					title: 'Colaboradores',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
			{
				path: 'collaborators/:id',
				component: CollaboratorsProfileComponent,
				data: {
					title: 'Colaboradores',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
			{
				path: 'reports',
				component: ReportsComponent,
				data: {
					title: 'Relatórios',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
			{
				path: 'notes',
				component: NotesComponent,
				data: {
					title: 'Anotações',
					roles: rolesAdmin,
				},
				canActivate: [AuthGuard],
			},
		],
	},
	{
		path: '404',
		component: NotFoundComponent,
		data: { title: 'Página não encontrada' },
	},
	{
		path: '**',
		redirectTo: '/404',
		data: { title: 'Página não encontrada' },
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
