import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks'
import { environment } from 'src/environments/environment'
import { finalize } from 'rxjs/operators'

@Injectable({
	providedIn: 'root',
})
export class NotesService {
	constructor(private http: HttpClient) {}

	updateNotes(data: any, clientId: string, callback: Callbacks) {
		this.http
			.patch<any>(`${environment.api}/admin/dayoff/${clientId}/`, data)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}

	deleteNote(uuid: string, callback: Callbacks) {
		this.http
			.delete<any>(`${environment.api}/admin/dayoff/${uuid}/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}
	getNotes(callback: Callbacks) {
		this.http
			.get<any>(`${environment.api}/dayoff/`)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}
	createNote(data: any, callback: Callbacks) {
		this.http
			.post<any>(`${environment.api}/admin/dayoff/`, data)
			.pipe(
				finalize(() => {
					if (callback.fnFinalized) callback.fnFinalized()
				})
			)
			.subscribe({
				next: (data) => {
					callback.fnSuccess(data)
				},
				error: (err) => {
					callback.fnError(err)
					console.warn(err)
				},
			})
	}
}
