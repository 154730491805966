<div
	class="form-check form-check-custom form-check-solid wrapper-form-group"
	[class]="'form-check-' + typeCheck"
>
	<div [formGroup]="form">
		<div class="form-check p-0">
			<input
				class="form-check-input"
				type="checkbox"
				[id]="'c-' + id || name"
				[attr.name]="name"
				[attr.disabled]="disabled ? true : null"
				[formControlName]="name"
				[setValue]="inputModel"
				[value]="value"
				(change)="fnChangeModel($event)"
			/>
			<label class="form-check-label" [for]="'c-' + id || name">
				{{ labelName }}
				<ng-content></ng-content>
			</label>
		</div>
	</div>
</div>
