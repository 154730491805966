<div class="main-team-workers">
    <h4 class="title"> Projeto </h4>

    <div class="row">
        <div class="col-4">
            <div class="projects">
                <ng-select2 
                    placeholder="Projetos"
                    id="projects"
                    [data]="projects"
                    [(ngModel)]="project"
                    [class.disabled]="loading || !client"
                    (ngModelChange)="getDashboardByProject($event)"
                ></ng-select2>

                <div class="loading-local" *ngIf="loading">
                    <app-loading-local [loading]="loading"></app-loading-local>
                </div>
            </div>
        </div>
    </div>

    <div class="main-content">
        <div class="wrapper-chart" *ngIf="!loadingProject">
            <div *ngIf="project && validationsProject">
                <div class="summary">
                    <h4> Horas contratadas: <strong>{{ totalHours.total || 0 }}h </strong> </h4>
                    <h4> Horas utilizadas: <strong>{{ totalHours.used || 0 }}h </strong> </h4>
                </div>

                <app-chart
                    [data]="dahsboardTeam"
                    [options]="plugins"
                ></app-chart>
            </div>

            <h3 class="text-center" *ngIf="!project"> Selecione um projeto para a busca de informações do time! </h3>
            <h3 class="text-center" *ngIf="project && !validationsProject"> 
                Time sem informações!
            </h3>
        </div>

        <div class="loading">
            <app-loading-local [loading]="loadingProject"></app-loading-local>
        </div>
    </div>
</div>
