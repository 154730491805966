import { Select2OptionData } from "ng-select2"

const months: Array<Select2OptionData> = [
    {
        id: "1",
        text: "Janeiro"
    },
    {
        id: "2",
        text: "Fevereiro"
    },
    {
        id: "3",
        text: "Março"
    },
    {
        id: "4",
        text: "Abril"
    },
    {
        id: "5",
        text: "Maio"
    },
    {
        id: "6",
        text: "Junho"
    },
    {
        id: "7",
        text: "Julho"
    },
    {
        id: "8",
        text: "Agosto"
    },
    {
        id: "9",
        text: "Setembro"
    },
    {
        id: "10",
        text: "Outubro"
    },
    {
        id: "11",
        text: "Novembro"
    },
    {
        id: "12",
        text: "Dezembro"
    },
]

export default months