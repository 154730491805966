const tabsTimesheet = [
    {
        label: "Folha de ponto",
        className: ""
    },
    {
        label: "Planilha de horas",
        className: ""
    },
]

export default tabsTimesheet
