<div class="main-clients container">
    <app-table-header 
        title="Clientes" 
        (callbackAdd)="save()"
    ></app-table-header>

    <div class="main-clients-body">
        <div class="wrapper-table-search">
            <div class="mb-5">
                <div class="wrapper-search">
                    <i class="bi bi-search search"></i>
                    <input 
                        type="text" 
                        class="w-100 input-search" 
                        (keyup)="searchClient($event)"
                        placeholder="Pesquisar cliente"
                    />
                </div>
            </div>
            <app-table-list 
                [listHeader]='clientHeader' 
                [listBody]='listFilters' 
                (emmitChangePagination)="changePagination($event)" 
                [total]="total"
                [loading]="loadingTable"
                [page]="page"
            >
                <ng-template let-item #body>
                    <td>
                        <span (click)="save(item)" class="cursor-pointer d-inline">
                            {{ item.name }}
                        </span>
                    </td>
                    <td>
                        {{ helpersService.getDate(item.created_at) }}
                    </td>
                    <td>
                        {{ item.cnpj ? helpersService.addMaskCnpj(item.cnpj) : '00.000.000/0000-00' }}
                    </td>
                    <td>
                        <div class="wrapper-icons">
                            <i class="bi bi-eye" [routerLink]="'/admin/clients/' + item.uuid"></i>
                            <i class="bi bi-pencil" (click)="save(item)"></i>
                            <i class="bi bi-trash" (click)="deleteClient(item)"></i>
                        </div>
                    </td>
                </ng-template>
            </app-table-list>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #modalCustom>
    <div class="row">
        <div class="col-lg-6">
            <app-image-cutter
                (imageChange)="setAvatar($event)"
                [image]="form.value.avatar ? form.value.avatar : '/assets/images/image-default.jpg'"
            ></app-image-cutter>
        </div>
        
        <div class="col-lg-6">
            <app-input 
                [form]="form" 
                name="name" 
                id="name"
                labelName="Nome do cliente*" 
                placeholder="Adicione o nome do cliente"
                [inputModel]="form.value.name"
            ></app-input>

            <app-input 
                [form]="form" 
                name="cnpj" 
                labelName="CNPJ*"
                id="cnpj"
                placeholder="Adicione o cnpj do cliente"
                inputMask="00.000.000/0000-00"
                [inputModel]="form.value.cnpj"
            ></app-input>

            <app-input 
                [form]="form" 
                name="razaoSocial" 
                id="razaoSocial"
                labelName="Razão social" 
                placeholder="Adicione a razão social do cliente"
                [inputModel]="form.value.razaoSocial"
            ></app-input>
        </div>
    </div>

    <div class="wrapper-buttons col-12 d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-cancel btn-secondary " (click)="closeModal()">
            Cancelar
        </button>

        <button 
            type="button" 
            class="btn-save ms-3 btn-default" 
            [class.btn-disabled]="form.invalid"
            (click)="onSubmit()"
        >
            Salvar
        </button>
    </div>
</ng-template>
