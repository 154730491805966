<footer>
    <div class="footer-container container">
        <div class="row footer-wrapper">
            <div class="footer-social col-lg-10">
                <a href="https://catskillet.com/" target="_blank" class="button-footer btn btn-white visit-website">
                    <figure>
                        <img src="/assets/images/temp/catskillet.svg" alt="catskillet" title="catskillet">
                    </figure>
                    Visite nosso site
                </a>
                <div class="button-list d-flex">
                    <a href="https://catskillet.slack.com/" target="_blank" class="button-footer btn btn-white me-4">
                        <figure>
                            <img src="/assets/images/temp/slack.svg" alt="slack" title="slack">
                        </figure>
                    </a>
                    <a 
                        href="mailto:contact@catskillet.com" 
                        target="_blank" 
                        class="button-footer btn btn-white"
                    >
                        <figure>
                            <img src="/assets/images/temp/icon-gmail.svg" alt="google" title="google">
                        </figure>
                    </a>
                </div>
                <ul class="m-0">
                    <li>
                        <a 
                            href="https://catskillet.com/#section-about" 
                            target="_blank"
                            class="text-light effect-hover"
                        >
                            QUEM SOMOS
                        </a>
                    </li>
                    <li>
                        <a href="https://catskillet.com/#section-our-services" target="_blank"
                            class="text-light effect-hover">
                            O QUE FAZEMOS
                        </a>
                    </li>
                    <li>
                        <a href="https://catskillet.com/#section-our-clients" target="_blank"
                            class="text-light effect-hover">
                            NOSSOS PROJETOS
                        </a>
                    </li>
                    <li>
                        <a href="https://catskillet.com/#section-where-are-we" target="_blank"
                            class="text-light effect-hover">
                            ONDE ESTAMOS
                        </a>
                    </li>
                </ul>
            </div>
            <div class="footer-contact col-lg-2">
                <p class="fw-bolder m-0 text-danger">Fale com a gente!</p>
                <a href="mailto:contact@catskillet.com" class="text-light effect-hover">
                    contact@catskillet.com
                </a>
                <a href="tel:+55 (85) 3045-4890" class="text-light effect-hover">
                    +55 (85) 3045-4890
                </a>
            </div>
        </div>
    </div>
</footer>
