import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isAdmin: boolean = false

  private interval: any
  currentDateTime: any

  constructor(
    private authService: AuthService,
    private helpersService: HelpersService
  ) { }

  ngOnInit(): void {
    this.getDateNow()

    this.interval = setInterval(() => {
      this.getDateNow()
    }, 1000)

    this.isAdmin = this.getRole()
  }

  ngOnDestroy(): void {
    clearInterval(this.interval)
  }

  getRole(): boolean {
    const roles = this.authService.getRole()
    return roles.includes("admin")
  }

  logout() {
    this.authService.logout().subscribe(() => {
      //@ts-ignore
      window.location.href = '/'
    })
  }

  getDateNow() {
    const date: any = new Date()

    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()
    const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
    const hours = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()

    const monthInPortuguese = this.helpersService.searchingMonthByNumber(month)
    this.currentDateTime = `${day} de ${monthInPortuguese} de ${year} - ${hours}:${minutes}`
  }
}
