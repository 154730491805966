import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { clientDefault } from '../../../mocks/default-form'
import { Client } from '../../../models/client'
import { ModalConfirmComponent } from '../../../components/modals/modal-confirm/modal-confirm.component'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { HelpersService } from '../../../services/helpers/helpers.service'
import { ToastrService } from 'ngx-toastr'
import { ModalCustomService } from '../../../services/modal/modal-service.service'
import { ListHeader } from '../../../models/list-header'
import { ControlSelectOptions } from '../../../models/control-select-options'
import { notesHeader } from '../../../mocks/notes'
import { NotesService } from '../../../services/notes/notes.service'

@Component({
	selector: 'app-notes',
	templateUrl: './notes.component.html',
	styleUrls: ['./notes.component.scss'],
})
export class NotesComponent implements OnInit {
	@ViewChild('modalCustom', { static: false }) modalCustom?: TemplateRef<any>
	clientHeader: ListHeader[] = notesHeader
	clients: Client[] = []
	listFilters: Client[] = []
	form: FormGroup | any
	clientSelected?: any
	total: number = 0
	searchTime: any
	params: any = {}
	page: number = 1
	loadingTable: boolean = false
	optionsTypes: ControlSelectOptions[] = []

	constructor(
		private notesService: NotesService,
		private modalService: NgbModal,
		public helpersService: HelpersService,
		private toast: ToastrService,
		private formBuilder: FormBuilder,
		private modalCustomService: ModalCustomService
	) {
		this.form = this.formBuilder.group({
			date: ['', Validators.required],
			reason: ['', Validators.required],
			half_day: [false],
		})
	}
	ngOnInit(): void {
		this.getNotes()

		this.optionsTypes = [
			{ value: 'holiday', label: 'Feriado' },
			{
				value: 'with_employees',
				label: 'Acordado com Funcionarios',
			},
			{ value: 'others', label: 'Outros' },
		]
	}
	// searchClient(event: any) {
	// 	clearTimeout(this.searchTime)
	// 	this.searchTime = setTimeout(() => {
	// 		const searchValue = event.target.value.trim()
	// 		const param: string = this.setParam('search', searchValue)
	// 		this.getNotes(param)
	// 	}, 350)
	// }
	getNotes(params: string = '') {
		const self = this
		this.loadingTable = true

		this.notesService.getNotes({
			fnSuccess({ data, count }) {
				self.total = count
				self.clients = data
				self.listFilters = data
				self.loadingTable = false
			},
			fnError(err) {
				console.warn(err)
				self.loadingTable = false
			},
		})
	}
	changePagination(page: any) {
		this.page = page
		const param: string = this.setParam('page', page)
		this.getNotes(param)
	}
	fnParam(name?: string, value?: any): string {
		if (name) {
			if (name !== 'page') {
				this.params['page'] = 1
				this.page = 1
			}

			this.params[name] = value
		}

		return Object.entries(this.params)
			.map((param, index) => {
				const [query, value] = param
				const cancat = index === 0 ? '?' : '&'
				return `${cancat}${query}=${value}`
			})
			.join('')
	}
	getParams() {
		return this.fnParam()
	}
	setParam(name: string, value: any) {
		return this.fnParam(name, value)
	}
	showModal(title: string) {
		const data = {
			content: this.modalCustom,
			title,
		}

		this.modalCustomService.showModal(data)
		this.modalCustomService.cancelModal(() => {
			this.form?.reset({
				date: '',
				reason: '',
				half_day: false,
			})
		})
	}
	updateNote(clientId: string) {
		const self = this
		const data = this.helpersService.converterCamelCaseToSnake(
			this.form.value
		)

		this.notesService.updateNotes(data, clientId, {
			fnSuccess(data) {
				const params = self.getParams()
				self.getNotes(params)
				self.toast.success('Cliente atualizado com sucesso!')
				self.closeModal()
			},
			fnError(err) {
				console.warn(err)
				self.showErrorMessage(err.error, () => {
					self.toast.error('Erro na atualização do cliente!')
				})
				self.closeModal()
			},
		})
	}
	createNotes() {
		const self = this
		const data = this.helpersService.converterCamelCaseToSnake(
			this.form.value
		)

		this.notesService.createNote(data, {
			fnSuccess(data) {
				const params = self.getParams()
				self.getNotes(params)
				self.toast.success('Cliente criado com sucesso!')
				self.closeModal()
			},
			fnError(err) {
				console.warn(err)
				self.closeModal()
				self.showErrorMessage(err.error, () => {
					self.toast.error('Erro na criação do cliente!')
				})
			},
		})
	}
	deleteNote(client: Client) {
		const self = this

		const modalRef = this.modalService.open(ModalConfirmComponent, {
			windowClass: 'modal-confirm',
		})

		modalRef.componentInstance.title =
			'Tem certeza que deseja excluir esse(s) cliente(s)?'
		modalRef.componentInstance.btnTitle = 'Excluir'

		modalRef.componentInstance.callbackConfirmation = () => {
			this.notesService.deleteNote(client.uuid, {
				fnSuccess(data) {
					const params = self.getParams()
					self.getNotes(params)
					self.toast.success('Cliente deletado com sucesso!')
					modalRef.close()
				},
				fnError(err) {
					console.warn(err)
					self.toast.error('Erro ao deletar cliente!')
				},
			})
		}
	}
	save(data?: any) {
		this.clientSelected = data
		let titleModal = 'Adicionar nova data'

		if (data) {
			this.form?.setValue({
				date: data.date,
				reason: data.reason,
				half_day: data.half_day,
			})

			titleModal = `Editar data ${this.helpersService.newDate(data.date)}`
		}

		this.showModal(titleModal)
	}
	onSubmit() {
		if (!this.clientSelected) this.createNotes()
		else this.updateNote(this.clientSelected.uuid)
	}
	showErrorMessage(error: any, callbackError?: Function) {
		if (error.cnpj) {
			if (error.cnpj[0].includes('cnpj already')) {
				this.toast.error('Cliente com cnpj já cadastrado!')
			} else if (error.cnpj[0].includes('not valid')) {
				this.toast.error('Cnpj inválido!')
			} else {
				if (callbackError) callbackError()
			}
		} else {
			if (callbackError) callbackError()
		}
	}
	closeModal() {
		this.modalCustomService.closeModal()
	}
}
