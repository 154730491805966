import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'

@Component({
	selector: 'app-accordion',
	templateUrl: './accordion.component.html',
	styleUrls: ['./accordion.component.scss'],
})
export class AccordionComponent implements OnInit {
	@Output() emmitEventScrollTop: EventEmitter<any> = new EventEmitter()
	@Input() isOpen: boolean = false
	@Input() title: string = ''
	@Input() description: string = ''
	@Input() bgOdd: boolean = false

	constructor() {}

	ngOnInit(): void {}

	showContentFaq(): void {
		this.isOpen = !this.isOpen
	}

	scrollTop() {
		this.emmitEventScrollTop.emit('scroll')
	}
}
