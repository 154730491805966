import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	SimpleChanges,
} from '@angular/core'
import {
	CalendarOptions,
	DateSelectArg,
	EventClickArg,
} from '@fullcalendar/core'
import { TimesheetService } from 'src/app/services/timesheet/timesheet.service'

import fullCalendarBuilder from 'src/app/mocks/cadendar-options'

import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ModalTimesheetComponent } from '../modals/modal-timesheet/modal-timesheet.component'
import { HelpersService } from 'src/app/services/helpers/helpers.service'
import { TimesheetModel } from 'src/app/models/timesheet'
import { ToastrService } from 'ngx-toastr'
import { saveAs } from 'file-saver'
import * as moment from 'moment'
import { WorksheetService } from 'src/app/services/worksheet/worksheet.service'
import { User } from 'src/app/models/user'

import { Observable } from 'rxjs'
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/app-state'

import { ModalDetailsClockComponent } from '../modals/modal-details-clock/modal-details-clock.component'
import * as cluster from 'cluster'
import { NotesService } from '../../services/notes/notes.service'

@Component({
	selector: 'app-calendar',
	templateUrl: './calendar.component.html',
	styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
	private user$?: Observable<User>
	user?: User

	@Input() collaboratorAdmin: any
	@Input() hours = { overtime: 0, normal: 0 }

	@Output() handlerMonth: EventEmitter<any> = new EventEmitter()
	@Output() setContentDaySelected: EventEmitter<any> = new EventEmitter()

	worksheets: any
	timesheetsByDate: any = {}

	calendarOptions: CalendarOptions = {}
	totalHours: any[] = []

	dateSelected: any
	dateCalendar = {
		start: '',
		end: '',
	}

	timesheetDownload: any = {}
	reasonDays: any = [
		// {
		// 	uuid: '8daf06c5-58e2-4076-b7a1-d53f15320f7a',
		// 	date: '2023-10-13',
		// 	reason: 'holiday',
		// 	half_day: false,
		// },
		// {
		// 	uuid: '8daf06c5-58e2-4076-b7a1-d53f15320f7a',
		// 	date: '2023-10-16',
		// 	reason: 'with_employees',
		// 	half_day: false,
		// },
		// {
		// 	uuid: '8daf06c5-58e2-4076-b7a1-d53f15320f7a',
		// 	date: '2023-11-01',
		// 	reason: 'with_employees',
		// 	half_day: false,
		// },
	]
	isReasonDay: string = ''

	constructor(
		private timesheetService: TimesheetService,
		private toast: ToastrService,
		private modalService: NgbModal,
		public helpers: HelpersService,
		private worksheetService: WorksheetService,
		private store: Store<AppState>,
		private notesService: NotesService
	) {}

	ngOnInit(): void {
		if (!this.collaboratorAdmin) {
			this.user$ = this.store.select('user')
			this.user$.subscribe((state) => {
				this.user = state
			})
		}

		this.calendarOptions = fullCalendarBuilder({
			handlerClick: (data: any) => this.showModalUpdate(data),
			handlerNewEvent: (data: any) => this.showModalAdd(data),
			handlerMonth: (date: any) => this.handlerMonthCalendar(date),
			setContentCell: (data: any) => this.setContentCell(data),
		})
		this.getNotes()
	}

	ngAfterContentInit(): void {
		setTimeout(() => {
			this.showDetailsClockWorksheet()
		}, 1)
	}

	handlerMonthCalendar(date: any) {
		const currentMonth = new Date().getMonth() + 1
		const dateNotExist = !date.month && !date.year

		let numberMonth = dateNotExist ? date.getMonth() : date.month
		numberMonth = numberMonth < 1 ? 12 : numberMonth

		let previousMonth: any = numberMonth - 1 < 1 ? 12 : numberMonth - 1
		previousMonth = `${previousMonth < 10 ? '0' : ''}${previousMonth}`

		// const year = dateNotExist ? date.getFullYear() : date.year
		const month = `${numberMonth < 10 ? '0' : ''}${numberMonth}`
		const year = dateNotExist
			? Number(month) > 11
				? new Date().getFullYear()
				: date.getFullYear()
			: date.year

		let lastDayMonth: any = new Date(year, numberMonth, 0).getDate()
		lastDayMonth = `${lastDayMonth < 10 ? '0' : ''}${lastDayMonth}`

		const previousYear = previousMonth == 12 ? year - 1 : year
		let startDate = `${previousYear}-${previousMonth}-01`
		let endDate = `${year}-${month}-${lastDayMonth}`

		this.dateSelected = {
			month,
			year,
			lastDayMonth,
			startDayMonth: '01',
		}

		if (currentMonth !== numberMonth) {
			let nextMonth: any = Number(month) + 1
			nextMonth = `${nextMonth < 10 ? '0' : ''}${nextMonth}`

			startDate = `${previousYear}-${previousMonth}-01`
			endDate = `${year}-${nextMonth}-20`
		}

		this.dateCalendar = {
			start: startDate,
			end: endDate,
		}

		if (!this.collaboratorAdmin) {
			this.getWorksheets()
			this.getTimesheets()
		} else {
			this.getTimesheetByAdmin()
			this.getWorksheetPerAdmin()
		}
	}

	getTimesheets(date?: any) {
		const self = this

		this.timesheetService.getTimesheets(this.dateCalendar, {
			fnSuccess(data) {
				self.hours.normal = 0
				self.hours.overtime = 0

				data.timesheets.forEach((timesheet: any) => {
					const [day, month, year] = timesheet.date.split('-')
					if (month === self.dateSelected.month) {
						if (timesheet.overtime) {
							self.hours.overtime += Number(timesheet.hours)
						} else {
							self.hours.normal += Number(timesheet.hours)
						}
					}
				})

				self.setEventsCalendar(data.timesheets)
				self.getTimesheetsByMonthToDownload(data.timesheets)
			},
			fnError(err) {
				self.toast.error('Erro na busca dos eventos')
			},
		})
	}

	setEventsCalendar(timesheets: TimesheetModel[]) {
		this.timesheetsByDate = {}
		this.calendarOptions.events = []

		const events: any[] = []


		const result = [
			...new Set(timesheets.map((timesheet) => timesheet.date)),
		].map((val) => {
			return {
				date: val,
				hrsOvertime: timesheets.filter((d) => d.date === val && d.hours && d.overtime).map((x) => x.hours).reduce((acc, cur) => acc + cur, 0),
				hrsNormal: timesheets.filter((d) => d.date === val && d.overtime === false).map((x) => x.hours).reduce((acc, cur) => acc + cur, 0),
				hrstotal: timesheets.filter((d) => d.date === val).map((x) => x.hours).reduce((acc, cur) => acc + cur, 0),
			}
		})




		result.map((item: any) => {
            this.totalHours.push({
                date: item.date,
                hrsNormal: item.hrsNormal,
                hrsOvertime: item.hrsOvertime,
                hrsTotais: item.hrstotal
            })
		})



		if (timesheets.length) {
			timesheets.forEach((timesheet: TimesheetModel) => {
				events.push({
					title: `${timesheet.hours}h ${timesheet.project?.name}`,
					start: timesheet.date || new Date(),
					extendedProps: {
						hours: timesheet.hours,
						uuid: timesheet.uuid,
						overtime: timesheet.overtime,
						project: timesheet.project,
						function: timesheet.function,
						absence_reason: timesheet.absence_reason,
						absence_file: timesheet.absence_file,
					},
				})
			})

			// this.calendarOptions.events = [...events]
			this.updateTimesheetsByDay(events)
		}
	}

	getTimesheetByAdmin() {
		const self = this
		const params = `start=${this.dateCalendar.start}&end=${this.dateCalendar.end}`

		this.timesheetService.getOneTimesheet(
			this.collaboratorAdmin.uuid,
			params,
			{
				fnSuccess(data) {
					self.hours.normal = 0
					self.hours.overtime = 0

					data.timesheets.forEach((timesheet: any) => {
						const [day, month, year] = timesheet.date.split('-')
						if (month === self.dateSelected.month) {
							if (timesheet.overtime) {
								self.hours.overtime += Number(timesheet.hours)
							} else {
								self.hours.normal += Number(timesheet.hours)
							}
						}
					})

					self.setEventsCalendar(data.timesheets)
					self.getTimesheetsByMonthToDownload(data.timesheets)
				},
				fnError(err) {
					self.toast.error('Erro na busca dos eventos')
				},
			}
		)
	}

	getTimesheetsByMonthToDownload(timesheets: TimesheetModel[]) {
		if (timesheets.length) {
			const currentMonth = this.dateSelected.month

			const timesheetsFiltred = timesheets.filter((timesheet: any) => {
				const [day, month, year] = timesheet.date.split('-')
				return currentMonth === month
			})

			this.timesheetDownload = {
				timesheets: timesheetsFiltred,
			}
		}
	}

	getWorksheets() {
		const self = this

		if (
			this.user?.typeContract &&
			this.user?.typeContract.toLowerCase() === 'clt'
		) {
			this.worksheetService.getWorksheetsPerDate(this.dateCalendar, {
				fnSuccess(data) {
					self.worksheets = data
				},
				fnError(err) {
					console.warn('err', err)
				},
			})
		}
	}

	getWorksheetPerAdmin() {
		const self = this
		const params = `start=${this.dateCalendar.start}&end=${this.dateCalendar.end}`

		if (this.collaboratorAdmin.type_contract !== 'PJ') {
			this.worksheetService.getWorksheetPerAdmin(
				this.collaboratorAdmin.uuid,
				params,
				{
					fnSuccess(data) {
						self.worksheets = data
					},
					fnError(err) {
						console.warn('err', err)
					},
				}
			)
		}
	}

	setContentCell(data: any) {
		const dayNumber = document.createElement('h4')
		dayNumber.classList.add('custom-calendar-day')
		dayNumber.textContent = data.dayNumberText
		let status = 'danger'

		const iconClock = document.createElement('i')
		const div = document.createElement('div')

		const day = new Date(data.date).getDate()
		const month = new Date(data.date).getMonth() + 1
		const date = {
			day: `${day < 10 ? '0' : ''}${day}`,
			month: `${month < 10 ? '0' : ''}${month}`,
			year: new Date(data.date).getFullYear(),
		}

		const formattedDate = `${date.year}-${date.month}-${date.day}`




        const hrs = document.createElement('div')
		hrs.classList.add('custom-calendar-hrs')
		this.totalHours.map((val: any) => {
            if(formattedDate === val.date){
                hrs.innerHTML = `
                <span class = "box-hrs">
                    <h4 class = "hrs-normal" >${val.hrsNormal}h Normal</h4>
                    <h4 class = "hrs-overtime" >${val.hrsOvertime}h Extras</h4>
                </span>

                    <span class = "box-totalhrs" >
                        <p class = "hrs-totais"><strong>Total: ${val.hrsTotais}h</strong></p>
                    </span>`
            }
        })

		const reason = this.reasonDays.find(
			(day: any) => day.date === formattedDate
		)

		if (this.worksheets && !this.collaboratorAdmin) {
			const worksheet = this.worksheets.find(
				(worksheet: any) => worksheet.date === formattedDate
			)
			// if (worksheet) {
			// 	const completedWorksheet =
			// 		worksheet.start_day &&
			// 		worksheet.return_lunch &&
			// 		worksheet.out_lunch &&
			// 		worksheet.end_day
			// 	status = completedWorksheet ? 'success' : 'warning'
			// }

			// iconClock.setAttribute(
			// 	'class',
			// 	`bi bi-clock calendar-icon-clock text-${status}`
			// )
		} else {
			iconClock.style.display = 'none'
		}

		div.setAttribute('class', `reason ${reason ? 'holiday' : ''}`)

		const arrayOfDomNodes = [dayNumber, iconClock, div, hrs]
		return { domNodes: arrayOfDomNodes }
	}

	showModalUpdate(data: EventClickArg) {
		const { _instance }: any = data.event
		const { range } = _instance

		const date = moment(range.end).format('YYYY-MM-DD')
		this.showModalContent(date)
	}

	showModalAdd(event: any) {
		const date = moment(event.start).format('YYYY-MM-DD')
		const reason = this.reasonDays.find((day: any) => day.date === date)
		if (reason) {
			this.isReasonDay = reason.reason
		}

		this.showModalContent(date)
	}

	showModalContent(date: string) {
		if (this.collaboratorAdmin) {
			const worksheet =
				this.worksheets && this.worksheets.length
					? this.worksheets.find(
							(worksheet: any) => worksheet.date === date
					  )
					: null
			const timesheets = this.timesheetsByDate[date]

			this.setContentDaySelected.emit({
				timesheets: timesheets && timesheets.length ? timesheets : [],
				worksheet,
				date,
			})
		} else {
			const modalRef = this.modalService.open(ModalTimesheetComponent, {
				windowClass: 'modal-timesheet',
				keyboard: false,
				backdrop: 'static',
			})

			const weekday: any = moment(date).format('dddd')

			modalRef.result.then(() => {
				this.getTimesheets()
				this.getWorksheets()
				this.isReasonDay = ''
			})

			modalRef.componentInstance.date = date
			modalRef.componentInstance.weekday = weekday
			modalRef.componentInstance.user = this.user
			modalRef.componentInstance.timesheets = this.timesheetsByDate[date]

			modalRef.componentInstance.callbackConfirmation = () => {
				this.getTimesheets()
				setTimeout(() => {
					modalRef.componentInstance.setTimeSheat(
						this.timesheetsByDate[date]
					)
				}, 500)
			}
			if (this.isReasonDay) {
				modalRef.componentInstance.typeReasonDay = this.isReasonDay
			}
		}
	}

	updateTimesheetsByDay(events: any) {
		events.forEach((event: any) => {
			if (!this.timesheetsByDate[event.start]) {
				this.timesheetsByDate[event.start] = [event]
			} else {
				this.timesheetsByDate[event.start].push(event)
			}
		})
	}

	showDetailsClockWorksheet() {
		const detailsClock: any = document.querySelector(
			'.details-clock-worksheet'
		)

		if (detailsClock) {
			if (
				this.user?.typeContract &&
				this.user?.typeContract.toLowerCase() === 'clt'
			) {
				detailsClock.addEventListener('click', () => {
					this.showDetailsClock()
				})
			} else {
				detailsClock.style.display = 'none'
			}
		}
	}

	showDetailsClock() {
		const options: any = { windowClass: 'modal-confirm' }

		if (this.collaboratorAdmin) {
			options.container = '#main-modal-lg'
		}

		this.modalService.open(ModalDetailsClockComponent, options)
	}

	downloadTimesheet() {
		const self = this

		if (!this.timesheetDownload.timesheets.length) {
			this.toast.warning(
				'Para a realização do download do histórico, é necessário que pelo menos um timesheet seja adicionado.'
			)
			return
		}

		this.timesheetService.downloadTimesheet(this.timesheetDownload, {
			fnSuccess(csv) {
				const blob = new Blob([csv], {
					type: 'application/octet-stream',
				})
				const numberMonth: number = Number(self.dateSelected.month)
				const month = self.helpers.searchingMonthByNumber(
					numberMonth > 1 ? numberMonth - 1 : numberMonth
				)
				saveAs(blob, `controle-de-horas-${month.toLowerCase()}.csv`)
			},
			fnError(err) {
				console.warn('Erro no export', err)
			},
		})
	}

	downloadWorksheet() {
		const self = this

		const { month, year, lastDayMonth, startDayMonth } = this.dateSelected

		const startDay = `${year}-${month}-${startDayMonth}`
		const endDay = `${year}-${month}-${lastDayMonth}`

		const params = `?start=${startDay}&end=${endDay}`

		this.worksheetService.downloadWorksheet(params, {
			fnSuccess(csv) {
				const blob = new Blob([csv], {
					type: 'application/octet-stream',
				})
				const numberMonth: number = Number(self.dateSelected.month)
				const month = self.helpers.searchingMonthByNumber(
					numberMonth > 1 ? numberMonth - 1 : numberMonth
				)
				saveAs(blob, `folha-de-ponto-${month.toLowerCase()}.xlsx`)
			},
			fnError(err) {
				console.warn('err', err)
			},
		})
	}

	getNotes() {
		const self = this

		this.notesService.getNotes({
			fnSuccess({ data }) {
				self.reasonDays = data
			},
			fnError(err) {
				console.warn(err)
			},
		})
	}
}
