import { CalendarOptions, DateSelectArg, EventClickArg } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import { callbackCalendarSettings } from '../models/callbackCalendarSettings'
import ptbrLocale from "@fullcalendar/core/locales/pt-br"
import * as moment from 'moment'

const calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin],
    customButtons: {
        detailsClockWorksheet: {
            icon: "bi bi-clock details-clock-worksheet",
        }
    },
    headerToolbar: {
        left: 'prev,next today',
        center: 'title',
        right: 'detailsClockWorksheet'
    },
    locales: [ptbrLocale],
    locale: 'pt-br',
    timeZone: "local",
    initialView: 'dayGridMonth',
    weekends: true,
    dayMaxEvents: 2,
    height: "100vh",
    selectable: true
}

function fullCalendarBuilder({
    handlerClick,
    handlerNewEvent,
    handlerMonth,
    setContentCell
}: callbackCalendarSettings) {
    return {
        ...calendarOptions,
        select(selectInfo: DateSelectArg) {
            const currentDate: Date | any = new Date()
            const selectDate: Date | any = new Date(selectInfo.start)

            const dateInSeconds = moment().add(selectDate, "second")
            const currentDateInSeconds = moment().add(currentDate, "second")

            if (dateInSeconds < currentDateInSeconds) {
                handlerNewEvent(selectInfo)
            }
        },
        eventClick(evt: EventClickArg) {
            handlerClick(evt)
        },
        datesSet: (data: any) => {
            const date = new Date(data.end)
            handlerMonth(date)
        },
        dayCellContent: (arg: any) => {
            return setContentCell(arg)
        }
    }
}

export default fullCalendarBuilder
