import {Component} from '@angular/core';
import {AppState} from "./state/app-state";
import {Store} from "@ngrx/store";
import {Observable} from "rxjs";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    private loading$?: Observable<boolean>
    loading?: boolean

    constructor(
        private store: Store<AppState>,
    ) {
        this.loading$ = this.store.select('loading')

        this.loading$.subscribe((state) => {
            this.loading = state
        })
    }

    ngOnInit(): void {
        this.focusInShowSelect()
    }

    focusInShowSelect() {
        $(document).on('select2:open', function(e) {
            const selectId = e.target.id
            
            $(".select2-search__field[aria-controls='select2-" + selectId + "-results']")
                .each((_, value) => {
                    value.focus();
                })
        });
    }
}
