<div class="main-collaborator container">
    <app-table-header title="Relatórios" [additional]="false"></app-table-header>

    <div class="main-collaborator-body">
        <div class="wrapper-table-search">
            <div class="d-flex mb-5 justify-content-between align-items-center">
                <div class="d-flex gap-10">
                    <div class="d-flex gap-5 align-items-center">
                        <h4> Filtro de horas: </h4>

                        <div class="d-flex gap-4 align-items-center">
                            <input
                                type="date"
                                class="input-date form-group"
                                [max]="currentMonth.end"
                                [(ngModel)]="date.start"
                                (ngModelChange)="changeDate($event, 'start')"
                            >
                            <span> Até </span>
                            <input
                                type="date"
                                class="input-date form-group"
                                [max]="currentMonth.end"
                                [min]="date.start"
                                [(ngModel)]="date.end"
                                (ngModelChange)="changeDate($event, 'end')"
                            />
                        </div>
                    </div>

                    <div class="mw-4 d-flex gap-5 align-items-center">
                        <h4> Status: </h4>

                        <div class="status-reports">
                            <ng-select2
                                [data]="status"
                                placeholder="Status"
                                [id]="'status'"
                                [(ngModel)]="statusSelected"
                                (ngModelChange)="filterByStatus($event)"
                            ></ng-select2>
                        </div>
                    </div>

                </div>

                <button class="btn-clear-filter badge badge-light-primary" *ngIf="clearFilterShow" (click)="clearFilters()">
                    Limpar filtros
                </button>
            </div>

            <div class="mw-4 my-4 d-flex gap-5 align-items-center">
                <h4> Clients: </h4>

                <div class="wrapper-loading">
                    <ng-select2
                        *ngIf="!loadingSelectClient"
                        [data]="clients"
                        [(ngModel)]="clientSelected"
                        (ngModelChange)="changeSearchClientsParam($event)"
                        [id]="'clients'"
                        placeholder="Clientes"
                    ></ng-select2>

                    <app-loading-local [loading]="loadingSelectClient"></app-loading-local>
                </div>

                <h4> Projects: </h4>

                <div class="wrapper-loading">
                    <ng-select2
                        *ngIf="!loadingSelectProject"
                        [data]="projects"
                        [(ngModel)]="projectSelected"
                        (ngModelChange)="changeSearchProjectsParam($event)"
                        [id]="'projects'"
                        placeholder="Projetos"
                        [disabled]="selectDisable"
                    ></ng-select2>

                    <app-loading-local [loading]="loadingSelectProject"></app-loading-local>
                </div>
            </div>

            <div class="mb-5">
                <div class="wrapper-search">
                    <i class="bi bi-search search"></i>
                    <input
                        type="text"
                        class="w-100 input-search"
                        [(ngModel)]="collaboratorSearched"
                        (ngModelChange)="searchCollaborator($event)"
                        placeholder="Pesquisar colaborador "
                    />
                </div>
            </div>

            <div class="wrapper-content">
                <div class="table-reports">
                    <app-table-list
                        [listHeader]='reportsHeader'
                        [listBody]='collaborators'
                        (emmitChangePagination)="changePagination($event)"
                        [total]="total"
                        [loading]="loadingTable"
                        [page]="page"
                    >
                        <ng-template let-item #body>
                            <td>
                                <a [routerLink]="'/admin/collaborators/' + item.uuid" [queryParams]="{ start: date.start, end: date.end }">
                                    {{ item.first_name }} {{ item.last_name }}
                                </a>
                            </td>
                            <td class="text-center">
                                <span class="badge-status" [class]="item.is_active ? 'success' : 'danger'">
                                    {{ item.is_active ? "Ativo" : "Inativo" }}
                                </span>
                            </td>
                            <td class="text-center">
                                <strong class="hours" *ngIf="!loadingHours">
                                    {{ item.timesheet.hours  }}h
                                </strong>
                                <app-loading-local class="loading-hours" [loading]="loadingHours"></app-loading-local>
                            </td>
                            <td class="text-center">
                                <strong class="hours" *ngIf="!loadingHours">
                                    {{item.timesheet.overtime }}h
                                </strong>
                                <app-loading-local class="loading-hours" [loading]="loadingHours"></app-loading-local>
                            </td>
                            <td class="text-center">
                                <strong class="hours" *ngIf="!loadingHours">
                                    {{ item.timesheet.hours + item.timesheet.overtime }}h
                                </strong>
                                <app-loading-local class="loading-hours" [loading]="loadingHours"></app-loading-local>
                            </td>
                            <!-- <td style="width: 400px;">
                                <div class="clockwork" #tableClockwork>
                                    <table class="table-clock">
                                        <thead>
                                            <tr>
                                                <th
                                                    *ngFor="let data of dataControl[item.uuid]"
                                                    [class.item-weekend]="isWeekend(data.abbreviation)"
                                                >
                                                    <div class="d-flex px-5 flex-column align-items-center">
                                                        <span> {{ data.month }} </span>
                                                        <span> {{ data.day }} </span>
                                                        <span> {{ data.abbreviation }} </span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td
                                                    *ngFor="let data of dataControl[item.uuid]" class="text-center py-3"
                                                    [class.item-weekend]="isWeekend(data.abbreviation)"
                                                >
                                                    {{ data.totalHours }}h
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </td> -->
                            <td class="text-center">
                                <button class="btn-bg-white p-0" title="exportar" (click)="downloadTimsheeetColaborator(item)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-download" viewBox="0 0 16 16">
                                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"
                                        />
                                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"
                                        />
                                    </svg>
                                </button>
                            </td>
                        </ng-template>
                    </app-table-list>
                </div>
            </div>
        </div>
    </div>
</div>
