<div class="main-dashboard container">
    <div class="main-dashboard-header d-flex justify-content-between mb-5">
        <h3 class="title-content"> Visão Geral </h3>
        <div class="d-flex align-items-center flex-row">
            <button class="btn-bg-white" title="download" (click)="downloadOverview()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
                <span class="ms-2">Exportar</span>
            </button>
        </div>
    </div> 

    <div class="row">
        <app-card-statistics 
            class="col-4"
            icon="/assets/images/temp/person.svg"
            description="Total de funcionários ativos"
            [total]="topContent.workers"
        ></app-card-statistics>
        <app-card-statistics 
            class="col-4"
            icon="/assets/images/temp/file-text.svg"
            description="Total de projetos"
            [total]="topContent.projects"
        ></app-card-statistics>
        <app-card-statistics 
            class="col-4"
            icon="/assets/images/temp/project.svg"
            description="Total de clientes"
            [total]="topContent.clients"
        ></app-card-statistics>
    </div>

    <div class="main-dashboard-projects row">
        <div class="col-lg-6 main-card">
            <app-clients-chart 
                [dashboardExport]="dashboardExport"
                (changeClient)="changeClient($event)"
            ></app-clients-chart>
        </div>
        <div class="col-lg-6 main-card">
            <app-projects-chart 
                [dashboardExport]="dashboardExport"
                [client]="client"
            ></app-projects-chart>
        </div>
    </div>

    <div class="main-dashboard-user main-card">
        <app-collaborator-chart [dashboardExport]="dashboardExport"></app-collaborator-chart>
    </div>
</div> 
