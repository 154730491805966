import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormGroup } from '@angular/forms'

@Component({
    selector: 'app-input',
    templateUrl: './input.component.html',
    styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
    public showPass: boolean = false

    @Input() form: FormGroup = new FormGroup(Input())
    @Input() id?: string
    @Input() name: string | number | null = ''
    @Input() placeholder: string = ''
    @Input() typeInput?: string
    @Input() labelName: string = ''
    @Input() callout: string = ''
    @Input() inputModel: any
    @Input() disabled: boolean = false
    @Input() readonly: boolean = false
    @Input() inputMask: string = ''
    @Output() emmitEvent: EventEmitter<any> = new EventEmitter()

    constructor() {
    }

    ngOnInit(): void {
    }

    toggleInputPass(): void {
        this.showPass = !this.showPass
    }

    onChange(event: any, value: string): void {
        if (event.keyCode !== 8 && event.keyCode !== 46)
            this.emmitEvent.emit(value)
    }
}
