import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ViewChild, TemplateRef } from '@angular/core';
import { HelpersService } from 'src/app/services/helpers/helpers.service';

import * as moment from 'moment'
import { Moment } from 'moment';
import { ModalCustomService } from 'src/app/services/modal/modal-service.service';

@Component({
  selector: 'app-calendar-mobile',
  templateUrl: './calendar-mobile.component.html',
  styleUrls: ['./calendar-mobile.component.scss']
})
export class CalendarMobileComponent implements OnInit {
  @ViewChild('modalHours', { static: false }) modalHours?: TemplateRef<any>

  @Input() timesheetsByDate: any = {}
  @Input() worksheets: any
  @Input() usertypeContract: string | undefined = ""
  @Input() hours: any = { overtime: 0, normal: 0 }

  @Output() showModal: EventEmitter<any> = new EventEmitter()
  @Output() handlerMonth: EventEmitter<any> = new EventEmitter()
  @Output() showDetailsClock: EventEmitter<any> = new EventEmitter()
  @Output() download: EventEmitter<any> = new EventEmitter()
  @Output() downloadWorksheetFn: EventEmitter<any> = new EventEmitter()

  timesheets: any
  currentWorksheet: any
  validDayAdd: boolean = true
  date: string = moment().format("YYYY-MM-DD")

  // dates
  day: number = moment().date()
  weekday: number = moment().day()
  month: number = moment().month()
  year: number = moment().year()

  constructor(
    public helpers: HelpersService,
    private modalCustomService: ModalCustomService
  ) { }

  ngOnInit(): void {
    this.getCurrentDate()
  }

  getWorksheetByDate(date: string) {
    const worksheet = this.worksheets.find((worksheet: any) => worksheet.date === date)
    let status = "danger"

    if (worksheet) {
      status = worksheet.start_day && worksheet.return_lunch && worksheet.out_lunch && worksheet.end_day ? "success" : "warning"
    }

    this.currentWorksheet = {
      ...worksheet, status
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["timesheetsByDate"] && changes["timesheetsByDate"].currentValue) {
      this.timesheetsByDate = changes["timesheetsByDate"].currentValue
      this.getCurrentDate()
    }
  }

  getCurrentDate() {
    this.timesheets = this.timesheetsByDate[this.date]

    if (this.usertypeContract && this.usertypeContract === "CLT" && this.worksheets && this.worksheets.length) {
      this.getWorksheetByDate(this.date)
    }
  }

  previousDay() {
    let yesterday = moment(this.date).add(-1, 'days')
    this.updateDates(yesterday)
  }

  nextDay() {
    let tomorrow = moment(this.date).add(1, 'days')
    this.updateDates(tomorrow)
  }

  updateDates(date: Moment) {
    this.date = date.format("YYYY-MM-DD");

    const month = date.month()
    const year = date.year()
    const day = date.date()

    if (month !== this.month || year !== this.year) {
      this.handlerMonth.emit({ month: month + 1, year })
    }

    this.day = day
    this.weekday = date.day()
    this.month = month
    this.year = year

    this.getCurrentDate()
    this.setValidDayToAdd()
  }

  setValidDayToAdd() {
    const currentDate: any = new Date()
    const selectDate: any = new Date(this.date)

    const dateInSeconds = moment().add(selectDate, "second")
    const currentDateInSeconds = moment().add(currentDate, "second")

    if (dateInSeconds > currentDateInSeconds) {
      this.validDayAdd = false
    } else {
      this.validDayAdd = true
    }
  }

  addNewTimesheet() {
    this.showModal.emit(this.date)
  }

  getWeekend(weekday: number) {
    const day = this.helpers.getWeekday(weekday).toLowerCase()
    return day === "domingo" || day === "sábado"
  }

  showModalhours() {
    this.modalCustomService.showModal({
      content: this.modalHours,
      wrapperClass: "modal-summary-calendar"
    })
  }

  showDetailsClockEmit() {
    this.showDetailsClock.emit()
  }

  downloadTimesheet() {
    this.download.emit()
    this.modalCustomService.closeModal()
  }

  downloadWorksheet() {
    this.downloadWorksheetFn.emit()
    this.modalCustomService.closeModal()
  }
}
