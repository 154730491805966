import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-details-clock',
  templateUrl: './modal-details-clock.component.html',
  styleUrls: ['./modal-details-clock.component.scss']
})
export class ModalDetailsClockComponent implements OnInit {
  clockStatus = [
    {
      description: "Completo",
      status: "success"
    },
    {
      description: "Informações pendentes",
      status: "warning"
    },
    {
      description: "Não iniciado",
      status: "danger"
    },
  ]

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void { }
}
