import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(
    private http: HttpClient,
  ) { }

  getRoles(callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/admin/roles/`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
        }
      })
  }

  getRoleById(roleId: number, callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/admin/roles/${roleId}/`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
        }
      })
  }
}
