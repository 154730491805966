import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { Callbacks } from 'src/app/models/callbacks';
import { environment } from 'src/environments/environment';
import { finalize } from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient,
  ) { }

  getDashboardByProject(project_id: string, callback: Callbacks, date?: { start?: string, end?: string }) {
    let url = `${environment.api}/admin/dashboard/project/${project_id}/`

    if(date?.start) {
      url += `?start=${date?.start}`
    }

    if(date?.end) {
      const query = date?.start ? "&" : "?"
      url += `${query}end=${date?.end}`
    }

    this.http
      .get<any>(url).pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
        }
      })
  }

  getDashboardByClient(client_id: string, callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/admin/dashboard/client/${client_id}/`)
        .pipe(
          finalize(() => {
            if (callback.fnFinalized) callback.fnFinalized()
          })
        )
        .subscribe({
          next: (data) => {
            callback.fnSuccess(data)
          },
          error: (err) => {
            callback.fnError(err)
          }
        })
  }

  exportDashboard(json: any, callback: Callbacks) {
    const config: any = { responseType: 'blob' }

    this.http
      .post<any>(`${environment.api}/admin/dashboard/export/`, json, config)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (csv: any) => {
          callback.fnSuccess(csv)
        },
        error: (err) => {
          callback.fnError(err)
        }
      })
  }

  getDashboardTop(callback: Callbacks) {
    this.http
      .get<any>(`${environment.api}/admin/dashboard/top/`)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
        }
      })
  }

  getDashboard(user: string, callback: Callbacks, month?: number) {
    let url = `${environment.api}/admin/dashboard/`

    if (user) {
      url += `?user=${user}`
    }

    if(month) {
      const query = user ? "&" : "?"
      url += `${query}month=${month}`
    }

    this.http
      .get<any>(url)
      .pipe(
        finalize(() => {
          if (callback.fnFinalized) callback.fnFinalized()
        })
      )
      .subscribe({
        next: (data) => {
          callback.fnSuccess(data)
        },
        error: (err) => {
          callback.fnError(err)
        }
      })
  }
}
