<div>
    <h4 class="form-label">Orçamento de horas:</h4>

    <form [formGroup]="form" *ngIf="!loading.skills">
        <div 
            class="main-budget" 
            formArrayName="budget"     
            *ngIf="budgets().controls.length; else notSkills"
        >
            <div 
                *ngFor="let budget of budgets().controls; let idx = index" 
                [formGroupName]="idx" 
                class="row mb-4 budgets-list"
            >
                <div class="col-6">
                    <label [for]="'budget_skill-' + idx"> Habilidade* </label>

                    <div class="wrapper-select-skills">
                        <ng-select2 
                            *ngIf="groupSkills.length && !loading.select"
                            [data]="groupSkills"
                            formControlName="skill"
                            placeholder="Selecione um projeto do cliente"
                            [id]="'budget_skill-' + idx"
                            (ngModelChange)="disableGroupsExists()"
                        ></ng-select2>

                        <app-loading-local [loading]="loading.select"></app-loading-local>
                    </div>
                </div>

                <div class="col-6">
                    <label [for]="'budget_hours-' + idx"> Total de horas* </label>
                    
                    <div class="form-group">
                        <input 
                            type="number" 
                            formControlName="total_hours"
                            [id]="'budget_hours-' + idx"
                            class="form-control"
                            placeholder="Adicione aqui as horas desta habilidade"
                            min="0"
                        />
                    </div>
                </div>

                <button class="budget-close" (click)="removeBudget(idx)">
                    <i class="bi bi-trash"></i>
                </button>
            </div>
        </div>

        <ng-template #notSkills>
            <h3 class="text-center my-5"> Nenhum orçamento adicionado </h3>
        </ng-template>
    </form>
    
    <div 
        class="d-flex justify-content-center align-content-center" 
        *ngIf="budgets().controls.length < groupSkills.length"
    >
        <button (click)="addBudget()" class="btn-default mt-5"> Adicionar </button>
    </div>
</div>
