import { Component, Input, OnInit } from '@angular/core'
import { ModalCustomService } from 'src/app/services/modal/modal-service.service';

@Component({
	selector: 'app-modal-custom',
	templateUrl: './modal-custom.component.html',
	styleUrls: ['./modal-custom.component.scss'],
})
export class ModalCustomComponent implements OnInit {
	@Input() content: any;
	@Input() title?: string = "";
	@Input() className?: string = "";
	@Input() closeBtn?: boolean = false;

	constructor(private modalCustomService: ModalCustomService) {}

	ngOnInit(): void {}

	closeModal() {
		this.modalCustomService.closeModal()
	}
}
