import {
  Component,
  ViewChild,
  OnInit,
  TemplateRef
} from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { clientHeaderInternal } from 'src/app/mocks/client-header';
import { Client } from 'src/app/models/client';
import { ListHeader } from 'src/app/models/list-header';
import { Project } from 'src/app/models/project';

import { ClientsService } from 'src/app/services/clients/clients.service';
import { HelpersService } from 'src/app/services/helpers/helpers.service';
import { ModalCustomService } from 'src/app/services/modal/modal-service.service';
import { ProjectsService } from 'src/app/services/projects/projects.service';

@Component({
  selector: 'app-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {
  client?: Client
  clientProjects: Project[] = []
  loading: boolean = false
  form: FormGroup | any
  projectSelected?: Project
  clientHeader: ListHeader[] = clientHeaderInternal

  // Modals
  @ViewChild('modalCustom', { static: false }) modalCustom?: TemplateRef<any>
  @ViewChild('modalViewDetails', { static: false }) modalViewDetails?: TemplateRef<any>

  constructor(
    public helpers: HelpersService,
    private route: ActivatedRoute,
    private projectsService: ProjectsService,
    private clientsService: ClientsService,
    private formBuilder: FormBuilder,
    private toast: ToastrService,
    private modalCustomService: ModalCustomService
  ) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      cnpj: ['', [this.helpers.cnpjValidator(), Validators.required]],
      avatar: [''],
      razaoSocial: ['']
    })

    this.route.params.subscribe(params => {
      const clientId = params["id"]
      this.getClient(clientId)
    });
  }

  getClient(clientId: string) {
    const self = this
    this.loading = true

    this.clientsService.getClient(clientId, {
      fnSuccess(data) {
        self.client = data
        self.getProjectsByClient(clientId)

        self.form?.setValue({
          name: data.name,
          cnpj: data.cnpj,
          avatar: data.avatar,
          razaoSocial: data.razao_social
        })
        self.loading = false
      }, fnError(err) {
        console.warn(err)
        self.loading = false
      }
    })
  }

  getProjectsByClient(clientId: string) {
    const self = this

    this.projectsService.getProjectByClientForAdmin(clientId, {
      fnSuccess({ data }) {
        self.clientProjects = data
      }, fnError(err) {
        console.warn(err)
        self.loading = false
      }
    })
  }

  setAvatar(avatar: string) {
    this.form?.controls["avatar"].setValue(avatar);
  }

  onSubmit() {
    const self = this
    const uuid = this.client ? this.client.uuid : ""
    const data = this.helpers.converterCamelCaseToSnake(this.form.value)

    this.clientsService.updateClient(data, uuid, {
      fnSuccess() {
        self.toast.success("Sucesso na atualização do cliente")
        self.getClient(uuid)
        self.closeModal()
      },
      fnError(err) {
        console.warn(err)
        self.showErrorMessage(err.error, () => {
          self.toast.success("Erro na atualização do cliente")
        })
        self.closeModal()
      }
    })
  }

  showModalEdit() {
    const formValue = { ...this.form.value }

    this.modalCustomService.showModal({
      content: this.modalCustom,
      title: `Editar ${this.client?.name}`
    })

    this.modalCustomService.cancelModal(() => {
      this.form?.reset(formValue)
    })
  }

  showModalViewDetails(project: Project) {
    this.projectSelected = project
    this.modalCustomService.showModal({
      content: this.modalViewDetails
    })
  }

  showErrorMessage(error: any, callbackError?: Function) {
    if (error.cnpj) {
      if (error.cnpj[0].includes("cnpj already")) {
        this.toast.error("Cliente com cnpj já cadastrado!")
      } else if (error.cnpj[0].includes("not valid")) {
        this.toast.error("Cnpj inválido!")
      } else {
        if (callbackError) callbackError()
      }
    } else {
      if (callbackError) callbackError()
    }
  }

  closeModal() {
    this.modalCustomService.closeModal()
  }

  getInitials(user: any) {
    return this.helpers.getInitials(`${user.first_name} ${user.last_name}`)
  }

  setColorRandom(index: number) {
    const colors = ['#4285F4', '#ECB22E', '#FC473D', '#34A853', '#8DA1C1']
    return colors[index % colors.length]
  }
}
