import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { ClientsService } from 'src/app/services/clients/clients.service';
import { DashboardService } from 'src/app/services/dashboard/dashboard.service';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-clients-chart',
  templateUrl: './clients-chart.component.html',
  styleUrls: ['./clients-chart.component.scss']
})
export class ClientsChartComponent implements OnInit {
  @Input() dashboardExport: any
  @Output() changeClient: EventEmitter<any> = new EventEmitter()
  client: string = ""
  loading: boolean = false
  notProjects: boolean = false
  clients: Array<Select2OptionData> = []
  dahsboardClient: any

  totalHours = {
    total: 0,
    overtime: 0,
    expediente: 0
  }

  constructor(
    private dashboardService: DashboardService,
    private clientsService: ClientsService
  ) { }

  ngOnInit(): void {
    this.getClients()
  }

  getClients() {
    const self = this
    this.loading = true
    const params = "?all"

    this.clientsService.getClientsForAdmin({
      fnSuccess(data) {
        self.clients = data.map((item: Client) => ({
          text: item.name,
          id: item.uuid
        }))
        self.client = self.clients[0].id
        self.getDashboardByClient(self.client)
        self.loading = false
        self.changeClient.emit(self.client)
      }, fnError(err) {
        console.warn(err)
      }
    }, params)
  }

  getDashboardByClient(client_id: string) {
    const self = this

    if (client_id) {
      this.changeClient.emit(client_id)
      this.loading = true

      this.dashboardService.getDashboardByClient(client_id, {
        fnSuccess(data) {
          const { client_dash } = data
          let labels: any = []
          const hours = {
            total: 0,
            overtime: 0,
            expediente: 0
          }

          let datasets: any = {
            normalHours: [],
            overtime: [],
            totalHours: []
          }

          if (client_dash.projects.length) {
            client_dash.projects.forEach((project: any) => {
              const { budget, name } = project
              labels.push(name)

              datasets.normalHours.push(budget.hours)
              datasets.overtime.push(budget.overtime)

              const totalHours = !budget.total ? budget.hours + budget.overtime : budget.total

              hours.total += totalHours
              hours.overtime += budget.overtime
              hours.expediente += budget.hours

              datasets.totalHours.push(totalHours)
            })
            self.notProjects = false
          } else {
            self.notProjects = true
          }

          self.dahsboardClient = {
            labels,
            datasets: [
              {
                label: 'Horas contratadas',
                data: datasets.totalHours,
                backgroundColor: '#8A9099',
                stack: 'Stack 0'
              },
              {
                label: 'Horas utilizadas',
                data: datasets.normalHours,
                backgroundColor: '#4285F4',
                stack: 'Stack 1'
              },
              {
                label: 'Horas extras',
                data: datasets.overtime,
                backgroundColor: '#FC473D',
                stack: 'Stack 1'
              }
            ]
          }

          self.totalHours = hours
          self.loading = false
          self.dashboardExport.middle = { ...self.dashboardExport.middle, client_dash }
        }, fnError(err) {
          console.warn(err)
          self.loading = false
        }
      })
    }
  }
}
