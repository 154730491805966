<div *ngIf="!subItems || !subItems?.length" class="menu-item">
    <a class="menu-link text-white bright-1" [routerLink]="link" routerLinkActive="active"
        [routerLinkActiveOptions]="{ exact: true }">
        <span class="menu-icon bright-1">
            <span class="svg-icon svg-icon-2 bright-1" [title]="title">
                <img *ngIf="icon" class="icon-menu-link bright-1" [src]="icon" alt="icon" />
            </span>
        </span>
        <span class="menu-title text-white">{{ title }}</span>
    </a>
</div>

<div *ngIf="subItems?.length" data-kt-menu-trigger="click"
    [class]="'menu-item menu-accordion ' + (activeSubItem ? 'show hover' : '')">
    <span [class]="'menu-link ' + (activeSubItem ? 'menu-active' : '')" (click)="toggleShowSubItems()">
        <span class="menu-icon">
            <span class="svg-icon svg-icon-2" fill="white" [title]="title">
                <img *ngIf="icon" [src]="icon" [alt]="icon" />
                <svg *ngIf="!icon" width="18" height="18" viewBox="0 0 18 18" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M16.0143 3.51669C16.5917 4.0941 16.6011 5.0273 16.0355 5.6163L10.0466 11.8531C9.7713 12.1398 9.39348 12.3055 8.99611 12.3139L6.47946 12.3667L6.53258 9.83478C6.54073 9.44645 6.69919 9.07642 6.97463 8.80255L13.0972 2.71492C13.6835 2.132 14.6309 2.13335 15.2155 2.71796L16.0143 3.51669Z"
                        fill="#333333" />
                    <path
                        d="M9.92789 1.63916C10.3268 1.63916 10.6501 1.96251 10.6501 2.36138C10.6501 2.76025 10.3268 3.0836 9.92789 3.0836H4.94455C4.14681 3.0836 3.50011 3.7303 3.50011 4.52805V13.1947C3.50011 13.9925 4.14681 14.6392 4.94455 14.6392H13.6112C14.409 14.6392 15.0557 13.9925 15.0557 13.1947V9.58361C15.0557 9.18473 15.379 8.86138 15.7779 8.86138C16.1768 8.86138 16.5001 9.18473 16.5001 9.58361V13.1947C16.5001 14.7902 15.2067 16.0836 13.6112 16.0836H4.94455C3.34906 16.0836 2.05566 14.7902 2.05566 13.1947V4.52805C2.05566 2.93256 3.34906 1.63916 4.94455 1.63916H9.92789Z"
                        fill="#333333" />
                </svg>
            </span>
        </span>
        <span class="menu-title text-white">{{ title }}</span>
        <span class="menu-arrow"></span>
    </span>
    <div class="menu-sub menu-sub-accordion menu-active-bg">
        <div class="menu-item" *ngFor="let subItem of subItems">
            <a class="menu-link text-white" [routerLink]="subItem.link" routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }">
                <span class="menu-bullet">
                    <span fill="white" class="bullet bullet-dot"></span>
                </span>
                <span class="menu-title text-white">{{ subItem.title }}</span>
            </a>
        </div>
    </div>
</div>