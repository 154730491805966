<div class="wrapper-image-cutter">
	<div class="image">
		<app-image
            [load]="true"
            [path]="image"
            title="image cliente"
        ></app-image>
		
		<div class="file">
			<form enctype="multipart/form-data">
				<label for="file"></label>
				<input
					id="file"
					type="file"
					name="file"
					(change)="fileChangeEvent($event)"
				/>
			</form>
		</div>
	</div>
	<div class="modal-file modal-body" *ngIf="openModalFile">
		<div class="close-file" (click)="onCloseModalFile()"></div>
		<div class="crop-image">
			<image-cropper
				[imageChangedEvent]="imageChangedEvent"
				[maintainAspectRatio]="true"
                [containWithinAspectRatio]="true"
				[aspectRatio]="4 / 4"
				format="png"
				(imageCropped)="imageCropped($event)"
				[transform]="transform"
				(loadImageFailed)="loadImageFailed()"
			></image-cropper>
			<div class="wrapper-button">
				<div class="configs">
					<button
						type="button"
						class="btn btn-primary"
						[class.disabled]="scale === 1"
						(click)="zoomOut()"
					>
						Zoom -
					</button>
					<button
						type="button"
						class="btn btn-primary"
						(click)="zoomIn()"
					>
						Zoom +
					</button>
				</div>

				<button
					(click)="confirmImage()"
					type="button"
					class="btn btn-primary"
				>
					Salvar
				</button>
			</div>
		</div>
	</div>
</div>
