<div class="main-collaborator container">
    <app-table-header 
        title="Colaboradores"
        (callbackAdd)="save()"
    ></app-table-header>

    <div class="main-collaborator-body">
        <div class="wrapper-table-search">
            <div class="d-flex justify-content-between mb-5">
                <div class="d-flex gap-5 align-items-center">
                    <h4> Filtro de horas: </h4>

                    <div class="d-flex gap-4 align-items-center">
                        <input 
                            type="date"
                            class="input-date form-group"
                            [max]="currentDate" 
                            [(ngModel)]="date.start"
                            (ngModelChange)="changeDate($event, 'start')"
                        >
                        <span> Até </span>
                        <input 
                            type="date" 
                            class="input-date form-group"
                            [max]="currentDate" 
                            [min]="date.start" 
                            [(ngModel)]="date.end"
                            (ngModelChange)="changeDate($event, 'end')"
                        />
                    </div>
                </div>

                <button class="btn-default btn-export-clt" (click)="exportWorksheets()">
                    <i class="bi bi-download"></i> Exportar folhas de ponto/CLT
                </button>
            </div>

            <div class="mb-5">
                <div class="wrapper-search">
                    <i class="bi bi-search search"></i>
                    <input 
                        type="text" 
                        class="w-100 input-search" 
                        (keyup)="searchCollaborator($event)"
                        placeholder="Pesquisar colaborador"
                    />
                </div>
            </div>

            <app-table-list 
                [listHeader]='collaboratorsHeader' 
                [listBody]='listFilters' 
                (emmitChangePagination)="changePagination($event)" 
                [total]="total"
                [loading]="loadingTable"
            >
                <ng-template let-item #body>
                    <td>
                        <a [routerLink]="'/admin/collaborators/' + item.uuid">
                            {{ item.first_name }} {{ item.last_name }}
                        </a>
                    </td>
                    <td>
                        {{ item.email }}
                    </td>
                    <td class="text-center">
                        <span class="badge-status" [class]="item.is_active ? 'success' : 'danger'">
                            {{ item.is_active ? "Ativo" : "Inativo" }}
                        </span>
                    </td>
                    <td class="text-center">
                        <span class="badge" [class]="item.type_contract === 'CLT' ? 'badge-success' : 'badge-primary'">
                            {{ item.type_contract }}
                        </span>
                    </td>
                    <td class="text-center">
                        <div *ngIf="!loadingHours">
                            <span class="hours-normal">{{ item.timesheet.hours }}</span>h/<span class="hours-overtime">{{ item.timesheet.overtime }}</span>h/<strong class="total">{{ item.timesheet.hours + item.timesheet.overtime  }}</strong>h
                        </div>
                        <app-loading-local class="loading-hours" [loading]="loadingHours"></app-loading-local>
                    </td>
                    <td class="text-center">
                        <div class="wrapper-icons">
                            <i class="bi bi-calendar4-week" (click)="showModalCalendar(item)"></i>
                            <i class="bi bi-pencil" (click)="save(item)"></i>
                            <i class="bi bi-trash" *ngIf="item.is_active" (click)="deleteCollaborator(item)"></i>
                        </div>
                    </td>
                </ng-template>
            </app-table-list>
        </div>
    </div>
</div>

<ng-template #modalCustom>
    <div *ngIf="!loading">
        <app-input 
            [form]="form" 
            name="first_name" 
            id="first_name"
            labelName="Nome*" 
            placeholder="Nome do Colaborador"
            [inputModel]="form.value.first_name"
        ></app-input>

        <app-input 
            [form]="form" 
            name="last_name" 
            id="last_name"
            labelName="Sobrenome*" 
            placeholder="Sobrenome do Colaborador"
            [inputModel]="form.value.last_name"
        ></app-input>
        
        <app-input 
            [form]="form" 
            name="email" 
            id="email"
            labelName="E-mail*" 
            placeholder="E-mail do Colaborador"
            [inputModel]="form.value.email"
            [readonly]="!!collaboratorSelected"
        ></app-input>

        <app-checkbox
            [form]="form" 
            id="is_admin"
            name="is_admin"
            labelName="Administrador"
            [inputModel]="form.value.is_admin"
        ></app-checkbox>

        <form *ngIf="form" class="mb-4" [formGroup]="form">
            <label for="skills" class="form-label">Habilidades</label>
            <ng-select2 
                [data]="skills" 
                placeholder="Selecione as habilidades"
                id="skills"
                name="skills"
                [options]="options"
                formControlName="skills"
            ></ng-select2>
        </form>

        <app-select 
            [form]="form" 
            name="type_contract"
            labelName="Tipo de contrato*" 
            [placeholder]="'Adicione um tipo de contrato'"
            id="type_contract"
            [inputModel]="form.value.type_contract || ''"
            [options]="typeContracts"
        ></app-select>

        <app-select 
            [form]="form" 
            name="role"
            labelName="Cargo*" 
            [placeholder]="'Adicione um cargo'"
            id="role"
            [inputModel]="form.value.role || ''"
            [options]="roles"
        ></app-select>

        <app-select 
            [form]="form" 
            name="is_active"
            *ngIf="collaboratorSelected"
            labelName="Status*" 
            [placeholder]="'Adicione um status'"
            id="is_active"
            [inputModel]="form.value.is_active || ''"
            [options]="optionsStatus"
        ></app-select>
    </div>

    <app-loading-local class="loading" [loading]="loading"></app-loading-local>

    <div class="wrapper-buttons col-12 d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-cancel btn-secondary " (click)="closeModal()">
            Cancelar
        </button>

        <button 
            type="button" 
            class="btn-save ms-3 btn-default" 
            [class.btn-disabled]="form.invalid"
            (click)="onSubmit()"
        >
            Salvar
        </button>
    </div>
</ng-template>
