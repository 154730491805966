import { Select2OptionData } from "ng-select2";

const status: Select2OptionData[] = [
    {
        text: "Ativos/Inativos",
        id: "all"
    },
    {
        text: "Ativos",
        id: "True"
    },
    {
        text: "Inativos",
        id: "False"
    },
]

export default status