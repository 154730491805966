<div class="form-group" *ngIf="forms.length">
	<div *ngFor="let f of forms" class="wrapper-form custom-scroll">
		<form
			novalidate
			(ngSubmit)="onSubmit(f.formGroup)"
			[formGroup]="f.formGroup"
		>
			<div *ngFor="let prop of f.metaData" class="wrapper-content">
				<app-controls-form-builder
					[prop]="prop"
					[name]="prop.id"
					[form]="f.formGroup"
					(emmitEvent)="setConditionProp($event, prop, f.formGroup)"
				>
				</app-controls-form-builder>
				<ng-container *ngIf="prop.conditionalIsValid">
					<div class='mt-4' *ngFor="let subProp of prop.conditional.questions">
						<app-controls-form-builder
							[prop]="subProp"
							[name]="subProp.id"
							[form]="f.formGroup"
						></app-controls-form-builder>
					</div>
				</ng-container>
			</div>
			<div class="wrapper-button">
				<ng-content select="[buttons]"></ng-content>
				<button
					type="submit"
					class="btn btn-primary"
					[disabled]="f.formGroup.invalid"
				>
					Send
				</button>
			</div>
		</form>
	</div>
</div>
