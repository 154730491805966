<app-card-profile [profile]="profile" [profileTitle]="itemSelected">
    <div profileHeader>
        <ul class="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder">
            <li class="nav-item mt-2" *ngFor="let menu of listMenu; index as i">
                <button
                    type="button"
                    class="nav-link text-active-primary ms-0 me-10 py-5 bg-white"
                    [class.active]="itemSelected === menu.title"
                    (click)="setIdActiveItem(menu)"
                >
                    {{ menu.title }}
                </button>
            </li>
        </ul>
    </div>

    <div profileContent>
        <div *ngIf="itemSelected === 'Editar'">
            <app-form-collaborator
                *ngIf="collaboratorData"
                [collaborator]="collaboratorData"
                (updateData)="updatePage()"
            ></app-form-collaborator>
        </div>
            
        <!-- <div *ngIf="itemSelected === 'Calendário'">
            <app-calendar-collaborator
                [collaborator]="collaboratorData"
            ></app-calendar-collaborator>
        </div> -->

        <div *ngIf="itemSelected === 'Projetos'">
            <app-collaborator-projects
                *ngIf="collaboratorData"
                [collaboratorId]="collaboratorData.user.uuid"
                [startDate]="date.start"
                [endDate]="date.end"
            ></app-collaborator-projects>
        </div>
    </div>
</app-card-profile>
