import { Component, ViewChild, Input } from '@angular/core';
import { ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import chartOtions from 'src/app/mocks/chart-data';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent {
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective

  @Input() data: any
  @Input() chartType: ChartType = 'bar';
  @Input() options: any

  public barChartData: any
  public barChartOptions: any

  ngOnInit(): void {
    this.barChartOptions = { ...chartOtions[this.chartType], ...this.options }
    this.barChartData = this.data
  }

  ngOnDestroy(): void {
    const tooltipEl: any = document.getElementById('chartjs-tooltip');
    if (tooltipEl) {
      tooltipEl.remove()
    }
  }
}
