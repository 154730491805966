<div class="table-header">
    <div class="table-header-buttons d-flex justify-content-between">
        <h3 class="title-content"> {{ title }} </h3>
        <div class="d-flex align-items-center flex-row">
            <!-- <button class="btn-bg-white" title="download" (click)="fnExport()">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="black" class="bi bi-download" viewBox="0 0 16 16">
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
                </svg>
                <span class="ms-2">Exportar</span>
            </button> -->
    
            <button class="btn-shadow ms-3" (click)="fnAdd()" *ngIf="additional">
                <span> + </span>
            </button>
        </div>
    </div>
    
    <div class="table-header-wrapper-label">
        <h4> Todos </h4>
    </div>
</div>
