<div class='wrapper-table custom-scroll'>
    <table class='table position-relative table-striped-custom'>
        <thead>
        <tr>
            <th
                scope='col'
                *ngFor='let item of listHeader'
                [attr.data-value]='item.value'
                [attr.align]='item.align'
                [class]='item.className'
            >
                {{ item.label }}
            </th>
        </tr>
        </thead>
        <tbody *ngIf="!loading">
            <tr *ngFor='let item of listBody'>
                <ng-container
                    *ngIf='bodyTemplateRef'
                    [ngTemplateOutlet]='bodyTemplateRef'
                    [ngTemplateOutletContext]='{ $implicit: item }'
                ></ng-container>
            </tr>
        </tbody>
        <tbody *ngIf="loading || !listBody.length">
            <tr class="wrapper-loading" *ngIf="loading">
                <app-loading-local class="loading-table" [loading]="loading"></app-loading-local>
            </tr>
            <tr *ngIf="!listBody.length && !loading" class="not-found p-0">
                <h3> Nenhum registro encontrado </h3>
            </tr>
        </tbody>
    </table>
</div>

<div>
    <ngb-pagination
        [collectionSize]='total || 0'
        [(page)]='page'
        [pageSize]='pageSize'
        [maxSize]='7'
        [ellipses]='true'
        (pageChange)='changePagination($event)'
    ></ngb-pagination>
</div>
