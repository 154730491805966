import { Component, OnInit, Input } from '@angular/core';
import collaboratorProjectsHeader from 'src/app/mocks/collaborator-projects-header';
import { TimesheetModel } from 'src/app/models/timesheet';
import { TimesheetService } from 'src/app/services/timesheet/timesheet.service';

@Component({
  selector: 'app-collaborator-projects',
  templateUrl: './collaborator-projects.component.html',
  styleUrls: ['./collaborator-projects.component.scss']
})
export class CollaboratorProjectsComponent implements OnInit {
  @Input() startDate: string = ""
  @Input() endDate: string = ""
  @Input() collaboratorId: string = ""

  currentDate: string = ""
  listFilters: any[] = []
  collaboratorProjectsHeader = collaboratorProjectsHeader
  projects: any[] = []
  totalHours: number = 0
  loading: boolean = false
  searchValue: string = ""

  constructor(private timesheetService: TimesheetService) { }

  ngOnInit(): void {
    this.getCurrentDate()
  }

  searchProjects(event: any) {
    this.searchValue = event.target.value.trim()
    this.search(this.searchValue)
  }

  search(searchValue: string) {
    this.listFilters = this.projects.filter((project: any) => {
      return project.name.toUpperCase().includes(searchValue.toUpperCase())
    })
  }

  getCurrentDate() {
    const day = new Date().getDate()
    const dayWithZero = day < 10 ? `0${day}` : day
    const month = new Date().getMonth() + 1
    const monthWithZero = month < 10 ? `0${month}` : month
    const year = new Date().getFullYear()

    this.currentDate = `${year}-${monthWithZero}-${dayWithZero}`

    if (!this.startDate && !this.endDate) {
      this.startDate = `${year}-${monthWithZero}-01`
      this.endDate = this.currentDate
    }

    const params = `start=${this.startDate}&end=${this.endDate}`
    this.getTimesheetCollaborator(params)
  }

  changeDate(date: any, prop: "start" | "end") {
    const dateSelected = {
      start: this.startDate,
      end: this.endDate
    }
    dateSelected[prop] = date

    if (prop === "start") {
      if (dateSelected["start"] > dateSelected["end"]) {
        dateSelected["end"] = dateSelected["start"]
        this.endDate = this.startDate
      }
    }

    const params = `start=${dateSelected.start}&end=${dateSelected.end}`
    this.getTimesheetCollaborator(params)
  }

  getTimesheetCollaborator(params: string = "") {
    const self = this
    this.loading = true

    this.timesheetService.getOneTimesheet(this.collaboratorId, params, {
      fnSuccess(data) {
        const { timesheets } = data

        if (timesheets.length) {
          const timesheetData: any[] = []

          timesheets.map((timesheet: TimesheetModel) => {
            const isOvertime = timesheet.overtime
            let timesheetExists = timesheetData.find((item) => item.name === timesheet.project.name)

            if (!timesheetExists) {
              timesheetData.push({
                name: timesheet.project.name,
                overtime: isOvertime ? timesheet.hours : 0,
                hours: !isOvertime ? timesheet.hours : 0,
                uuid: timesheet.project.uuid,
                total: timesheet.hours
              })
            } else {
              if (isOvertime) {
                timesheetExists.overtime += timesheet.hours
              } else {
                timesheetExists.hours += timesheet.hours
              }
              timesheetExists.total += timesheet.hours
            }
          })

          self.totalHours = timesheetData.reduce((acc, timesheet) => {
            acc += timesheet.total
            return acc
          }, 0)

          self.projects = timesheetData
          self.listFilters = timesheetData
          self.loading = false
          self.search(self.searchValue)
        } else {
          self.loading = false
        }
      }, fnError(err) {
        console.warn("err")
        self.loading = false
      }
    })
  }
}
