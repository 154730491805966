import { Component, OnInit, Input } from '@angular/core'
import { Collaborator } from 'src/app/models/collaborator'
import { TimesheetService } from 'src/app/services/timesheet/timesheet.service'
import { WorksheetService } from 'src/app/services/worksheet/worksheet.service'
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ToastrService } from 'ngx-toastr'
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component'
import { HelpersService } from 'src/app/services/helpers/helpers.service'
import tabsTimesheet from 'src/app/mocks/tabs-timesheet'

@Component({
	selector: 'app-modal-lg',
	templateUrl: './modal-lg.component.html',
	styleUrls: ['./modal-lg.component.scss'],
})
export class ModalLgComponent implements OnInit {
	@Input() collaborator: Collaborator | any
	@Input() closeModal: Function | any

	timesheetsSelecteds: any
	worksheetSelected: any

	loading: boolean = false

	effectTimeControl: boolean = false
	dateSelected: string = ''
	hours = { overtime: 0, normal: 0 }
	dateCalendar = {
		month: new Date().getMonth() + 1,
		year: new Date().getFullYear(),
	}

	tabs = tabsTimesheet
	currentTab: string = 'Folha de ponto'

	constructor(
		private timesheetService: TimesheetService,
		private worksheetService: WorksheetService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		public helpers: HelpersService,
		private toast: ToastrService
	) {}

	ngOnInit(): void {
		if (this.collaborator.type_contract.toLowerCase() === 'pj') {
			this.currentTab = 'Planilha de horas'
		}
	}

	getEndDate() {
		const month =
			this.dateCalendar.month >= 10
				? this.dateCalendar.month
				: `0${this.dateCalendar.month}`
		return `${
			this.dateCalendar.year
		}-${month}-${this.getLastDayOfTheMonth()}`
	}

	getLastDayOfTheMonth() {
		const day = new Date(
			this.dateCalendar.year,
			this.dateCalendar.month,
			0
		).getDate()
		return day >= 10 ? day : `0${day}`
	}

	onDelete(timesheetUuid: string) {
		const self = this
		const messageModal = 'Tem certeza que deseja deletar esse apontamento?'

		const modalRef = this.modalService.open(ModalConfirmComponent, {
			container: '#main-modal-lg',
			windowClass: 'modal-confirm',
		})

		modalRef.componentInstance.title = messageModal
		modalRef.componentInstance.callbackConfirmation = () => {
			this.timesheetService.deleteTimesheetByAdmin(timesheetUuid, {
				fnFinalized() {
					modalRef.close()
				},
				fnSuccess() {
					self.toast.success('Horários removido com sucesso')
				},
				fnError(err) {
					console.warn('err', err)
					self.toast.error('Erro ao remover horário')
				},
			})
		}
	}

	onDeleteWorksheet(worksheetUuid: string) {
		const self = this
		const messageModal =
			'Tem certeza que deseja deletar esta Folha de ponto?'

		const modalRef = this.modalService.open(ModalConfirmComponent, {
			container: '#main-modal-lg',
			windowClass: 'modal-confirm',
		})

		modalRef.componentInstance.title = messageModal
		modalRef.componentInstance.callbackConfirmation = () => {
			this.worksheetService.deleteWorksheet(worksheetUuid, {
				fnFinalized() {
					modalRef.close()
				},
				fnSuccess() {
					self.toast.success('Folha de ponto removida com sucesso')
				},
				fnError(err) {
					console.warn('err', err)
					self.toast.error('Erro ao remover Folha de ponto')
				},
			})
		}
	}

	close() {
		this.activeModal.close()
	}

	handlerMonth(date: any) {
		const month = date.getMonth()
		const year = date.getFullYear()

		if (
			month !== this.dateCalendar.month ||
			year !== this.dateCalendar.year
		) {
			this.effectTimeControl = false
			this.timesheetsSelecteds = undefined
			this.dateCalendar = { year, month }
		}
	}

	setContentDaySelected(data: any) {
		const time = data.date.split('-')
		const day = time[2]
		const month = this.helpers.searchingMonthByNumber(Number(time[1]) - 1)
		this.dateSelected = `${day} de ${month}:`

		this.timesheetsSelecteds = data.timesheets
		this.worksheetSelected = data.worksheet

		this.showTimeControl()
	}

	setTab(tab: string) {
		this.currentTab = tab
	}

	showTimeControl() {
		if (!this.effectTimeControl) {
			this.effectTimeControl = false
			setTimeout(() => {
				this.effectTimeControl = true
			}, 400)
		}
	}
}
