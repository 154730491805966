<div class="main-projects container">
    <app-table-header
        title="Projetos"
        (callbackAdd)="save()"
    ></app-table-header>

    <div class="main-projects-body">
        <div class="wrapper-table-search">
            <div class="mb-5">
                <div class="wrapper-search">
                    <i class="bi bi-search search"></i>
                    <input type="text" class="w-100 input-search" (keyup)="searchProjects($event)"
                        placeholder="Pesquisar projeto">
                </div>
            </div>
            <app-table-list
                [listHeader]='projectsHeader'
                [listBody]='listFilters'
                [total]="total"
                (emmitChangePagination)="changePagination($event)"
                [loading]="loadingTable"
                [page]="page"
            >
                <ng-template let-item #body>
                    <td>
                        <span (click)="save(item)" class="cursor-pointer d-inline">
                            {{ item.name }}
                        </span>
                    </td>
                    <td>
                        <span class="badge-status" [class]="item.is_activate ? 'success' : 'danger'">
                            {{ item.is_activate ? "Ativo" : "Inativo" }}
                        </span>
                    </td>
                    <td>
                        {{ item.clients.name }}
                    </td>
                    <td>
                        <div class="wrapper-icons">
                            <i class="bi bi-eye" [routerLink]="'/admin/projects/' + item.uuid"></i>
                            <i class="bi bi-pencil" (click)="save(item)"></i>
                            <i class="bi bi-trash" (click)="deleteProject(item)"></i>
                        </div>
                    </td>
                </ng-template>
            </app-table-list>
        </div>
    </div>
</div>

<!-- Modal -->
<ng-template #modalCustom>
    <div class="mb-3 content-modal">
        <app-input
            [form]="form"
            name="name"
            labelName="Nome do projeto*"
            placeholder="Adicione o nome do projeto"
            [inputModel]="form.value.name"
        ></app-input>

        <app-select
            [form]="form"
            name="clients"
            labelName="Cliente*"
            placeholder="Adicione o cliente do projeto"
            [inputModel]="form.value.clients"
            [options]="clients"
        ></app-select>

        <app-select
            *ngIf="projectSelected?.name"
            [form]="form"
            name="is_activate"
            labelName="Status*"
            placeholder="Adicione um status ao projeto"
            [inputModel]="form.value.is_activate"
            [options]="optionsStatus"
        ></app-select>

        <div class="mb-4">
            <app-budget-hours
                [form]="form"
            ></app-budget-hours>
        </div>
    </div>

    <div class="wrapper-buttons d-flex align-items-center justify-content-end">
        <button type="button" class="btn btn-cancel btn-secondary " (click)="closeModal()">
            Cancelar
        </button>

        <button
            type="button"
            class="btn-save ms-3 btn-default"
            [class.btn-disabled]="form.invalid"
            (click)="onSubmit()"
        >
            Salvar
        </button>
    </div>
</ng-template>
