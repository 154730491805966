import { Select2OptionData } from "ng-select2"

const optionsDays: Array<Select2OptionData> = [
    {
        text: "Esse mês",
        id: "Esse mês"
    },
    {
        text: "Últimos 7 dias",
        id: "Ultimos 7 dias"
    },
    {
        text: "Ontem",
        id: "Ontem"
    },
    {
        text: "hoje",
        id: "hoje"
    },
]

export default optionsDays