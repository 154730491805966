<div class="card mb-5 mb-xl-10">
	<div class="card-body pt-9 pb-0">
		<div class="d-flex flex-wrap flex-sm-nowrap mb-3">
			<div class="me-7 mb-4">
				<div
					class="symbol w-150px symbol-100px symbol-lg-160px symbol-fixed position-relative"
				>
					<div class="wrapper-profile">
						<app-image
							[path]="'assets/images/media/avatars/blank.png'"
							[title]="'no-image'"
						></app-image>
					</div>
				</div>
			</div>
			<div class="flex-grow-1">
				<div class="d-flex justify-content-between align-items-start flex-wrap mb-2">
					<div class="d-flex flex-column">
						<div class="d-flex align-items-center mb-2">
							<h2 class="text-gray-900 fs-2 fw-bolder me-1">
								{{ profile?.name || "--" }}
							</h2>
						</div>
						<div class="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
							<span
								*ngIf="profile?.role"
								class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 me-4 gap-2"
							>
								<i class="bi bi-person-circle"></i>
								{{ profile?.role }}
							</span>

							<a
								*ngIf="profile?.email"
								[href]="'mailto:' + profile?.email"
								class="d-flex align-items-center text-gray-400 text-hover-primary mb-2 gap-2"
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at-fill" viewBox="0 0 16 16">
									<path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2H2Zm-2 9.8V4.698l5.803 3.546L0 11.801Zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.493 4.493 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586l-1.239-.757ZM16 9.671V4.697l-5.803 3.546.338.208A4.482 4.482 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671Z"/>
									<path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034v.21Zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791Z"/>
								</svg>
								{{ profile?.email }}
							</a>
						</div>
					</div>
				</div>
				<div class="d-flex flex-wrap flex-stack">
					<div class="d-flex flex-grow-1 pe-8">
						<div class="d-flex flex-wrap">
							<div
								class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
							>
								<div class="d-flex align-items-center">
									<div class="fs-2 fw-bolder counted">{{ profile?.projectsQntd || "--" }}</div>
								</div>
								<div class="fw-bold fs-6 text-gray-400">
									Projetos incluso
								</div>
							</div>
						</div>
						<!-- <div class="d-flex flex-wrap">
							<div
								class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3"
							>
								<div class="d-flex align-items-center">
									<div class="fs-2 fw-bolder counted">175h</div>
								</div>
								<div class="fw-bold fs-6 text-gray-400">
									Média de horas mensais
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
		<ng-content select="[profileHeader]"></ng-content>
	</div>
</div>

<div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
	<div class="card-header">
		<div class="card-title m-0">
			<h3 class="fw-bolder m-0">{{ profileTitle }}</h3>
		</div>
	</div>
	<div class="card-body p-9">
		<ng-content select="[profileContent]"></ng-content>
	</div>
</div>
