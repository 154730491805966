<div class="calendar-mobile">
    <div class="calendar-mobile-header" [class.weekend]="getWeekend(weekday)">
        <div class="arrow previous" (click)="previousDay()">
            <img src="/assets/images/temp/arrow-left.svg" alt="arrow-left">
        </div>
        <div class="calendar-mobile-header-wrapper">
            <div class="calendar-mobile-header-date">
                <h3> {{ day }} </h3> 
                <span>
                    <h4> {{ helpers.getWeekday(weekday) }} </h4>
                    <p> {{ helpers.searchingMonthByNumber(month) }}, {{ year }} </p>
                </span>
            </div>
    
            <button class="btn-default mt-5" (click)="addNewTimesheet()" *ngIf="validDayAdd">
                Adicionar novo
            </button>
        </div>

        <div class="arrow" (click)="nextDay()">
            <img src="/assets/images/temp/arrow-right.svg" alt="arrow-right">
        </div>

        <i *ngIf="validDayAdd && usertypeContract === 'CLT' && currentWorksheet" [class]="'bi bi-clock icon-clock text-' + currentWorksheet.status"></i>
    </div>

    <div class="calendar-mobile-content">
        <div *ngIf="!timesheets; else listTimesheet">
            <h3 class="text-center mt-5"> Nenhum horário encontrado! </h3>
        </div>

        <ng-template #listTimesheet>
            <div 
                class="calendar-mobile-content-item" 
                [class.weekend]="getWeekend(weekday)" 
                *ngFor="let timesheet of timesheets" 
                (click)="addNewTimesheet()"
            >
                <h4>
                    {{ timesheet.title }}
                </h4>
            </div>
        </ng-template>

        <div class="summary-month">
            <i class="bi bi-clock" (click)="showDetailsClockEmit()"></i>
            
            <button (click)="showModalhours()">
                Clique aqui para ver seu resumo mensal
            </button>
        </div>
    </div>
</div>

<ng-template #modalHours>
    <div class="overview">
        <h2 class="text-center">Resumo mensal</h2>

        <div class="item-hours"> 
            <strong>Horas</strong>: <span>{{ hours.normal }}h</span> 
        </div>
        <div class="item-hours overtime"> 
            <strong>Horas extras</strong>: <span>{{ hours.overtime }}h</span> 
        </div>

        <div class="download-timesheet">
            <button (click)="downloadTimesheet()">
                baixar histórico mensal
                <i class="bi bi-download"></i>
            </button>
        </div>

        <button class="btn-default btn-worksheet" (click)="downloadWorksheet()" *ngIf="usertypeContract === 'CLT'">
            Baixar folha de ponto mensal  <i class="bi bi-download"></i>
        </button>
    </div>
</ng-template>