import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/user';

import { Observable } from 'rxjs';
import { Store } from '@ngrx/store'
import { AppState } from 'src/app/state/app-state'

@Component({
  selector: 'app-modal-onboarding-worksheet',
  templateUrl: './modal-onboarding-worksheet.component.html',
  styleUrls: ['./modal-onboarding-worksheet.component.scss']
})
export class ModalOnboardingWorksheetComponent implements OnInit {
  private user$?: Observable<User>
  user?: User

  clockStatus = [
    {
      description: "Completo",
      details: "Quando os detalhes da folha de ponto estão completamente preenchidas, o icone de relógio irá aparecer ",
      color: "VERDE",
      status: "success"
    },
    {
      description: "Informações pendentes",
      details: "Quando os detalhes da folha de ponto faltam preencher, o icone de relógio irá aparecer ",
      color: "AMARELO",
      status: "warning"
    },
    {
      description: "Não iniciado",
      details: "Quando os detalhes da folha de ponto não forem preenchidos, o icone do relógio irá aparecer ",
      color: "VERMELHO",
      status: "danger"
    },
  ]

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>
    ) { }

  ngOnInit(): void {
    this.user$ = this.store.select('user')
    this.user$.subscribe((state) => {
      this.user = state
    })
  }

  close() {
    this.activeModal.close()
    this.viewedOnboarding()
  }

  viewedOnboarding() {
    localStorage.setItem("alreadyLoggedIn", "true")
  }
}
