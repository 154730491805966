<div class="main-orboarding-worksheet container">
    <img alt="Logo" src="assets/images/temp/catskillet.svg" class="logo h-70px">

    <div class="onboarding-worksheet-content">
        <h3 class="text-justify my-2">
            Olá {{ user?.firstName }} <br />
        </h3>
        <h3 class="text-justify my-2">
            Agora a Catskillet trabalha com folha de ponto.
        </h3>

        <div class="wrapper-list my-5 py-5 gap-5">
            <h5> Veja abaixo como vai funcionar e os possíveis status: </h5>

            <div class="mt-4 d-flex align-items-center justify-content-justify gap-5" *ngFor="let item of clockStatus">
                <i [class]="'bi bi-clock text-' + item.status"></i>
                <div class="">
                    <strong> {{ item.description }} </strong>
                    <p> {{ item.details }} <strong [class]="'text-lowercase text-' + item.status">{{ item.color
                            }}</strong> </p>

                </div>
            </div>
        </div>
    </div>

    <button class="btn-default" (click)="close()"> Fechar </button>
</div>