<div class="modal-details-content">
    <figure class="d-flex justify-content-center">
        <img class="icon-wave" src="/assets/images/temp/wave.svg" alt="wave" title="Wave">
    </figure>

    <h3 class="text-center mt-5"> Folha de ponto </h3>

    <div class="wrapper-list">
        <h4> Possíveis status </h4>
        <div class="mt-4 d-flex align-items-center gap-5" *ngFor="let item of clockStatus">
            <i [class]="'bi bi-clock text-' + item.status"></i>
            <strong> {{ item.description }} </strong>
        </div>
    </div>
</div>