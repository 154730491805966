<app-loading-global *ngIf='loading'></app-loading-global>
<div class='container'>
    <div class='mt-5' *ngIf='_reload'>
        <ng-wizard [config]='config' (stepChanged)='stepChanged($event)'>
            <ng-wizard-step *ngFor='let formPage of formPagesData' [title]='formPage.title'>
                <ng-container>
                    <div class='wrapper-title'>
                        <h4>{{ formPage.title }}</h4>
                        <p *ngIf='formPage.description'>{{ formPage.description }}</p>
                    </div>
                    <app-form-builder
                        (onSubmitForm)='onSubmit($event)'
                        [formJson]='formPage.questions'
                    ></app-form-builder>
                </ng-container>
            </ng-wizard-step>
        </ng-wizard>
    </div>
</div>
