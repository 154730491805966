import { ListHeader } from "../models/list-header"

const projectHeader: ListHeader[] = [
    {
      value: 'name',
      label: 'Nome do Projeto',
      align: '',
      className: ''
    },
    {
      value: '',
      label: 'status',
      align: '',
      className: ''
    },
    {
      value: '',
      label: 'cliente',
      align: '',
      className: ''
    },
    {
      value: '',
      label: '',
    },
]

export default projectHeader