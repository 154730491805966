import { NgModule } from '@angular/core'
import { NgWizardModule, NgWizardConfig, THEME } from 'ng-wizard'
import { BrowserModule } from '@angular/platform-browser'
import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ToastrModule } from 'ngx-toastr'
import { NgxMaskModule } from 'ngx-mask'
import { StoreModule } from '@ngrx/store'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'

import { stateReducers } from './state/reducers'

import { SetValueDirective } from './directives/set-value.directive'
import { SetFormControlErrorDirective } from './directives/set-form-control-error.directive'

import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ImageCropperModule } from 'ngx-image-cropper';
import { LoadingGlobalComponent } from './components/loading/loading-global/loading-global.component'
import { LoadingLocalComponent } from './components/loading/loading-local/loading-local.component'
import { LoadingProgressComponent } from './components/loading/loading-progress/loading-progress.component'
import { InputComponent } from './components/form/input/input.component'
import { CheckboxCustomComponent } from './components/form/checkbox-custom/checkbox-custom.component'
import { CheckboxComponent } from './components/form/checkbox/checkbox.component'
import { SelectComponent } from './components/form/select/select.component'
import { RadiosComponent } from './components/form/radios/radios.component'
import { ModalDefaultComponent } from './components/modals/modal-default/modal-default.component'
import { VersionComponent } from './components/version/version.component'
import { NotFoundComponent } from './pages/not-found/not-found.component'
import { LazyLoadImageModule } from 'ng-lazyload-image'
import { ImageComponent } from './components/image/image.component'
import { MenuItemComponent } from './components/aside/menu-item/menu-item.component'
import { LabelGroupMenuItemComponent } from './components/aside/label-group-menu-item/label-group-menu-item.component'
import { TableListComponent } from './components/table-list/table-list.component'
import { ProgressComponent } from './components/progress/progress.component'
import { ComponentsComponent } from './pages/components/components.component'
import { ControlErrorComponent } from './components/form/control-error/control-error.component'
import { FormBuilderComponent } from './components/form-builder/form-builder.component'
import { ControlsFormBuilderComponent } from './components/form-builder/controls-form-builder/controls-form-builder.component'
import { CheckboxFormBuilderComponent } from './components/form/checkbox-form-builder/checkbox-form-builder.component'
import { QuizComponent } from './pages/quiz/quiz.component';
import { TimesheetComponent } from './pages/timesheet/timesheet.component';
import { FooterComponent } from './components/footer/footer.component'
import { LoginComponent } from './pages/login/login.component';
import { HeaderComponent } from './components/header/header.component'
import { FullCalendarModule } from '@fullcalendar/angular';
import { ModalTimesheetComponent } from './components/modals/modal-timesheet/modal-timesheet.component';
import { NgSelect2Module } from 'ng-select2'
import { AdminComponent } from './pages/admin/admin.component'
import { AuthInterceptorService } from './services/auth/auth-interceptor.service';
import { BooleanComponent } from './components/form/boolean/boolean.component';
import { TemplateDefaultComponent } from './layouts/template-default/template-default.component';
import { ProjectsComponent } from './pages/admin/projects/projects.component';
import { ClientsComponent } from './pages/admin/clients/clients.component';
import { DashboardComponent } from './pages/admin/dashboard/dashboard.component'
import { CardStatisticsComponent } from './components/dashboard-components/card-statistics/card-statistics.component';
import { ModalConfirmComponent } from './components/modals/modal-confirm/modal-confirm.component';
import { TableHeaderComponent } from './components/table-header/table-header.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarMobileComponent } from './components/calendar-mobile/calendar-mobile.component';
import { ClientProfileComponent } from './pages/admin/client-profile/client-profile.component';
import { ImageCutterComponent } from './components/image-cutter/image-cutter.component';
import { CollaboratorsComponent } from './pages/admin/collaborators/collaborators.component';
import { ProjectProfileComponent } from './pages/admin/project-profile/project-profile.component';
import { ModalCustomComponent } from './components/modals/modal-custom/modal-custom.component'
import { AccordionComponent } from './components/accordion/accordion.component'
import { NgChartsModule } from 'ng2-charts';
import { ChartComponent } from './components/chart/chart.component';
import { BudgetHoursComponent } from './components/budget-hours/budget-hours.component';
import { CollaboratorsProfileComponent } from './pages/admin/collaborators-profile/collaborators-profile.component';
import { ModalLgComponent } from './components/modals/modal-lg/modal-lg.component';
import { CardProfileComponent } from './components/card-profile/card-profile.component';
import { FormCollaboratorComponent } from './components/form-collaborator/form-collaborator.component';
import { CalendarCollaboratorComponent } from './components/calendar-collaborator/calendar-collaborator.component';
import { ReportsComponent } from './pages/admin/reports/reports.component';
import { CollaboratorChartComponent } from './components/dashboard-components/collaborator-chart/collaborator-chart.component';
import { ClientsChartComponent } from './components/dashboard-components/clients-chart/clients-chart.component';
import { ProjectsChartComponent } from './components/dashboard-components/projects-chart/projects-chart.component';
import { CollaboratorProjectsComponent } from './components/collaborator-projects/collaborator-projects.component';
import { WorksheetComponent } from './components/timesheet-tabs/worksheet/worksheet.component';
import { TimesheetControlComponent } from './components/timesheet-tabs/timesheet-control/timesheet-control.component';
import { ModalDetailsClockComponent } from './components/modals/modal-details-clock/modal-details-clock.component';
import { ModalOnboardingWorksheetComponent } from './components/modals/modal-onboarding-worksheet/modal-onboarding-worksheet.component';
import { NotesComponent } from './pages/admin/notes/notes.component';

const ngWizardConfig: NgWizardConfig = {
    theme: THEME.default
}

@NgModule({
    declarations: [
        AppComponent,
        LoadingGlobalComponent,
        LoadingLocalComponent,
        LoadingProgressComponent,
        InputComponent,
        CheckboxComponent,
        CheckboxCustomComponent,
        CheckboxFormBuilderComponent,
        SelectComponent,
        RadiosComponent,
        ControlErrorComponent,
        ModalDefaultComponent,
        ImageComponent,
        VersionComponent,
        NotFoundComponent,
        SetValueDirective,
        SetFormControlErrorDirective,
        MenuItemComponent,
        LabelGroupMenuItemComponent,
        TableListComponent,
        ProgressComponent,
        ComponentsComponent,
        FormBuilderComponent,
        ControlsFormBuilderComponent,
        QuizComponent,
        TimesheetComponent,
        FooterComponent,
        LoginComponent,
        HeaderComponent,
        ModalTimesheetComponent,
        BooleanComponent,
        AdminComponent,
        TemplateDefaultComponent,
        ProjectsComponent,
        ClientsComponent,
        DashboardComponent,
        CardStatisticsComponent,
        ModalConfirmComponent,
        TableHeaderComponent,
        CalendarComponent,
        CalendarMobileComponent,
        ClientProfileComponent,
        ImageCutterComponent,
        ProjectProfileComponent,
        CollaboratorsComponent,
        ModalCustomComponent,
        AccordionComponent,
        ProjectProfileComponent,
        ChartComponent,
        BudgetHoursComponent,
        CollaboratorsProfileComponent,
        ModalLgComponent,
        ModalLgComponent,
        CardProfileComponent,
        FormCollaboratorComponent,
        CalendarCollaboratorComponent,
        ReportsComponent,
        CollaboratorChartComponent,
        ClientsChartComponent,
        ProjectsChartComponent,
        CollaboratorProjectsComponent,
        WorksheetComponent,
        TimesheetControlComponent,
        ModalDetailsClockComponent,
        ModalOnboardingWorksheetComponent,
        NotesComponent
    ],
    imports: [
        BrowserModule,
        NgSelect2Module,
        AppRoutingModule,
        BrowserAnimationsModule,
        CommonModule,
        ImageCropperModule,
        HttpClientModule,
        LazyLoadImageModule,
        NgbModule,
        FullCalendarModule,
        FormsModule,
        NgChartsModule,
        ReactiveFormsModule,
        StoreModule.forRoot({ ...stateReducers }, {}),
        NgxMaskModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: 'toast-top-right'
        }),
        NgWizardModule.forRoot(ngWizardConfig),
    ],
    providers: [{
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptorService,
        multi: true,
    }],
    bootstrap: [AppComponent]
})
export class AppModule {
}
